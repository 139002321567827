<template>
  <p v-if="existNewFiles" class="status-exist-new-files">Подгружены новые документы</p>
</template>

<script>
export default {
    name: "InfoComponent",
    props: {
        existNewFiles: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

  .status-exist-new-files {
    background-color: #65aa4c;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    color: #FFF;
    padding: 3px;
    border-radius: 5px;
    display: inline-block;
  }

</style>
