<template>
  <div class="info">
    <template v-if="!delivery.type">
      <p>Выбор вариантов получения и доставки будет доступен после готовности оценки</p>
    </template>
    <template v-else-if="delivery.type === 1">
      <p>Вами выбран вариант получения: В офисе</p>
      <p v-if="existReportOnPrint">
        Отчет(ы) передан(ы) на печать и сшивку. Когда все будет готово - мы Вас уведомим по СМС. Срок печати 1 рабочий день.
      </p>
      <p>
        Когда отчет(ы) на бумажном носителе будут готовы, Вы можете забрать их в нашем офисе по адресу: {{ delivery.address }}
      </p>
    </template>
    <template v-else-if="delivery.type === 2">
      <p style="text-indent: 20px">Вами выбран вариант получения: <b>Почтой России</b></p>
      <p v-if="existReportOnPrint" style="text-align: justify; text-indent: 20px">
        Отчет(ы) передан(ы) на печать и сшивку. После отправки Почтой России на адресе: {{delivery.address}} мы Вас уведомим по СМС. Срок печати и передачу в Почту России- 2-4 рабочих дня.
      </p>
      <p v-if="isAllReportSentMailRussia" style="text-align: justify; text-indent: 20px">
        Отчет(ы) на бумажном носителе отправлены Почтой России по адресу: {{delivery.address}}. Ожидайте получения.
      </p>
      <p style="text-align: justify; text-indent: 20px"><b>ВАЖНО!</b> Отправляем заказным письмом на ФИО заказчика в отчете (получить на почте сможет только это лицо). Если нужно, чтобы получило иное лицо, пишите нам в чате пожелания сразу после выбора данного варианта получения заказа.</p>
    </template>
    <template v-else-if="delivery.type === 3">
      <p>Вами выбран вариант получения: Доставкой CDEK</p>
      <p v-if="existReportOnPrint">
        Отчет(ы) передан(ы) на печать и сшивку. После передачи отправления курьеру CDEK мы Вас уведомим по СМС. Срок печати и передачу в доставку CDEK - 1-3 рабочих дня.
      </p>
      <p v-if="isAllReportSentDelivery">
        Отчет(ы) на бумажном носителе переданы курьеру CDEK для доставки по адресу: {{delivery.address}}. Ожидайте получения. Срок доставки по СПб - 1-3 рабочих дня. По РФ сроки могу отличаться.
      </p>
    </template>
  </div>
</template>

<script>
export default {
    name: "NotifyComponent",
    props: {
        status: {
            type: Object,
            required: true
        },
        delivery: {
            type: Object,
            required: true
        },
        existReportOnPrint: {
            type: Boolean,
            required: true
        },
        isAllReportPrinted: {
            type: Boolean,
            required: true
        },
        isAllReportSentMailRussia: {
            type: Boolean,
            required: true
        },
        isAllReportSentDelivery: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

  .info {
    font-size: 18px;
  }

  .info p {
    margin-bottom: 8px;
    line-height: 22px;
  }

</style>
