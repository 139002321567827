<template>
  <div :class="'category category-' + category.id">
    <div class="name">
      {{ category.name }}
      <span v-if="parseInt(category.id) === 6">
        При большом количестве ценных бумаг создайте заказ на 1 пакет акций, подгрузите в него выписки - мы их обработаем самостоятельно и скорректируем заказ на нужное количество объектов оценки.
      </span>
    </div>
    <div class="items">
      <div class="container">
        <div class="row">
          <item-component
            v-for="item in category.items"
            :key="item.id"
            :item="item"
            :category-alias="category.alias"
            :objects="objects"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemComponent from "./item/ItemComponent";
export default {
    components: {
        ItemComponent
    },
    props: {
        category: {
            type: Object,
            required: true
        },
        objects: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>

  .category .name {
    width: 100%;
    font-weight: 700;
    font-size: x-large;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid;
  }

  .category .name>span {
    display: block;
    font-weight: 100;
    font-size: 16px;
    margin-top: 5px;
    line-height: 20px;
  }

  .category .items>.container {
    padding: 0;
  }

</style>
