<template>
  <section class="payment">
    <span class="title">Стоимость и оплата</span>
    <div class="info">
      <span>Общая стоимость: <b>{{ price }} ₽</b><template v-if="discount"> (скидка {{ discount }}%)</template></span>
    </div>
  </section>
</template>

<script>
import { ref, watchEffect } from 'vue';
export default {
    name: "PaymentComponent",
    props: {
        status: {
            type: Object,
            required: true
        },
        price: {
            type: Number,
            required: true
        }
    },
    setup(props) {

      const discount = ref(0);

      watchEffect(() => {
          if(props.price>5000 && props.price<=10000) {
              discount.value = 5;
          } else if(props.price>10000 && props.price<=15000) {
              discount.value = 10;
          } else if(props.price>15000 && props.price<=20000) {
              discount.value = 15;
          } else if(props.price>20000) {
              discount.value = 20;
          }
      });

      return {
          discount
      }

    }
}
</script>

<style scoped>

  .payment {
    border-top: 1px dotted;
    border-color: gray;
    font-family: 'Roboto';
    color: #626262;
    margin: 20px 0;
    padding: 15px 20px 0 20px;
    position: relative;
  }

  .payment .title {
    position: absolute;
    top: -8px;
    background: white;
    left: 15px;
    padding: 0 5px;
    color: #3294ca;
  }

  .payment .info {
    font-size: 18px;
  }

  .payment .info>span {
    margin-right: 5px;
  }

  .payment .info>span:last-child {
    font-weight: 500;
  }

  .payment .action {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    width: 100%;
  }

  .payment .action button {
    width: 200px;
    display: inline-block;
    background: #ff9800;
    color: #ffffff;
    margin-top: 15px;
    padding: 3px 5px;
    border-radius: 5px;
    border: none;
  }

</style>
