<template>
  <section class="body" ref="bodyRef">
    <div class="message-items">
      <div v-if="messages.length" v-for="message in messages" :key="message.id">
        <message-deleted-component v-if="message.deleted" :message="message"/>
        <message-component v-else :message="message"/>
      </div>
      <message-empty-component v-else/>
    </div>
  </section>
</template>

<script>
import { ref, provide } from 'vue';
import MessageComponent from "./message/MessageComponent";
import MessageEmptyComponent from "./messageEmpty/MessageEmptyComponent";
import MessageDeletedComponent from "./messageDeleted/MessageDeletedComponent";
export default {
    name: "BodyComponent",
    components: {
        MessageComponent,
        MessageEmptyComponent,
        MessageDeletedComponent
    },
    props: {
        messages: {
            type: Array,
            required: true
        }
    },
    setup() {

        const bodyRef = ref(null);

        provide('bodyRef', bodyRef);

        return {
            bodyRef
        }
    }
}
</script>

<style scoped>

  .body {
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 500px;
    padding: 0 0 60px 0;
  }

  .message-items {
    padding: 15px;
    width: 100%;
    max-height: 100%;
    height: 100%;
    overflow-y: scroll;
    -webkit-box-shadow: 0 0 10px 0 rgba(239, 239, 239, 1) inset;
    -moz-box-shadow: 0 0 10px 0 rgba(239, 239, 239, 1) inset;
    box-shadow: 0 0 10px 0 rgba(239, 239, 239, 1) inset;
    display: flex;
    flex-direction: column-reverse;
  }

</style>
