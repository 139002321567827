<template>
  <layout-legacy>
    <breadcrumb-component/>
    <h1>{{ h1 }}</h1>
    <div>
      <category-item-component
        v-for="category in categories"
        :key="category.id"
        :category="category"
        :objects="objects"
      />
    </div>
  </layout-legacy>
</template>

<script>
import { ref, onMounted, watchEffect, computed } from 'vue';
import { useSeoMeta, useHead } from '@unhead/vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { BREADCRUMBS_SET_ITEM } from "../../store/constants";
import LayoutLegacy from "../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";
import CategoryItemComponent from "./categoryItem/CategoryItemComponent";
export default {
    name: "ServicesView",
    components: {
        LayoutLegacy,
        BreadcrumbComponent,
        CategoryItemComponent
    },
    setup() {

        const route = useRoute();
        const store = useStore();
        const page = ref(null);
        const title = ref('');
        const h1 = ref('');
        const metaKeywords = ref('');
        const metaDescription = ref('');
        const categories = ref([]);
        const objects = computed(() => store.state.objects);

        watchEffect(() => {
            if(store.state.objects.length && store.state.pages.length) {

                page.value = store.getters.getPage(route.path);

                if (page.value !== undefined) {

                    if(page.value.title) {
                        title.value = page.value.title;
                    } else {
                        title.value = page.value.name;
                    }

                    if(page.value.h1) {
                        h1.value = page.value.h1;
                    } else {
                        h1.value = page.value.name;
                    }

                    metaKeywords.value = page.value.meta_keywords;
                    metaDescription.value = page.value.meta_description;
                    categories.value = page.value.items;

                }

            }
        });

        onMounted(() => {

            useSeoMeta({
                title: title,
                keywords: metaKeywords,
                description: metaDescription,
                ogLocale: 'ru_RU',
                ogSiteName: 'Наследство.Онлайн: сервис оценки с отправкой нотариусу',
                ogTitle: title,
                ogDescription: metaDescription,
                ogType: 'website',
                ogUrl: location.href,
            });

            useHead({
                link: [{name: 'canonical', content: location.href}]
            });

            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: page.value.name, path: `/oc` }
            });

        });

        return {
            h1,
            objects,
            categories
        }

    }
}
</script>

<style scoped>

</style>
