<template>
  <div class="step-2">
    <div class="cart-form">
      <form>
        <h1>Заполните сведения о наследстве</h1>
        <div class="group-block">
          <span class="group-block-title">Данные свидетельства о смерти</span>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>ФИО наследодателя (умершего)</label>
                <dadata-suggestions
                  v-model="fields.eval_name.value"
                  type="NAME"
                  class="form-control"
                  @change="checkFulled()"
                />
                <p class="error" v-if="fields.eval_name.error">{{fields.eval_name.errorText}}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>
                  Дата оценки
                  <span class="prompt-text">(дата смерти наследодателя)</span>
                </label>
                <VueDatePicker
                  v-model="fields.date_eval.value"
                  model-type="timestamp"
                  locale="ru"
                  :enable-time-picker="false"
                  auto-apply
                  format="dd.MM.yyyy"
                  @closed="checkFulled()"
                  text-input
                  utc="preserve"
                  @keyup="handleFormatDate"
                  placeholder="__.__.____"
                />
                <p class="error" v-if="fields.date_eval.error">{{fields.date_eval.errorText}}</p>
              </div>
            </div>
          </div>
          <div v-if="fields.notaryFound.value>0" class="row">
            <div class="col-sm-12">
              <notary-data v-if="fields.notaryFound.value === 1" :items="notaryItems"/>
              <notary-custom v-else :is-time-out="isTimeOut" />
            </div>
          </div>
        </div>
        <div class="step-nav">
          <div class="row">
            <div class="col-sm-6">
              <a href="#" @click="prevStep">Вернуться назад</a>
            </div>
            <div class="col-sm-6">
              <button v-if="formValidate(true)" type="submit" @click="nextStep" class="active">Далее</button>
              <button v-else type="button" disabled="disabled" class="disabled">Далее</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { findRequest } from "../../../../../../api/notaries";
import { parseDateFormat } from "../../../../../../helpers/helper";
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "@/store/constants";
import NotaryData from "./notaryData/NotaryData";
import NotaryCustom from "./notaryCustom/NotaryCustom";

export default {
    name: 'Step2Component',
    components: {
        NotaryData,
        NotaryCustom
    },
    setup() {

        const nextStep = inject('nextStep');
        const prevStep = inject('prevStep');
        const fields = inject('fields');
        const notaryItems = inject('notaryItems');
        const store = useStore();
        const isTimeOut = ref(true);

        const handleFormatDate = (e) => {
            e.target.value = parseDateFormat(e.target.value);
        };

        const checkFulled = () => {
            setTimeout(function () {
                if(formValidate()) {
                    isTimeOut.value = false;
                    notaryItems.value = [];
                    fields.value.notaryFound.value = null;
                    const date = new Date(fields.value.date_eval.value);
                    store.commit(DEFAULT_SHOW_LOADER);
                    findRequest({
                        name: fields.value.eval_name.value,
                        date: date.getTime()
                    }).then(response => {
                        store.commit(DEFAULT_HIDE_LOADER);
                        const data = response.data;
                        if(data) {
                            if(data.items.length) {
                                fields.value.notaryFound.value = 1;
                                fields.value.proccess = data.proccess;
                                notaryItems.value = data.items;
                            } else {
                                fields.value.notaryFound.value = 2;
                            }
                        }
                    }).catch(err => {
                        console.log(err);
                        isTimeOut.value = true;
                        store.commit(DEFAULT_HIDE_LOADER);
                        fields.value.notaryFound.value = 2;
                    })
                }
            },0);
        };

        const formValidate = (checkAll = false) => {
          let error = false;
          const date = new Date().getTime();
          for (const key of Object.keys(fields.value)) {
            const field = fields.value[key];
            if (key === 'date_eval') {
              if(field.value==='' || field.value===null) {
                field.error = true;
                field.errorText = 'Заполните дату смерти наследодателя';
                error = true;
              } else {
                if(field.value >= date) {
                  field.error = true;
                  field.errorText = 'Дата не может быть больше текущей';
                  error = true;
                } else {
                  field.error = false;
                  field.errorText = '';
                }
              }
            } else if(key === 'eval_name') {
              if(field.value==='' || field.value===null) {
                  field.error = true;
                  field.errorText = 'Заполните ФИО наследодателя (по свидетельству о смерти)';
                  error = true;
              } else {
                  field.error = false;
                  field.errorText = '';
              }
            } else if (key === 'notary' || key === 'notaryFound') {
                if (checkAll) {
                    if(!field.value) {
                        error = true;
                    }
                }
            }
          }
          if(error) {
            return false;
          }
          return true;
        };

        return {
            fields,
            prevStep,
            nextStep,
            checkFulled,
            notaryItems,
            formValidate,
            isTimeOut,
            handleFormatDate
        }
    }
}
</script>

<style scoped>

  .step-2 {
    max-width: 600px;
    margin: 0 auto;
  }

  .step-2 .cart-form .mx-datepicker {
    width: 100%;
  }

  .step-2 .cart-form label {
    display: block;
  }

  .step-2 .cart-form .warning-text {
    font-size: 12px;
    border-radius: 10px;
    display: block;
  }

  .step-2 .cart-form .form-group {
    margin-bottom: 0;
    position: relative;
  }

  .step-2 .cart-form .row {
    margin-bottom: 15px;
  }

  .step-2 .cart-form .row:last-child {
    margin-bottom: 0;
  }

  .step-2 .cart-form .group-block {
    border: 1px solid #c7c7c7;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
  }

  .step-2 .cart-form .group-block-title {
    position: absolute;
    top: -8px;
    left: 10px;
    background-color: #efefef;
    font-size: 10px;
    text-transform: uppercase;
    display: inline-block;
    padding: 2px;
    color: #000000;
  }

  .step-2 label {
    position: relative;
  }

  .step-2 .label-prompt>span {
    position: absolute;
    top: 0;
    left: 15px;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-weight: normal;
    font-size: 11px;
    max-width: 300px;
    width: max-content;
    width: -moz-max-content;
    z-index: 2;
    display: none;
  }

  .step-2 .label-prompt:hover>span {
    display: block;
  }

  .step-2 label .prompt-text {
    font-size: 12px;
    font-weight: normal;
    color: #606060;
  }

  .suggestions-wrapper {
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  /*step-nav*/

  .step-nav {
    margin-top: 20px;
  }

  .step-nav .row>.col-sm-6:first-child {
    text-align: left;
  }

  .step-nav .row>.col-sm-6:last-child {
    text-align: right;
  }

  .step-nav a,
  .step-nav button {
    background-color: #ffffff;
    color: #1b1e21;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #c7c7c7;
    display: inline-block;
    text-decoration: none;
  }

  .step-nav .disabled {
    background-color: #d3d3d3;
  }

  .step-nav .active {
    background-color: #3294ca;
    color: #ffffff;
    border: none;
  }

  @media (max-width: 768px) {
    .step-nav .col-sm-6 {
      width: 50%;
    }
  }

</style>
