<template>
  <div class="actions">
    <button type="button" class="success" @click="handleConfirmSubmit">Подтверждаю корректность</button>
    <button type="button" class="modify" @click="showEvent">Есть неточности</button>
    <help-component/>
  </div>
</template>

<script>
import { inject } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import { reportConfirmRequest } from "../../../../../../../../../api/orders";
import HelpComponent from "./help/HelpComponent";
export default {
    name: "ActionsComponent",
    props: {
        showEvent: {
            type: Function,
            required: true
        }
    },
    components: {
        HelpComponent
    },
    setup() {

        const loader = inject('loader');
        const orderId = inject('orderId');
        const reportId = inject('reportId');
        const searchOrders = inject('searchOrders');
        const toaster = createToaster({ position: 'top' });

        const handleConfirmSubmit = () => {
            if(confirm('Вы проверили ваши личные данные, данные объекта оценки в отчете и подтверждаете их корректность?')) {
                loader.value = true;
                reportConfirmRequest(orderId, reportId)
                    .then(() => {
                        searchOrders().then(() => {
                            loader.value = false;
                            toaster.success('Отчет подтвержден');
                        });
                    })
                    .catch(err => console.log(err));
            }
        };

        return {
            handleConfirmSubmit
        }
    }
}
</script>

<style scoped>

  .actions {
    display: inline-block;
    vertical-align: middle;
  }

  .actions .success {
    border: 1px solid #60d765;
    border-radius: 5px;
    padding: 5px 10px;
    background: #cff9d1;
    margin-left: 15px;
    color: #555555;
  }

  .actions .modify {
    border: 1px solid #ffae37;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 15px;
    background: #ffe2b8;
    color: #555555;
  }

</style>
