<template>
  <div class="cart-action">
    <div class="btn-group">
      <button v-if="countItems" class="minus" @click="handleDropCart">-</button>
      <button v-if="!countItems" @click="handleAddCart" title="Добавить в корзину">
        <font-awesome-icon icon="cart-plus" />
      </button>
      <button v-else>
        {{ countItems }} шт.
      </button>
      <button v-if="countItems" class="plus" @click="handleAddCart">+</button>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, watchEffect } from 'vue';
import { CART_GET_CART_DEFAULT, CART_ADD_ITEM, CART_DROP_ITEM } from "../../store/constants";

export default {
    name: 'CartButtonsComponent',
    props: {
        id: {
            type: Number,
            required: true
        }
    },
    setup({ id }) {

        const store = useStore();
        const countItems = ref(0);
        const cart = store.getters[CART_GET_CART_DEFAULT];

        const handleAddCart = () => {
            store.dispatch(CART_ADD_ITEM, id);
        };

        const handleDropCart = () => {
            store.dispatch(CART_DROP_ITEM, id);
        };

        const initCountItems = () => {
            let c = 0;
            cart.items.forEach(item => {
                if(parseInt(item.id) === id) {
                    c++;
                }
            });
            countItems.value = c;
        };

        watchEffect(() => {
            initCountItems();
        });

        return {
            countItems,
            handleAddCart,
            handleDropCart
        }
    }
}
</script>

<style scoped>

  .cart-action .btn-group>button {
    background-color: #3294ca;
    color: #ffffff;
    border: none;
    padding: 8px 10px;
  }

  .cart-action .btn-group>button.minus {
    border-right: 1px solid #ffffff;
  }

  .cart-action .btn-group>button.plus {
    border-left: 1px solid #ffffff;
  }

</style>
