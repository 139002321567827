<template>
  <div id="app" :class="template">
    <loader-component v-if="isLoading"/>
    <router-view v-else/>
  </div>
</template>

<script>
import { onMounted, provide, ref } from 'vue';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import {
    AUTH_GET_USER,
    LOAD_OBJECTS,
    CART_INIT,
    LOAD_PAGES,
    BREADCRUMBS_SET_ITEM,
    LOAD_ORDER_PARAMS
} from "../store/constants";
import LoaderComponent from "./LoaderComponent";

export default {
  components: {
      LoaderComponent
  },
  setup() {

      const store = useStore();
      const { cookies } = useCookies();
      const isLoading = ref(true);

      const addCrumbs = (items) => {
          items.map(item => store.commit(BREADCRUMBS_SET_ITEM, { item: item }));
      };

      onMounted(async () => {
          if (cookies.isKey('token')) {
              await store.dispatch(AUTH_GET_USER);
          }
          store.dispatch(LOAD_OBJECTS).then((response) => {
              if(response.data) {
                  return store.dispatch(LOAD_PAGES);
              }
              return null;
          }).then((response) => {
              if(response.data) {
                  return store.dispatch(LOAD_ORDER_PARAMS);
              }
              return null;
          }).then(() => {
              store.dispatch(CART_INIT);
              isLoading.value = false;
              setTimeout(function() {
                  window.YandexRotorSettings.IsLoaded = true;
              }, 5000);
          }).catch(err => console.log(err));
      });

      provide('addCrumbs', addCrumbs);

      return {
          isLoading,
          template: store.state.template
      }

  }
}
</script>

<style>

</style>
