<template>
  <div class="profile" v-if="user">
    <data-form-component
      :first-name="user.firstName"
      :second-name="user.secondName"
      :last-name="user.lastName"
    />
    <email-form-component :email="user.email" />
  </div>
</template>

<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { BREADCRUMBS_SET_ITEM } from "../../../../store/constants";
import DataFormComponent from "./DataFormComponent";
import EmailFormComponent from "./EmailFormComponent";
export default {
    components: {
        DataFormComponent,
        EmailFormComponent
    },
    setup() {

        const store = useStore();
        const user = computed(() => store.state.auth.user);

        onMounted(() => {
            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Профиль', path: '/settings/profile' },
            });
        });

        return {
            user
        }
    }
}
</script>

<style scoped>

  .profile .data {
    margin-bottom: 20px;
    border-bottom: 1px solid #e7e7e7;
    padding-bottom: 20px;
  }

  .profile .email .form,
  .profile .nicname .form {
    max-width: 300px;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

</style>
