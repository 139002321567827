<template>
  <ul :class="className" style="visibility: visible;" ref="ulRef">
    <li>
      <a href="#" v-if="!message.isAuthor" @click="(e) => handleSelectMessage(e, message, 'reply')">
        <font-awesome-icon icon="reply" /> Ответить
      </a>
      <a href="#" v-else onclick="return false;" style="color: #cccccc">
        <font-awesome-icon icon="reply" /> Ответить
      </a>
    </li>
    <li v-if="!message.type">
      <a v-if="message.isAuthor" href="#" @click="(e) => handleSelectMessage(e, message, 'edit')">
        <font-awesome-icon icon="pen" /> Редактировать
      </a>
      <a href="#" v-else onclick="return false;" style="color: #cccccc">
        <font-awesome-icon icon="pen" /> Редактировать
      </a>
    </li>
    <li>
      <a v-if="message.isAuthor" href="#" @click="(e) => handleDeleteMessage(e, message)">
        <font-awesome-icon icon="trash" /> Удалить
      </a>
      <a href="#" v-else onclick="return false;" style="color: #cccccc">
        <font-awesome-icon icon="trash" /> Удалить
      </a>
    </li>
  </ul>
</template>

<script>
import { ref, onMounted, inject } from 'vue';

export default {
    name: 'ListComponent',
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    setup() {

        const className = ref('top-right');
        const ulRef = ref(null);
        const messageRef = inject('messageRef');
        const bodyRef = inject('bodyRef');
        const handleSelectMessage = inject('handleSelectMessage');
        const handleDeleteMessage = inject('handleDeleteMessage');

        const initPosition = () => {
            const menuItemsWidth = ulRef.value.offsetWidth,
                messageWidth = messageRef.value.offsetWidth,
                messagesWidth = bodyRef.value.offsetWidth;
            if (((messageWidth+menuItemsWidth)+30)>messagesWidth) {
                className.value = 'bottom-right';
            } else {
                className.value = 'top-left';
            }
        };

        onMounted(() => {
            initPosition();
        });

        return {
            className,
            ulRef,
            handleSelectMessage,
            handleDeleteMessage
        }
    }
}
</script>

<style scoped>

  ul {
    position: absolute;
    top: 7px;
    right: 3px;
    margin-top: -8px;
  }

  ul.top-right {
    top: 0;
    right: 0;
  }

  ul.top-left {
    top: -22px;
    right: -140px;
  }

  ul.bottom-right {
    top: -70px;
    right: 0;
  }

  ul {
    visibility: hidden;
    position: absolute;
    width: 140px;
    background-color: #ffffff;
    -webkit-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
    z-index: 11;
  }

  ul li a {
    font-size: 14px;
    color: #0f0f0f;
    text-decoration: none;
    display: block;
    padding: 6px 8px;
  }

  ul li a:hover {
    background-color: #ebebeb;
  }



</style>
