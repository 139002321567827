<template>
  <layout-legacy>
    <div v-html="h1"></div>
    <info-column-component class="desktop"/>
    <div class="page-home">
      <services-component/>
    </div>
    <info-column-component class="mobile"/>
  </layout-legacy>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useSeoMeta, useHead } from '@unhead/vue';
import { useRoute } from 'vue-router';
import LayoutLegacy from "../../layouts/LayoutLegacy";
import ServicesComponent from "./services/ServicesComponent";
import infoColumnComponent from "./infoColumn/infoColumnComponent";

export default {
    name: 'HomeView',
    components: {
        LayoutLegacy,
        ServicesComponent,
        infoColumnComponent
    },
    setup() {

        const store = useStore();
        const route = useRoute();
        const page = ref(null);
        const title = ref('');
        const h1 = ref('');

        onMounted(() => {

            page.value = store.getters.getPage(route.path);

            if(page.value.title) {
                title.value = page.value.title;
            } else {
                title.value = page.value.name;
            }

            if(page.value.h1) {
                h1.value = page.value.h1;
            } else {
                h1.value = page.value.name;
            }

            useHead({
                link: [{name: 'canonical', content: location.href}]
            });

            useSeoMeta({
                title: title,
                keywords: page.value.meta_keywords,
                description: page.value.meta_description,
                ogLocale: 'ru_RU',
                ogSiteName: 'Наследство.Онлайн: сервис оценки с отправкой нотариусу',
                ogTitle: title,
                ogDescription: page.value.meta_description,
                ogType: 'website',
                ogUrl: location.href
            });

        });

        return {
            h1
        }
    }
}
</script>

<style>

  .page-home {
    padding: 20px;
    background-color: #ffffff;
  }

  @media (max-width: 768px) {

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

  }

</style>
