<template>
  <section class="status">
    <span class="title">Текущий статус заказа</span>
    <div class="name">{{ status.name }}</div>
  </section>
</template>

<script>
    export default {
        name: "BottomComponent",
        props: {
            status: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

  .status {
    border-top: 1px dotted;
    border-color: gray;
    font-family: 'Roboto';
    color: #626262;
    margin-top: 20px;
    padding: 15px 20px 0 20px;
    position: relative;
  }

  .status .title {
    position: absolute;
    top: -8px;
    background: white;
    left: 15px;
    padding: 0 5px;
    color: #3294ca;
  }

  .status .name {
    font-size: 18px;
    font-weight: 500;
  }

</style>
