<template>
  <section>
    <div class="auth">
      <h1>Для входа в личный кабинет укажите номер вашего мобильного телефона:</h1>
      <send-form-component/>
      <verify-form-component v-if="verifyStatus" />
      <loader-component v-if="loader"/>
    </div>
    <div class="auth-overlay" @click="handleHideAuth"></div>
  </section>
</template>

<script>
import { ref, provide, computed } from 'vue';
import { useStore } from 'vuex';
import { AUTH_HIDE } from "../../store/constants";
import LoaderComponent from "../LoaderComponent";
import SendFormComponent from "./SendFormComponent";
import VerifyFormComponent from "./VerifyFormComponent";
export default {
  name: 'AuthComponent',
  components: {
      LoaderComponent,
      SendFormComponent,
      VerifyFormComponent
  },
  setup() {

    const loader = ref(false);
    const store = useStore();
    const phone = ref('');
    const code = ref('');
    const verify = ref({
        status: false,
        sendTime: null,
        timer: 0,
        timerInterval: null
    });

    const handleHideAuth = () => {
        store.commit(AUTH_HIDE);
    };

    const getPhone = () => {
        return phone.value.match(/\d+/g).join([]);
    };

    provide('phone',phone);
    provide('code',code);
    provide('getPhone',getPhone);
    provide('loader', loader);
    provide('verify', verify);
    provide('handleHideAuth', handleHideAuth);

    return {
        loader,
        verifyStatus: (computed(() => verify.value.status)),
        handleHideAuth
    }
  }
}
</script>

<style scoped>

  .auth {
    width: 320px;
    padding: 15px 20px;
    background-color: #ffffff;
    border: 1px solid #dfdfdf;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border-radius: 10px;
    text-align: center;
    position: absolute;
    top: 30%;
    left: 50%;
    z-index: 99;
    margin-left: -150px;
  }

  .auth-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 97;
    background-color: rgba(0,0,0,0.5);
  }

  .auth h1 {
    font-size: 14px;
    margin-bottom: 10px;
  }

</style>
