<template>
  <div class="orders-cat">
    <div class="orders-cat-title">Заказы: проверка документов</div>
    <template v-if="preOrders.items.length">
      <div class="orders-items">
        <pre-order-component
          v-for="(preOrder, orderKey) in preOrders.items"
          :key="'order-' + orderKey"
          :pre-order="preOrder"
        />
      </div>
      <pagination-component v-if="preOrders.pages.length" :items="preOrders.pages" @action="searchPreOrders" />
    </template>
    <div v-else>
      <p>Заказов не найдено</p>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import PreOrderComponent from "./preOrder/PreOrderComponent";
import PaginationComponent from "../../../components/PaginationComponent";

export default {
    components: {
        PreOrderComponent,
        PaginationComponent
    },
    setup() {

        const preOrders = inject('preOrders');

        return {
            preOrders
        }
    }
}
</script>

<style scoped>

  .orders-cat {
    padding: 15px;
    border: 1px solid #cccccc;
    border-radius: 15px;
    margin-bottom: 20px;
    position: relative;
  }

  .orders .orders-cat:last-child {
    margin-bottom: 0;
  }

  .orders-cat-title {
    position: absolute;
    top: -10px;
    left: 15px;
    background-color: #3294ca;
    padding: 3px 5px;
    text-transform: uppercase;
    font-size: 12px;
    color: #FFFFFF;
  }

</style>
