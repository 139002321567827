<template>
  <div class="status">
    <span>{{ status.name }}</span>
  </div>
</template>

<script>
export default {
    name: "StatusComponent",
    props: {
        status: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

  .status {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }

  .status>span {
    font-family: 'Roboto';
    color: #fff;
    background: #aaa;
    padding: 3px 5px;
    border-radius: 5px;
    display: inline-block;
    font-size: 14px;
    line-height: 15px;
  }

</style>
