<template>
  <div class="object-item">
    <div class="row">
      <div class="col-sm-12 name">
        <h3><router-link :to="`/information/${item.alias}`">{{ item.title?item.title:item.name }}</router-link></h3>
        <p>{{ item.meta_description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { watchEffect, ref } from 'vue';
import CartButtonsComponent from "../../../components/cart/CartButtonsComponent";
export default {
    components: {
        CartButtonsComponent
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        objects: {
            type: Array,
            required: true
        }
    },
    setup(props) {

        const object = ref(null);

        watchEffect(() => {
            object.value = props.objects.find(item => item.id === props.item.objectId);
        });

        return {
            object
        }
    }
}
</script>

<style scoped>

  .object-item:nth-child(2n) {
    background: #e5e5e5;
  }

  .object-item {
    padding: 10px;
  }

  .object-item .price_sale {
    color: red;
    text-decoration: line-through;
  }

  .object-item h3 {
    font-size: 16px;
    margin: 10px 0;
    text-indent: 0;
  }

  .object-item h3>a {
    color: #212529;
    border-bottom: 1px dashed;
    text-decoration: none;
  }

  .object-item h3>a:hover {
    border-bottom: 0;
  }

  .object-item p {
    font-size: 16px;
    margin: 10px 0;
  }

</style>
