import { getRequest, putRequest } from './axios';
import { USER_URL} from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function getUserRequest() {
  return getRequest(`${USER_URL}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function updateNameRequest(formData) {
  return putRequest(`${USER_URL}/name`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function updateEmailRequest(formData) {
  return putRequest(`${USER_URL}/email`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}
