<template>
  <layout-legacy>
    <breadcrumb-component/>
    <h1>{{ title }}</h1>
    <div class="default-page" v-html="content"></div>
  </layout-legacy>
</template>

<script>
import { ref, inject, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useHead } from '@unhead/vue';
import { useRoute, useRouter } from 'vue-router';
import { BREADCRUMBS_CLEAR } from "../../../store/constants";
import LayoutLegacy from "../../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../../components/BreadcrumbComponent";
export default {
    name: "InformationPageView",
    components: {
        LayoutLegacy,
        BreadcrumbComponent
    },
    setup() {

        const store = useStore();
        const route = useRoute();
        const router = useRouter();
        const addCrumbs = inject('addCrumbs');
        const page = ref(null);
        const title = ref('');
        const metaKeywords = ref('');
        const metaDescription = ref('');
        const content = ref('');

        useHead({
            title: title,
            meta: [
                { name: 'keywords', content: metaKeywords },
                { name: 'description', content: metaDescription  },
            ],
            link: [{name: 'canonical', content: location.href}]
        });

        const initPage = () => {
            page.value = store.getters.getPage(route.path);
            if(!page.value || !page.value.onPublic) {
                router.push({ name: 'not-found'});
            } else {
                addCrumbs([
                    { name: page.value.name, path: `/information/${page.value.alias}` },
                ]);
                title.value = page.value.name;
                content.value = page.value.content;
                if(page.value.h1) {
                    title.value = page.value.h1;
                }
                if(page.value.title) {
                    title.value = page.value.title;
                }
                metaKeywords.value = page.value.meta_keywords;
                metaDescription.value = page.value.meta_description;
            }
        };

        watchEffect(onInvalidate => {
            initPage();
            onInvalidate(() => {
                store.commit(BREADCRUMBS_CLEAR);
            })
        });

        return {
            title,
            content
        }
    }
}
</script>

<style scoped>

</style>
