<template>
  <div>
    <end/>
  </div>
</template>

<script>
import End from "./end/End";

export default {
    components: {
        End
    }
}
</script>

<style scoped>

</style>
