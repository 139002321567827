<template>
  <div>
    <div v-if="order" class="page">
      <h1>Файлы заказа</h1>
      <div class="container">
        <h1>Электронный сервис: Наследство.Онлайн.</h1>
        <h2>Результаты оценки рыночной стоимости для нотариальных целей</h2>
        <h2>Заказчик: {{order.clientName}}</h2>
        <div v-if="order.notary" class="notary">
          <div class="notary-row">
            <span>Нотариус:</span>
            <span>{{order.notary.name}} ({{order.notary.region}})</span>
          </div>
          <div class="notary-row" v-if="order.notary.number">
            <span>№ дела о наследстве:</span>
            <span>{{order.notary.number}}</span>
          </div>
          <div class="notary-row" v-if="order.notary.testator_name">
            <span>Наследодатель:</span>
            <span>{{order.notary.testator_name}}</span>
          </div>
        </div>
        <div class="sub-title">Файлы доступны для скачивания еще <b>{{order.leftDays}} дней</b></div>
        <button class="btn btn-primary btn-zip" @click="handleDownLoadZip">Скачать все файлы одним архивом</button>
        <p class="error" v-if="!order.isFullPaid">.SIG файлы ЭЦП сейчас для скачивания не доступны, т.к. Заказчик еще не полностью оплатил заказ.</p>
        <p class="error" v-if="!order.isDigital">.SIG файлы ЭЦП для скачивания не доступны, т.к. заказчик выбрал "бумажную" форму отчета.</p>
        <div class="table services">
          <div v-for="(service, index) in order.services" :key="service.id" class="tr">
            <div class="td service-index">{{index+1}}</div>
            <div class="td service-name">{{service.name}}</div>
            <div class="td files-td">
              <div v-if="service.files.length" class="table files">
                <div v-for="file in service.files" :key="file.id" class="tr">
                  <div class="td file-name">
                    <a href="#" @click="(e) => handleDownloadFile(e,file)"><b>{{file.name}}</b></a>
                  </div>
                  <div class="td">{{file.desc}}</div>
                  <div class="td">размер {{handleGetFileSize(file.size)}} мб</div>
                </div>
              </div>
              <div v-else class="files-empty">Файлов не найдено</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader-component v-if="loader"/>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import {
    shareFilesGetOrderRequest,
    shareFilesGetZipRequest,
    shareFilesGetFileRequest
} from "../../../api/orders";
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "../../../store/constants";
import LoaderComponent from "../../../components/LoaderComponent";

export default {
    name: "OrdersShareFilesView",
    components: {
        LoaderComponent
    },
    setup() {

        const route = useRoute();
        const token = route.query.token;
        const order = ref(null);
        const store = useStore();

        const handleGetData = () => {
            store.commit(DEFAULT_SHOW_LOADER);
            shareFilesGetOrderRequest(token)
              .then(response => {
                  order.value = response.data;
                  store.commit(DEFAULT_HIDE_LOADER);
              })
              .catch(err => console.log(err));
        };

        const handleGetFileSize = (size) => {
            return (size / 1048576).toFixed(2);
        };

        const handleDownLoadZip = () => {
            store.commit(DEFAULT_SHOW_LOADER);
            shareFilesGetZipRequest(token)
                .then(response => {
                    const data = response.data;
                    const blob = new Blob([data],{ type: data.type });
                    const url = window.URL.createObjectURL(blob, {
                        type: data.type
                    });
                    window.open(url, '_blank');
                    store.commit(DEFAULT_HIDE_LOADER);
                })
                .catch(err => console.log(err));
        };

        const handleDownloadFile = (e,file) => {
            e.preventDefault();
            store.commit(DEFAULT_SHOW_LOADER);
            shareFilesGetFileRequest(token, file.id)
                .then(response => {
                    const data = response.data;
                    const blob = new Blob([data],{ type: data.type });
                    const url = window.URL.createObjectURL(blob, {
                        type: data.type
                    });
                    const link = window.document.createElement("a");
                    link.href = url;
                    link.download = file.name;
                    link.target = '_blank';
                    link.click();
                    window.URL.revokeObjectURL(url);
                    store.commit(DEFAULT_HIDE_LOADER);
                })
                .catch(err => console.log(err));
        };

        onMounted(() => {
            if(token) {
                handleGetData();
            }
        });

        return {
            loader: computed(() => store.state.loader),
            order,
            handleGetFileSize,
            handleDownLoadZip,
            handleDownloadFile
        }
    }
}
</script>

<style scoped>

  .page {
    margin: 50px 0;
  }

  h1 {
    font-size: 31px;
  }

  h2 {
    font-size: 21px;
  }

  .sub-title {
    font-size: 21px;
    margin-bottom: 20px;
  }

  .btn-zip {
    margin-bottom: 20px;
  }

  .service-index {
    width: 60px;
    text-align: center;
  }

  .service-name {
    width: 310px;
  }

  .services>.tr .td {
    border: 1px solid #F2F2F2;
    padding: 10px;
    vertical-align: middle;
    font-size: 17px;
    background-color: #ebebeb;
  }

  .services>.tr .files-td {
    padding: 0;
  }

  .services>.tr>.td .td {
    border-top: none;
    border-left: none;
  }

  .services>.tr>.td .td:last-child {
    border-right: none;
  }

  .services>.tr>.td .tr:last-child .td {
    border-bottom: none;
  }

  .files-empty {
    padding: 10px;
  }

  .file-name a {
      color: #007bff;
      text-decoration: none;
      border-bottom: 1px dashed #007bff;
  }

  .file-name a:hover {
    border-bottom: 1px dashed transparent;
  }

  .notary>.notary-row {
    margin-bottom: 20px;
  }

  .notary>div>span:first-child {
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
  }

  .notary>div>span:last-child {
    font-size: 16px;
  }

</style>
