<template>
  <span class="menu">
    <a href="#" class="button" @click="showEvent">
      <font-awesome-icon icon="ellipsis-h" />
    </a>
    <list-component v-if="isShowList" :message="message"/>
    <span v-if="isShowList" class="overlay" @click="hideEvent"></span>
  </span>
</template>

<script>
import ListComponent from "./list/ListComponent";
export default {
    name: 'MenuComponent',
    components: {
        ListComponent
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        isShowList: {
            type: Boolean,
            required: true
        },
        showEvent: {
            type: Function,
            required: true
        },
        hideEvent: {
            type: Function,
            required: true
        }
    },
    setup(props, context) {

        const handleShowList = (e) => {
            e.preventDefault();
            context.emit('update:isShowList', true);
        };

        const handleHideList = () => {
            context.emit('update:isShowList', false);
        };

        return {
            handleShowList,
            handleHideList
        }
    }
}
</script>

<style scoped>

  .menu {
    position: absolute;
    top: 7px;
    right: 3px;
    margin-top: -8px;
  }

  .menu>.button {
    color: black;
    text-decoration: none;
    display: inline-block;
    vertical-align: middle;
    padding: 2px 5px;
  }

  .menu>.button>svg {
    font-size: 14px;
    line-height: 14px;
    color: #cccccc;
  }

  .menu>.button svg:hover {
    color: #858585;
  }

  .menu .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

</style>
