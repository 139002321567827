<template>
  <div>{{ number }}.</div>
</template>

<script>
export default {
    name: "NumberComponent",
    props: {
        number: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
