export function copy (str) {
  const tmp   = document.createElement('INPUT'), // Создаём новый текстовой input
    focus = document.activeElement; // Получаем ссылку на элемент в фокусе (чтобы не терять фокус)
  tmp.value = str; // Временному input вставляем текст для копирования
  document.body.appendChild(tmp); // Вставляем input в DOM
  tmp.select(); // Выделяем весь текст в input
  document.execCommand('copy'); // Магия! Копирует в буфер выделенный текст (см. команду выше)
  document.body.removeChild(tmp); // Удаляем временный input
  focus.focus(); // Возвращаем фокус туда, где был
}

export function parseDateFormat(value) {

  value = value.replace(/\D/g,'').substring(0, 10);

  let dateString = value;
  const valueSplit = value.match(/.{1,2}/g);

  if(valueSplit) {
    if(valueSplit[0] !== undefined) {
      dateString = valueSplit[0];
      if(valueSplit[1] !== undefined) {
        dateString += '.' + valueSplit[1];
        if(valueSplit[2] !== undefined) {
          dateString += '.' + valueSplit[2];
          if(valueSplit[3] !== undefined) {
            dateString += valueSplit[3];
          }
        }
      }
    }
  }

  return dateString;
}
