const state = () => ({
  id: null,
  display: false
});

const getters = {
  getTheme (state) {
    return state.id
  },
  getDisplay (state) {
    return state.display
  },
};

const actions = {};

const mutations = {
  show (state, payload) {
    state.id = payload.id;
    state.display = true;
  },
  hide (state) {
    state.id = null;
    state.display = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
