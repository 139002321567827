<template>
  <div class="number">
    Заказ №{{number}}
  </div>
</template>

<script>
export default {
    name: "NumberComponent",
    props: {
        number: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

  .number {
    margin-right: 10px;
    font-size: 24px;
    color: #FFFFFF;
    margin-left: 15px;
    font-family: 'Roboto';
    font-weight: 500;
  }

</style>
