<template>
  <section class="status">
    <span class="title">Текущий статус заказа</span>
    <div class="name">{{ status.name }}</div>
    <div v-if="status.id === 1">
      <p>Проверка документов проводится с 9:30 до 21:00 МСК. В рабочие дни занимает 1-2 часа. В выходные - в течение дня. Мы вас уведомим по итогам проверки</p>
    </div>
    <actions-component v-else-if="status.id === 2" :exist-new-file="existNewFile"/>
  </section>
</template>

<script>
import ActionsComponent from "./actions/ActionsComponent";
export default {
    name: "BottomComponent",
    components: {
        ActionsComponent
    },
    props: {
        status: {
            type: Object,
            required: true
        },
        existNewFile: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

  .status {
    border-top: 1px dotted;
    border-color: gray;
    font-family: 'Roboto';
    color: #626262;
    margin-top: 20px;
    padding: 15px 20px 0 20px;
    position: relative;
  }

  .status .title {
    position: absolute;
    top: -8px;
    background: white;
    left: 15px;
    padding: 0 5px;
    color: #3294ca;
  }

  .status .name {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .status p {
    font-size: 18px;
  }

</style>
