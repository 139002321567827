import { getRequest, putRequest } from "./axios";
import {
  NOTIFICATIONS_URL
} from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies }  = useCookies();

export function getAllRequest() {
  return getRequest(`${NOTIFICATIONS_URL}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function readRequest(id) {
  return putRequest(`${NOTIFICATIONS_URL}/${id}/read`, {},{
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

