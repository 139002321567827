<template>
  <div class="help">
    <a href="#" @click="handleShowPopup">
      <font-awesome-icon icon="info-circle" />
      <span class="text">Помощь</span>
    </a>
    <div v-if="isShowPopup" class="popup">
      <p>С любыми вопросами по работе нашего сервиса вы можете обратиться в <b>Чат</b> заказа.</p>
      <p>Значок чата расположен сверху заказа, справа от его номера.</p>
      <p>Также с нами можно связаться по электронной почте <a href="mailto:info@finec-audit.ru">info@finec-audit.ru</a></p>
      <p>или по телефону (812) 425-30-30 (с 9-30 до 21-00 по московскому времени)</p>
      <a href="#" class="close" @click="handleHidePopup">x</a>
    </div>
    <div v-if="isShowPopup" @click="handleHidePopup" class="popup-overlay"></div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: "HelpComponent",
    setup() {

        const isShowPopup = ref(false);

        const handleShowPopup = (e) => {
            e.preventDefault();
            isShowPopup.value = true;
        };

        const handleHidePopup = (e) => {
            e.preventDefault();
            isShowPopup.value = false;
        };

        return {
            isShowPopup,
            handleShowPopup,
            handleHidePopup
        }
    }
}
</script>

<style scoped>

  .help {
    font-size: 20px;
    color: #FFFFFF;
    margin-left: 23px;
    position: relative;
  }

  .help a {
    color: #FFFFFF;
    text-decoration: none;
  }

  .help>a>span.text {
    margin-left: 10px;
  }

  .help .popup {
    position: absolute;
    top: 25px;
    left: 0;
    background-color: #ffffff;
    width: 560px;
    padding: 35px;
    z-index: 2;
    border: 1px solid #000000;
  }

  .help .popup p {
    color: #767171;
    text-indent: 20px;
    line-height: 25px;
  }

  .help .popup p>a {
    color: #767171;
    border-bottom: 1px solid #767171;
    text-decoration: none;
  }

  .help .popup p>a:hover {
    border-bottom: none;
  }

  .help .popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .help .popup .close {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #767171;
  }

  @media (max-width: 992px) {

    .help {
      margin-left: 0;
    }

    .help>a>span.text {
      display: none;
    }

    .help .popup {
      width: 350px;
      left: auto;
      right: 0;
      padding: 20px;
    }

  }

</style>
