<template>
  <div>Сообщений не найдено</div>
</template>

<script>
export default {
    name: "MessageEmptyComponent"
}
</script>

<style scoped>

  div {
    border-radius: 5px;
    padding: 7px 10px;
    color: #ababab;
    background-color: #ededed;
    width: max-content;
    font-weight: 500;
    font-size: 12px;
    margin: 0 auto;
  }

</style>
