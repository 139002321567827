<template>
  <author-component :name="message.author.name"/>
  <text-component :text="message.text" :report-number="message.reportNumber"/>
  <date-component :datetime="message.datetime"/>
</template>

<script>
import AuthorComponent from "../author/AuthorComponent";
import TextComponent from "./text/TextComponent";
import DateComponent from "../date/DateComponent";
export default {
    name: "TextContentComponent",
    components: {
        AuthorComponent,
        TextComponent,
        DateComponent
    },
    props: {
        message: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
