<template>
  <div class="menu">
    <ul>
      <li>
        <router-link class="desktop" to="/information">Информация</router-link>
        <router-link class="mobile" to="/information">
          <font-awesome-icon icon="bars" />
        </router-link>
        <ul>
          <li v-for="item in items">
            <router-link :to="`/information/${item.alias}`">{{ item.name }}</router-link>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
export default {
    name: "MenuComponent",
    setup() {

        const page = ref(null);
        const items = ref([]);
        const store = useStore();

        onBeforeMount(() => {
            page.value = store.getters.getPage('/information');
            items.value = page.value.items.filter(item => item.onPublic);
        });

        return {
            items
        }
    }
}
</script>

<style scoped>

  .menu {
    display: inline-block;
    vertical-align: middle;
  }

  .menu li {
    display: inline-block;
    position: relative;
  }

  .menu>ul>li>a {
    font-size: 18px;
    color: #3294ca;
    text-decoration: none;
    font-weight: 500;
    display: block;
    padding: 19px 10px;
  }

  .menu>ul>li>ul>li>a {
    text-decoration: none;
    display: block;
    padding: 10px 20px;
    color: #303030;
  }

  .menu li:hover ul {
    display: block;
  }

  .menu li a:hover {
    background-color: #efefef;
    color: #3a95c8;
  }

  .menu li>ul {
    position: absolute;
    right: 0;
    top: 56px;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgb(0 0 0 / 20%);
    width: 200px;
    text-align: left;
    display: none;
  }

  .menu li>ul>li {
    width: 100%;
  }

  .menu li>ul>li>a {
    padding: 10px 20px;
  }

  .menu .mobile {
    display: none;
  }

  @media (max-width: 768px) {
    .menu .desktop {
      display: none;
    }
    .menu .mobile {
      display: block;
    }
    .menu li>ul {
      right: -100px;
    }
  }

</style>
