<template>
  <div class="logo">
    <router-link class="desktop" to="/">Прейскурант</router-link>
    <router-link class="mobile" to="/">Прейскурант</router-link>
  </div>
</template>

<script>
export default {
    name: "Logo"
}
</script>

<style scoped>

  .logo a {
    font-size: 18px;
    color: #3294ca;
    text-decoration: none;
    font-weight: 500;
  }

  .logo .router-link-active {
    color: #717171;
  }

</style>
