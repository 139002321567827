<template>
  <div class="cart-notary">
    <step1-component v-if="step === 1" />
    <step2-component v-else-if="step === 2" />
    <step3-component v-else-if="step === 3" />
    <modal-component v-model:show="isConfirmNotify" title="Дополнительная информация" :width-percent="50">
      <template v-slot:body>
        <div class="notify-content">
          <ul>
            <li>1) Если стоимость вашего заказа менее 2000 руб, то <b>оплата будет после готовности</b> и подтверждения заказа. Оплатить вы сможете прямо в личном кабинете картой или по QR-коду. При сумме более 2000 руб. потребуется оплатить 50% стоимости заказа после нашей проверки приложенных документов и подтверждения заказа.</li>
            <li>2) Если по указанным данным свидетельства о смерти к заказу было "привязано" наследственное дело и ведущий его нотариус, то после готовности и оплаты оценки Вы сможете направить ее результаты прямо нотариусу, <b>нажав в заказе кнопку "ОТПРАВИТЬ НОТАРИУСУ"</b>.</li>
          </ul>
          <div class="align-right">
            <button type="button" @click="handleSubmit" class="btn btn-primary">Отправить на проверку</button>
          </div>
        </div>
      </template>
    </modal-component>
  </div>
</template>

<script>
import { ref, computed, watchEffect, provide } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER, CART_CLEAR } from "../../../../../store/constants";
import { createRequest } from "../../../../../api/preOrders";
import Step1Component from "./step1/Step1Component";
import Step2Component from "./step2/Step2Component";
import Step3Component from "./step3/Step3Component";
import ModalComponent from '../../../../../components/ModalComponent';

export default {
    name: 'OrderComponent',
    components: {
        Step1Component,
        Step2Component,
        Step3Component,
        ModalComponent
    },
    setup() {

        const step = ref(1);
        const store = useStore();
        const router = useRouter();
        const objects = computed(() => store.state.objects);
        const cartObjects = ref([]);
        const cart = store.state.cart.carts[0];
        const filesAllowExtension = ['jpg','jpeg','png','heic','pdf'];
        const filesAllowSize = 104857600;
        const fields = ref({
            eval_name: { value:'', error: false, errorText: '' },
            date_eval: { value:'', error: false, errorText: '' },
            client_name: { value:'', error: false, errorText: '' },
            passport_series: { value:'', error: false, errorText: '' },
            passport_date: { value:'', error: false, errorText: '' },
            passport_from: { value:'', error: false, errorText: '' },
            notary: { value: null, error: false, errorText: '' },
            notaryFound: { value: 0, error: false, errorText: '' },
            notaryNumber: { value: '', error: false, errorText: '' },
            proccess: []
        });
        const notaryItems = ref([]);
        const isConfirmNotify = ref(false);

        const initObjects = () => {
            cartObjects.value = cart.items.map(item => {
                const object = objects.value.find(
                    object => parseInt(object.id) === item.id
                );
                object.files = [];
                object.filesError = '';
                return JSON.parse(
                    JSON.stringify(object)
                );
            });
        };

        const nextStep = (e) => {
            e.preventDefault();
            step.value = step.value+1;
        };

        const prevStep = (e) => {
            e.preventDefault();
            step.value = step.value-1;
        };

        const handleSubmit = (e) => {
            e.preventDefault();
            if(!store.state.auth.user.existOrders && !isConfirmNotify.value) {
                isConfirmNotify.value = true;
                return;
            }
            isConfirmNotify.value = false;
            store.commit(DEFAULT_SHOW_LOADER);
            const passport_date = new Date(fields.value.passport_date.value);
            const date_eval = new Date(fields.value.date_eval.value);
            const formData = new FormData();
            formData.append('CreateForm[legal]', 1);
            formData.append('CreateForm[client_name]', fields.value.client_name.value);
            formData.append('CreateForm[passport_series]', fields.value.passport_series.value);
            formData.append('CreateForm[passport_date]', passport_date.getTime());
            formData.append('CreateForm[passport_from]', fields.value.passport_from.value);
            formData.append('CreateForm[eval_name]', fields.value.eval_name.value);
            formData.append('CreateForm[date_eval]', date_eval.getTime());
            formData.append('CreateForm[report_type]', cart.type);
            formData.append('CreateForm[type]', '1');
            formData.append('CreateForm[notary_found]', fields.value.notaryFound.value);
            formData.append('CreateForm[notary]', JSON.stringify(fields.value.notary.value));
            formData.append('CreateForm[notary_number]', fields.value.notaryNumber.value);
            if (fields.value.proccess.length) {
                fields.value.proccess.map(item => formData.append('CreateForm[proccess][]', item));
            }
            const services = cartObjects.value;
            const serviceIDs = [];
            for (let i=0; i<services.length; i++) {
                const service = services[i];
                for (let ii=0; ii<service.files.length; ii++) {
                    const file = service.files[ii];
                    formData.append('CreateForm[files]['+i+'][]',file);
                    //formData.append('CreateForm[filesHeic]['+i+'][]',file.base64);
                }
                serviceIDs.push(service.id);
            }
            formData.append('CreateForm[services]',JSON.stringify(serviceIDs));
            createRequest(formData).then(() => {
                store.dispatch(CART_CLEAR);
                store.commit(DEFAULT_HIDE_LOADER);
                router.push('/orders');
            }).catch(err => console.log(err));
        };

        watchEffect(() => {
            initObjects();
        });

        provide('cartObjects', cartObjects);
        provide('filesAllowExtension', filesAllowExtension);
        provide('filesAllowSize', filesAllowSize);
        provide('nextStep', nextStep);
        provide('prevStep', prevStep);
        provide('fields', fields);
        provide('handleSubmit', handleSubmit);
        provide('notaryItems', notaryItems);

        return {
            step: computed(() => step.value),
            isConfirmNotify,
            handleSubmit
        }
    }
}
</script>

<style scoped>

  .notify-content li {
    padding: 10px 0;
    font-size: 17px;
    line-height: 24px;
  }

  .notify-content button {
    background-color: #3194ca;
    border: none;
  }

</style>
