<template>
  <div class="form-group">
    <label>Найдены наследственные дела у нотариуса:</label>
    <div class="notary-items">
      <div v-for="(item,itemIndex) in items" :key="itemIndex">
        <input
          type="radio"
          :value="itemIndex"
          :checked="currentItem !== null && currentItem === itemIndex"
          @click="handleSelect(itemIndex)"
        />
        <span>{{ item.notaryName }} ({{ item.chamberName }}), №{{ item.caseNumber }}, Дата смерти: {{ item.deathDate }}</span>
      </div>
    </div>
    <div class="info-text">
      <p v-if="items.length>1">Выберите нужное дело о наследстве.</p>
      <p>Если данные корректны, то отправляйте Заказ на обработку.</p>
      <p>Если нет, то укажите нужного нотариуса вручную по его ФИО</p>
      <p><a href="#" @click="handleSwitchToNotaryCustom">Выбрать нотариуса вручную</a></p>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue';
export default {
    name: "NotaryData",
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    setup(props) {

        const fields = inject('fields');
        const currentItem = ref(null);

        const handleSelect = (i) => {
            fields.value.notary.value = props.items[i];
            currentItem.value = i;
        };

        const handleSwitchToNotaryCustom = (e) => {
            e.preventDefault();
            fields.value.notaryFound.value = 2;
            fields.value.notary.value = null;
            fields.value.notaryNumber.value = null;
            fields.value.notaryTestator.value = null;
        };

        onMounted(() => {
            if(props.items.length === 1) {
                handleSelect(0);
            }
        });

        return {
            fields,
            currentItem,
            handleSelect,
            handleSwitchToNotaryCustom
        }
    }
}
</script>

<style scoped>

  .notary-items input {
    margin-right: 5px;
  }

  .form-group {
    margin-bottom: 0;
  }

  .info-text {
    margin-top: 10px;
  }

  .info-text>p {
    margin-bottom: 5px;
  }

  .info-text>p:last-child {
    margin-bottom: 0;
  }

</style>
