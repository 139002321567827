<template>
  <section class="delivery">
    <span class="title">Получение и доставка</span>
    <actions-component v-if="allReportConfirmed && !delivery.type && isFullPayed" />
    <notify-component
      v-else
      :status="status"
      :delivery="delivery"
      :exist-report-on-print="existReportOnPrint"
      :is-all-report-printed="isAllReportPrinted"
      :is-all-report-sent-mail-russia="isAllReportSentMailRussia"
      :is-all-report-sent-delivery="isAllReportSentDelivery"
    />
  </section>
</template>

<script>
import ActionsComponent from "./actions/ActionsComponent";
import NotifyComponent from "./notify/NotifyComponent";
export default {
    name: "DeliveryComponent",
    components: {
        ActionsComponent,
        NotifyComponent
    },
    props: {
        status: {
            type: Object,
            required: true
        },
        delivery: {
            type: Object,
            required: true
        },
        allReportConfirmed: {
            type: Boolean,
            required: true
        },
        existReportOnPrint: {
            type: Boolean,
            required: true
        },
        isAllReportPrinted: {
            type: Boolean,
            required: true
        },
        isAllReportSentMailRussia: {
            type: Boolean,
            required: true
        },
        isAllReportSentDelivery: {
            type: Boolean,
            required: true
        },
        isFullPayed: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

  .delivery {
    border-top: 1px dotted;
    border-color: gray;
    font-family: 'Roboto';
    color: #626262;
    margin-top: 20px;
    padding: 15px 20px 0 20px;
    position: relative;
  }

  .delivery .title {
    position: absolute;
    top: -8px;
    background: white;
    left: 15px;
    padding: 0 5px;
    color: #3294ca;
  }

</style>
