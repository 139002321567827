<template>
    <div class="verify-form">
      <div class="form-group code">
        <p>Код подтверждения:</p>
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            v-model="code"
            placeholder="######"
            inputmode="numeric"
            pattern="[0-9]*"
            autocomplete="one-time-code"
            @keyup="handleVerifyCode"
          />
          <div class="input-group-append">
            <button class="btn" type="button" @click="handleVerifyCode">Проверить</button>
          </div>
        </div>
        <p v-if="error" class="error">{{errorText}}</p>
      </div>
    </div>
</template>

<script>
import { inject, ref } from 'vue';
import { useStore } from 'vuex';
import { useCookies } from 'vue3-cookies';
import { verifyCodeRequest } from "../../api/auth";
import { AUTH_SET_USER } from "../../store/constants";
import { useRouter } from 'vue-router'

export default {
    name: 'VerifyFormComponent',
    setup() {

        const store = useStore();
        const code = ref('');
        const error = ref(false);
        const errorText = ref('');
        const { cookies } = useCookies();
        const loader = inject('loader');
        const getPhone = inject('getPhone');
        const handleHideAuth = inject('handleHideAuth');
        const router = useRouter();

        const handleVerifyCode = () => {
            if(code.value === '' || code.value.length<6) return;
            const codeValue = code.value;
            loader.value = true;
            error.value = false;
            errorText.value = '';
            code.value = '';
            verifyCodeRequest({
                code: codeValue,
                phone: getPhone()
            }).then(response => {
                loader.value = false;
                const data = response.data;
                if(data.error) {
                    error.value = true;
                    errorText.value = data.error['verifycodeform-code'][0];
                } else {
                    cookies.set('token', response.data.token);
                    store.commit(AUTH_SET_USER, response.data.user);
                    router.push(store.state.auth.routeTo);
                    handleHideAuth();
                }
            }).catch(err => {
                console.log('err',err);
                loader.value = false;
            });
        };

        return {
            code,
            error,
            errorText,
            handleVerifyCode
        }
    }
}
</script>

<style scoped>

  .verify-form {
    margin-top: 10px;
  }

  .form-group label {
    font-weight: 400;
  }

  .form-group.code button {
    font-size: 10px;
    background-color: #3d7fa2;
    color: #ffffff;
    text-transform: uppercase;
  }

  .form-group.code .input-group {
    max-width: 200px;
    margin: 0 auto;
  }

  .form-group.code>p {
    margin-bottom: 5px;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  .error {
    max-width: 200px;
    margin: 0 auto;
  }

</style>
