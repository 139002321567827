import { postRequest, getRequest } from './axios';
import {
  AUTH_SEND_CODE_URL,
  AUTH_SEND_VERIFY_CODE_URL,
  AUTH_LOGOUT_URL,
  AUTH_LOGIN_BY_MANAGER_TOKEN_URL
} from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function sendCodeRequest(formData) {
  return postRequest(AUTH_SEND_CODE_URL, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function verifyCodeRequest(formData) {
  return postRequest(AUTH_SEND_VERIFY_CODE_URL, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function logoutRequest() {
  return getRequest(AUTH_LOGOUT_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function loginByManagerToken(token) {
  return getRequest(AUTH_LOGIN_BY_MANAGER_TOKEN_URL + '?token=' + token, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}
