<template>
  <section class="notary">
    <span class="title">Отправка нотариусу</span>
    <info-component
      :notary="notary"
      :is-full-payed="isFullPayed"
      :all-report-confirmed="allReportConfirmed"
      :all-report-complete="allReportComplete"
      :all-report-signed="allReportSigned"
    />
    <actions-component v-if="notary && allReportSigned && notary.emails.length && isFullPayed" :notary="notary"/>
  </section>
</template>

<script>
import InfoComponent from "./info/InfoComponent";
import ActionsComponent from "./actions/ActionsComponent";
export default {
    name: "NotaryComponent",
    components: {
        InfoComponent,
        ActionsComponent
    },
    props: {
        notary: {
            type: Object
        },
        allReportSigned: {
            type: Boolean,
            required: true
        },
        isFullPayed: {
            type: Boolean,
            required: true
        },
        allReportConfirmed: {
            type: Boolean,
            required: true
        },
        allReportComplete: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

  .notary {
    border-top: 1px dotted;
    border-color: gray;
    font-family: 'Roboto';
    color: #626262;
    margin-top: 20px;
    padding: 15px 20px 0 20px;
    position: relative;
  }

  .notary .title {
    position: absolute;
    top: -8px;
    background: white;
    left: 15px;
    padding: 0 5px;
    color: #3294ca;
  }

</style>
