<template>
  <div class="image">
    <img :src="'data:image/jpg;base64,'+base64" :title="name"/>
  </div>
</template>

<script>
export default {
    name: "ImageComponent",
    props: {
        base64: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

  .image {
    max-width: 103px;
  }

</style>
