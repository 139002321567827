<template>
  <div class="date">{{ datetime }}</div>
</template>

<script>
export default {
    name: "DateComponent",
    props: {
        datetime: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

  .date {
    display: block;
    text-align: right;
    font-size: 10px;
    color: #ababab;
    margin-top: 4px;
  }

</style>
