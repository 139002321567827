<template>
  <div class="legacy-layout">
    <header-component/>
    <div class="wrap">
      <main>
        <div class="container">
          <slot/>
        </div>
      </main>
    </div>
    <footer-component/>
    <auth-component v-if="showAuth"/>
    <messenger-component/>
    <loader-component v-if="loader" position="fixed"/>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import HeaderComponent from "../components/header/HeaderComponent";
import FooterComponent from "../components/FooterComponent";
import AuthComponent from "../components/auth/AuthComponent";
import MessengerComponent from "../components/messenger/MessengerComponent";
import LoaderComponent from "../components/LoaderComponent";

export default {
    components: {
        AuthComponent,
        HeaderComponent,
        FooterComponent,
        MessengerComponent,
        LoaderComponent
    },
    setup() {

        const store = useStore();

        return {
            showAuth: computed(() => store.state.auth.display),
            loader: computed(() => store.state.loader)
        }
    }
}
</script>

<style>

  .legacy-layout {
    height: 100%;
  }

  .legacy-layout h1 {
    font-size: 24px;
    color: #363636;
    margin-bottom: 20px;
  }

  .legacy-layout h2 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #000000;
  }

  .legacy-layout .wrap {
    position: relative;
    padding-top: 54px;
    min-height: 100%;
    padding-bottom: 225.8px;
  }

  .legacy-layout main {
    padding: 20px;
  }

  .legacy-layout .content {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  }

  .legacy-layout .content-15 {
    padding: 15px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    box-shadow: 0 0 5px rgb(0 0 0 / 10%);
  }

  .legacy-layout .page-loader {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(236, 239, 244, 0.9);
    width: 100%;
    height: 100%;
  }

  .legacy-layout .page-loader>div {
    position: absolute;
    text-align: center;
    top: 50%;
    color: #4a4a4a;
    width: 100%;
    margin-top: -25px;
  }

  .legacy-layout .page-loader>div>svg {
    font-size: 30px;
  }

  .legacy-layout .page-loader>div>p {
    font-size: 16px;
    margin-top: 10px;
    font-weight: 500;
    padding: 0 15px;
  }

  @media (max-width: 992px) {

    .legacy-layout main {
      padding: 0;
    }

    .legacy-layout main {
      margin-top: 20px;
    }

  }

</style>
