<template>
  <h3>{{ name }}</h3>
</template>

<script>
export default {
    name: "NameComponent",
    props: {
        name: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

  h3 {
    font-size: 18px;
    font-weight: 500;
    color: #626262;
    font-family: Roboto;
    display: inline-block;
    vertical-align: middle;
  }

</style>
