<template>
  <div class="object">
    <div class="object-body">
      <div>
        <div class="col-left">
          <div class="image">
            <span class="count">{{index+1}}</span>
            <img :src="'data:image/jpg;base64,'+service.object.image" :title="service.object.name">
          </div>
        </div>
        <div class="col-center">
          <div class="files">
            <div class="file-item file-item-zip">
              <span class="file-name">
                <template v-if="service.isArchive">
                  <a href="#" @click="handleRecovery">
                    Отчет в архиве, нажмите чтобы разархивировать
                  </a>
                </template>
                <template v-else>
                  <a href="#" @click="handleGetZip">
                    <font-awesome-icon icon="file-archive"/>
                    Скачать все .zip архивом
                  </a>
                </template>
              </span>
            </div>
          </div>
        </div>
        <div class="col-right">
          <div class="sum-info">
            <template v-if="digitalReportType === 2 && paperReportType === 1">
              <p><b>Цена:</b> {{service.price}} руб.</p>
              <p>Электронно</p>
            </template>
            <template v-else-if="digitalReportType === 2 && (paperReportType === 2 || paperReportType === 3)">
              <p><b>Цена:</b> {{parseInt(service.price)+parseInt(order.reportPrice)}} руб.</p>
              <p>Электронно + на бумаге</p>
              <p>(в т.ч. +{{order.reportPrice}} руб. за бум. экз.)</p>
            </template>
            <template v-else>
              <p><b>Цена:</b> {{parseInt(service.price)+parseInt(order.reportPrice)}} руб.</p>
              <p>На бумаге<br/>(в т.ч. +{{order.reportPrice}} руб. за бум. экз.)</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import { getZipRequest, reArchiveRequest } from "../../../../../api/orders";
import { downloadFile } from "../../../../../helpers/file";

export default {
    name: 'ObjectComponent',
    props: {
        index: {
          type: Number,
          required: true
        },
        service: {
          type: Object,
          required: true
        },
        digitalReportType: {
          type: Number,
          required: true
        },
        paperReportType: {
            type: Number,
            required: true
        }
    },
    setup(props) {

        const order = inject('order');
        const searchArchive = inject('searchArchive');
        const loader = inject('archiveLoader');
        const toaster = createToaster({ position: 'top' });

        const handleGetZip = (e) => {
            e.preventDefault();
            toaster.warning('Zip архив формируется. Это может занять некоторое время...');
            loader.value = true;
            getZipRequest(order.id, props.service.id)
                .then(response => {
                    loader.value = false;
                    downloadFile(response.data, props.service.number)
                })
                .catch(err => console.log(err));
        };

        const handleRecovery = (e) => {
            e.preventDefault();
            toaster.warning('Идет процесс разархивации. Это может занять некоторое время...');
            loader.value = true;
            reArchiveRequest(order.id, props.service.id)
                .then(() => {
                    loader.value = false;
                    searchArchive()
                })
                .catch(err => console.log(err));
        };

        return {
            order,
            handleGetZip,
            handleRecovery
        }
    }
}
</script>

<style scoped>

.object .object-body {
  display: table;
  width: 100%;
}

.object .object-body>div {
  display: table-row;
}

.object .object-body .col-left {
  width: 16%;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: table-cell;
  vertical-align: middle;
}

.object .object-body .col-center {
  width: 61%;
  padding: 10px 15px;
  display: table-cell;
  vertical-align: middle;
}

.object .object-body .col-right {
  width: 23%;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}

.object .object-body .col-left .image {
  position: relative;
}

.object .object-body .image .count {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #FFF;
  color: #3294ca;
  border: 1px solid #3294ca;
  padding: 3px 7px;
  font-size: 14px;
  border-radius: 15px;
}

.object .files .file-name>a {
  color: #3294ca;
  font-size: 15px;
  font-weight: normal;
}

</style>
