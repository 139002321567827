<template>
  <div class="files">
    <div v-if="files.length" class="list">
      <file-component
        v-for="(file, index) in files"
        :key="file.id"
        :file="file"
        :is-last="files.length === (index+1)"
        :object-id="objectId"
      />
    </div>
    <upload-button-component v-if="status.id === 2" :sum-size="sumSize" :upload-event="handleUploadFiles"/>
    <error-component v-if="error" :text="error" />
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { uploadFilesRequest } from "../../../../../../../../api/preOrders";
import { getExtensionByName, bytesToMegabytes } from "../../../../../../../../helpers/file";
import FileComponent from "./file/FileComponent";
import UploadButtonComponent from "./uploadButton/UploadButtonComponent";
import ErrorComponent from "./error/ErrorComponent";
export default {
    name: "FilesComponent",
    components: {
        FileComponent,
        UploadButtonComponent,
        ErrorComponent
    },
    props: {
        files: {
            type: Array,
            required: true
        },
        status: {
            type: Object,
            required: true
        },
        sumSize: {
            type: Number,
            required: true
        },
        maxSize: {
            type: Number,
            required: true
        },
        objectId: {
            type: Number,
            required: true
        }
    },
    setup(props) {

        const error = ref('');
        const loader = inject('loader');
        const orderId = inject('orderId');
        const searchPreOrders = inject('searchPreOrders');
        const allowExtension = ['jpg','jpeg','png','pdf'];

        const validateFile = (uploadFiles) => {
            error.value = '';
            for (let i=0; i<uploadFiles.length; i++) {
                const uploadFile = uploadFiles[i];
                const extension = getExtensionByName(uploadFile.name);
                if (!extension || allowExtension.indexOf(extension)<0) {
                    error.value = 'Неверный формат файла. Допустимые - JPG, PNG, PDF.';
                    return false;
                } else {
                    if((props.sumSize+uploadFile.size)>props.maxSize) {
                        error.value = 'Файл не загружен. Превышен свободный лимит ('+bytesToMegabytes(props.maxSize)+' мб).';
                        return false;
                    }
                }
            }
            return true;
        };

        const handleUploadFiles = (e) => {
            const uploadFiles = e.target.files || e.dataTransfer.files;
            if (!uploadFiles.length || uploadFiles.length === 0) {
                return;
            }
            if(!validateFile(uploadFiles)) {
                return;
            }
            const formData = new FormData();
            for (let i=0; i<uploadFiles.length; i++) {
                formData.append('UploadFileForm[files][]',uploadFiles[i]);
            }
            loader.value = true;
            uploadFilesRequest(orderId, props.objectId, formData)
                .then(() => {
                    searchPreOrders().then(() => loader.value = false);
                })
                .catch(err => console.log(err));
        };

        return {
            error,
            handleUploadFiles
        }
    }
}
</script>

<style scoped>

  .files {
    font-size: 16px;
    margin-top: 5px;
    color: #555555;
  }

  .files .list {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  @media (max-width: 992px) {

    .files .list {
      display: block;
    }

  }

</style>
