<template>
  <layout-legacy>
    <custom-breadcrumbs/>
    <h1>{{ !page.h1?page.title:page.h1 }}</h1>
    <div class="service-container">
      <a name="start"></a>
      <div class="row">
        <div v-if="page.description" class="col-sm-12">
          <div class="description" v-html="page.description"></div>
        </div>
        <div class="col-sm-4 service_image">
          <div>
            <div class="image">
              <img :src="object.image" :title="page.title" />
            </div>
            <div class="image-bottom">
              <span>Добавить объект в список оценки</span>
              <div class="cart">
                <cart-action-component :id="object.id"/>
              </div>
            </div>
          </div>
        </div>
        <promo-component
          :object="object"
          :unit-name="page.unitName"
          :price-paper-report="pricePaperReport"
          :ride="page.ride"
        />
      </div>
      <div v-html="page.content"></div>
    </div>
  </layout-legacy>
</template>

<script>
import { ref, onMounted, watchEffect } from 'vue';
import { useSeoMeta, useHead } from '@unhead/vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { BREADCRUMBS_SET_ITEM } from "../../../../store/constants";
import LayoutLegacy from "../../../../layouts/LayoutLegacy";
import CartActionComponent from "../../../../components/cart/CartButtonsComponent";
import LoaderComponent from "../../../../components/LoaderComponent";
import PromoComponent from "./promo/PromoComponent";
import CustomBreadcrumbs from "../../../../components/BreadcrumbComponent";

export default {
    name: "ServiceView",
    components: {
        LayoutLegacy,
        CartActionComponent,
        LoaderComponent,
        PromoComponent,
        CustomBreadcrumbs
    },
    setup() {

        const route = useRoute();
        const store = useStore();
        const parent = ref(null);
        const page = ref(null);
        const object = ref(null);
        const title = ref('');
        const metaKeywords = ref('');
        const metaDescription = ref('');

        watchEffect(() => {
            if(store.state.objects.length && store.state.pages.length) {

                page.value = store.getters.getPage(route.path);

                if (page.value !== undefined) {

                    object.value = store.state.objects.find(item => item.id === page.value.objectId);

                    if(page.value.title) {
                        title.value = page.value.title;
                    } else {
                        title.value = page.value.name;
                    }

                    metaKeywords.value = page.value.meta_keywords;
                    metaDescription.value = page.value.meta_description;

                    parent.value = store.getters.getPage('/oc');

                }

            }
        });

        onMounted(() => {

            useSeoMeta({
                title: title,
                keywords: metaKeywords,
                description: metaDescription,
                ogLocale: 'ru_RU',
                ogSiteName: 'Наследство.Онлайн: сервис оценки с отправкой нотариусу',
                ogTitle: title,
                ogDescription: metaDescription,
                ogType: 'website',
                ogUrl: location.href,
                ogImage: object.value.image
            });

            useHead({
                link: [{name: 'canonical', content: location.href}]
            });

            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: parent.value.name, path: `/${parent.value.alias}` }
            });

            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: page.value.name, path: `/oc/${page.value.alias}` }
            });

        });

        return {
            page,
            object,
            pricePaperReport: store.state.pricePaperReport
        }

    }
}
</script>

<style scoped>

  .service-container {
    padding: 20px 10%;
    background-color: #fff;
    font-family: 'Roboto', Tahoma;
    font-size: 16px;
  }

  .service_descr {
    padding-top: 20px;
  }

  .service_image {
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .service_image>div {
    width: 100%;
  }

  .service_image>img {
    display: inline-block;
    width: max-content;
    height: max-content;
    margin: 0 auto;
  }

  .service_image .image-bottom {
    margin-top: 20px;
    padding: 10px;
  }

  .service_image .image-bottom span {
    display: inline-block;
    max-width: 135px;
    vertical-align: middle;
  }

  .service_image .image-bottom .cart {
    display: inline-block;
    vertical-align: middle;
  }

  .detail_name {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-weight: 700;
    margin: auto;
  }

  .detail_value {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    font-size: 16px;
    margin: auto;
  }

  .old_price {
    color: red;
    text-decoration: line-through;
    margin-right: 5px;
  }

  .grayline {
    background-color: #eee;
    border: 1px solid;
    border-color: #f5f5f5;
  }

  .service_add_info {
    text-indent: 1em;
    font-size: 22px;
    font-weight: 100;
  }

  .service_add_info h2, h3 {
    text-indent: 1em;
    font-weight: 700;
  }

  .service_add_info h2 {
    font-size: 22px;
    margin-top: 30px;
  }

  .service_add_info h3 {
    font-size: 20px;
  }

  .add_info_row h3 {
    padding-top: 1em;
    padding-bottom: 1em;
  }

  .service_add_info p {
    text-indent: 1em;
    ligh-height: 1em;
    font-size: 18px;
  }

  .service_add_info p a {
    color: #333;
    border-bottom: 1px dashed;
  }

  .service_add_info p a:hover {
    color: #333;
    border-bottom: 0px;
    text-decoration: none;
  }

  .service_add_info ul {
    padding-bottom: 10px;
  }

  .service_add_info ul li {
    color: #333;
    font-size: 18px;
    line-height: 1.5;
    list-style-type: disc;
    margin-left: 4em;
    font-family: 'Roboto', Tahoma;
  }

  .add_info_row p {
    text-align: justify;
    line-height: 1.5em;
    padding-bottom: 0.5em;
  }

  .add_info_row strong {
    font-weight: 700;
  }

  .service_add_articles {
  }

  .service_return_back {
    padding-top: 3em;
    padding-bottom: 3em;
  }

  .service_return_back a {
    color: #333;
    font-size: 22px;
    font-family: 'Roboto', Tahoma;
    border-bottom: 1px dashed;
  }

  .service_return_back a:hover {
    border-bottom: 0px;
    text-decoration: none;
  }

</style>
