<template>
  <div class="col-sm-3">
    <div class="object-item">
      <div class="image">
        <img :src="object.image">
      </div>
      <h3>
        <router-link :to="`/oc/${item.alias}`">
          {{ item.name }}
        </router-link>
      </h3>
      <div class="bottom">
        <div class="table">
          <div class="tr">
            <div class="td">
              <template v-if="!object.priceSale">
                <div class="price">
                  {{ object.price }} руб.
                </div>
              </template>
              <template v-else>
                <div class="price_sale">
                  {{ object.price }} руб.
                </div>
                <div class="price">
                  {{ object.priceSale }} руб.
                </div>
              </template>
            </div>
            <div class="td">
              <cart-buttons-component :id="object.id" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import CartButtonsComponent from "../../../../../../components/cart/CartButtonsComponent";
export default {
    name: 'ItemComponent',
    components: {
        CartButtonsComponent
    },
    props: {
        categoryAlias: {
            type: String,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        objects: {
            type: Array,
            required: true
        }
    },
    setup(props) {

        const object = ref(null);

        watchEffect(() => {
            object.value = props.objects.find(item => item.id === props.item.objectId);
        });

        return {
            object
        }
    }
}
</script>

<style scoped>

  .object-item {
    text-align: center;
    margin: 10px 0;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 10px;
  }

  .object-item>.image {
    position: relative;
    max-width: 233px;
    height: 153px;
  }

  .object-item h3 {
    font-size: 16px;
    margin: 10px 0;
    min-height: 38px;
  }

  .object-item h3>a {
    color: #212529;
    border-bottom: 1px dashed;
    text-decoration: none;
  }

  .object-item h3>a:hover {
    border-bottom: 0;
  }

  .object-item .table {
    width: 100%;
  }

  .object-item .table .td {
    padding: 5px 10px;
    vertical-align: middle;
  }

  .object-item .table .td:first-child {
    text-align: center;
  }

  .object-item .table .td:last-child {
    text-align: right;
  }

  .object-item .table .tr:nth-child(2n) {
    background-color: #f3f3f3;
  }

  .object-item .bottom {
    margin: 0 auto;
    background-color: #e9e9e9;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
  }

  .object-item .price {
    font-size: 15px;
    font-weight: bold;
  }

  .object-item .price_sale {
    font-size: 15px;
    color: red;
    text-decoration: line-through;
  }

  @media (max-width: 992px) {

    .col-sm-3 {
      flex: 0 0 33%;
      max-width: 33%;
    }

  }

  @media (max-width: 768px) {

    .col-sm-3 {
      flex: 0 0 50%;
      max-width: 50%;
    }

  }

  @media (max-width: 480px) {

    .col-sm-3 {
      flex: 0 0 100%;
      max-width: 100%;
    }

  }

</style>
