<template>
  <layout-legacy>
    <breadcrumb-component/>
    <section class="cart">
      <template v-if="cartObjects.length">
        <desktop-component
          class="desktop"
          :cart-objects="cartObjects"
          :report-type="type"
          :paper-report-price="pricePaperReport"
          :price="price"
          :delete-event="handleDeleteCartItem"
          :change-report-type-event="handleSetReportType"
        />
        <mobile-component
          class="mobile"
          :cart-objects="cartObjects"
          :report-type="type"
          :paper-report-price="pricePaperReport"
          :price="price"
          :delete-event="handleDeleteCartItem"
          :change-report-type-event="handleSetReportType"
        />
      </template>
      <template v-else>
        <div class="align-center">
          Корзина пуста
        </div>
      </template>
    </section>
  </layout-legacy>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
import { useStore } from 'vuex';
import { computed, ref, watchEffect, onMounted } from 'vue';
import { useSeoMeta } from '@unhead/vue';
import {
    BREADCRUMBS_SET_ITEM,
    CART_DROP_ITEM_BY_INDEX,
    CART_EDIT_FIELDS,
    CART_GET_CART_DEFAULT
} from "../../store/constants";
import LayoutLegacy from "../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";
import DesktopComponent from "./desktop/DesktopComponent";
import MobileComponent from "./mobile/MobileComponent";

export default {
    name: 'CartView',
    components: {
        LayoutLegacy,
        BreadcrumbComponent,
        DesktopComponent,
        MobileComponent
    },
    setup() {

        const store = useStore();
        const cartObjects = ref([]);
        const price = ref(0);
        const toaster = createToaster({position: 'top'});
        const cart = store.getters[CART_GET_CART_DEFAULT];
        const objects = computed(() => store.state.objects);
        const pricePaperReport = computed(() => store.state.pricePaperReport);

        const initCart = () => {
            let sum = 0;
            cartObjects.value = cart.items.map(item => {
                const object = objects.value.find(object => parseInt(object.id) === item.id);
                object.dbIndex = item.index;
                if(object.priceSale) {
                    sum += object.priceSale;
                } else {
                    sum += object.price;
                }
                object.page = store.getters.getPageByObject(object.id);
                return object;
            });
            if(cart.type === 2) {
                sum += (pricePaperReport.value*cartObjects.value.length);
            }
            price.value = sum;
        };

        const handleDeleteCartItem = (itemIndex) => {
            store.dispatch(CART_DROP_ITEM_BY_INDEX, itemIndex);
            toaster.success('Успешно удалено из корзины');
        };

        const handleSetReportType = () => {
            if(!cart.type || cart.type===1) {
              store.dispatch(CART_EDIT_FIELDS, { index: 'type', value: 2 });
            } else {
              store.dispatch(CART_EDIT_FIELDS, { index: 'type', value: 1 });
            }
        };

        watchEffect(() => {
            if (store.state.pages.length) {
                initCart();
            }
        });

        onMounted(() => {

            useSeoMeta({
                title: 'Корзина товаров',
                keywords: '',
                description: ''
            });

            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Корзина товаров', path: '/cart' }
            });

        });

        return {
            price,
            type: computed(() => cart.type),
            cartObjects,
            handleDeleteCartItem,
            handleSetReportType,
            pricePaperReport
        }
    }
};
</script>

<style scoped>

</style>
