<template>
  <div class="price">
    <span v-if="report.type === 1">
      Отчет электронный с ЭЦП: {{ price }} ₽
    </span>
    <span v-else>
      Отчет на бумаге: {{ price }} ₽
    </span>
  </div>
</template>

<script>
export default {
    name: "PriceComponent",
    props: {
        report: {
            type: Object,
            required: true
        },
        price: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

  .price {
    margin-top: 5px;
    font-family: Roboto;
    color: #626262;
  }

</style>
