<template>
  <div class="services">
    <instruction-component/>
    <sort-component :categories="categories"/>
    <grid-component v-if="view === 'grid'" :categories="categories" :objects="objects"/>
    <list-component v-else-if="view === 'list'" :categories="categories" :objects="objects"/>
    <div class="info-block">
      <strong>При сумме заказа до 2000 руб. - предоплата не требуется. Более 2000 руб. - требуется предоплата 50% от стоимости заказа.</strong>
    </div>
  </div>
</template>

<script>
import { ref, provide, watchEffect, computed } from 'vue';
import { useStore } from 'vuex';
import InstructionComponent from "./InstructionComponent";
import SortComponent from "./SortComponent";
import GridComponent from "./grid/GridComponent";
import ListComponent from "./list/ListComponent";

export default {
    name: 'ServicesComponent',
    components: {
        InstructionComponent,
        SortComponent,
        GridComponent,
        ListComponent
    },
    setup() {

        const categories = ref([]);
        const currentPage = ref(null);
        const currentCategory = ref(0);
        const view = ref('grid');
        const store = useStore();
        const pages = computed(() => store.state.pages);
        const objects = computed(() => store.state.objects);

        watchEffect(() => {
            if (pages.value.length) {
                currentPage.value = pages.value.filter(page => page.id === 1)[0];
                categories.value = currentPage.value.items;
            }
        });

        provide('view', view);
        provide('currentCategory', currentCategory);

        return {
            categories,
            view,
            objects
        }
    }
}
</script>

<style scoped>

  .services .list .object-item {
    padding: 10px;
  }

  .services .list .object-item:nth-child(2n) {
    background: #e5e5e5;
  }

  .services .list h3 {
    min-height: auto;
  }

  .services .info-block {
    text-align: center;
    margin-top: 20px;
  }

</style>
