<template>
    <div>
      <menu-component/>
    </div>
</template>

<script>
import MenuComponent from "./MenuComponent";
export default {
    name: "SideBarComponent",
    components: {
        MenuComponent
    }
}
</script>

<style scoped>

</style>
