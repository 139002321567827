export function getExtensionByName(name) {
  const nameSplit = name.split('.');
  if(nameSplit.length>1) {
    return nameSplit[nameSplit.length-1].toLowerCase();
  } else {
    return null;
  }
}

export function getSrc(file) {
  return URL.createObjectURL(file);
}

export function sizeFormatBytes(bytes, ext = true, decimals = 2) {
  if (bytes === 0) {
    return 0;
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['байт', 'кб', 'мб', 'гб', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if(ext) {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  } else {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }
}

export const isImage = (extension) => {
  const extensions = ['jpg','png','jpeg','heic'];
  if(extensions.indexOf(extension) > -1) {
    return true;
  }
  return false;
};

export const openFile = (blobFile) => {
  const blob = new Blob([blobFile],{ type: blobFile.type });
  const url = window.URL.createObjectURL(blob, {
    type: blobFile.type
  });
  window.open(url, '_blank');
};

export const downloadFile = (blobFile, fileName) => {
  const blob = new Blob([blobFile],{ type: blobFile.type });
  const url = window.URL.createObjectURL(blob, {
    type: blobFile.type
  });
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.append(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const bytesToMegabytes = (bytes) => {
  return bytes/(1024*1024);
};

export const getShortName = (name, maxLength = 25) => {
  const splitArr = name.split('.');
  let extension;
  if(splitArr.length) {
    extension = splitArr[splitArr.length-1];
  }
  if(name.length > maxLength) {
    name = name.substr(0, maxLength-8) + '... .' + extension;
  }
  return name;
};
