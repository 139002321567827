<template>
  <div class="modal-custom" :style="getStyle()" @click="(e) => handleHideModal(e,true)" ref="mainRef">
    <div :style="getInnerStyle()">
      <div class="content">
        <div class="modal-header" v-if="title">
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" data-dismiss="modal" aria-label="Close" class="close" @click="handleHideModal">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="body">
          <slot name="body"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'ModalComponent',
    props: {
        show: {
            type: Boolean,
            required: false,
            default: true
        },
        title: {
            type: String,
            required: false
        },
        widthPercent: {
            type: Number,
            required: false,
            default: 70
        },
        closeOverlay: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    setup(props, { emit }) {

        const mainRef = ref(null);

        const getStyle = () => {
            const styles = ref([]);
            if (props.show) {
                styles.value.push('opacity: 1');
                styles.value.push('visibility: visible');
            }
            return styles.value.join(';');
        };

        const getInnerStyle = () => {
            const styles = ref([]);
            styles.value.push('width: ' + props.widthPercent + '%');
            if (props.show) {
                styles.value.push('margin-top: 20px;');
                styles.value.push('margin-bottom: 20px;');
            }
            return styles.value.join(';');
        };

        const handleHideModal = (e, isOverlay) => {
            if(isOverlay) {
                if(props.closeOverlay) {
                    if(e.target === mainRef.value) {
                        emit('update:show', false);
                    }
                }
            } else {
                emit('update:show', false);
            }
        };

        return {
            getStyle,
            getInnerStyle,
            handleHideModal,
            mainRef
        }
    }
}
</script>

<style scoped>

  .modal-custom {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    overflow-y: auto;
  }

  .modal-custom>div {
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 5;
    position: relative;
  }

  .modal-custom>div>.content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    padding: 0;
  }

  .modal-custom>div>.content>.body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }

</style>
