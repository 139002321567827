<template>
  <div class="header">
    <div class="title">Чат с менеджером</div>
    <div class="actions">
      <a href="#" class="action-fullSize" @click="fullSizeEvent">
        <font-awesome-icon icon="expand" />
      </a>
      <a href="#" class="action-close" @click="hideEvent">x</a>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        fullSizeEvent: {
            type: Function,
            required: true
        },
        hideEvent: {
            type: Function,
            required: true
        }
    }
}
</script>

<style scoped>

  .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #3294ca;
  }

  .title {
    color: #ffffff;
    font-size: 14px;
    padding: 15px;
    font-weight: 500;
    cursor: pointer;
  }

  .actions {
    position: absolute;
    right: 15px;
    top: 9px;
  }

  .actions>a {
    margin-left: 10px;
    color: #ffffff;
  }

  .action-close {
    font-size: 21px;
    text-decoration: none;
  }

  .action-fullSize {
    font-size: 15px;
    text-decoration: none;
  }

</style>
