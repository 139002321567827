export const BASE_URL = `${process.env.VUE_APP_API_URL}`;

export const USER_URL = '/user';

export const AUTH_SEND_CODE_URL = '/auth/send-code';
export const AUTH_SEND_VERIFY_CODE_URL = '/auth/verify-code';
export const AUTH_REFRESH_TOKEN_URL = '/auth/refresh-token';
export const AUTH_LOGOUT_URL = '/auth/logout';
export const AUTH_LOGIN_BY_MANAGER_TOKEN_URL = '/auth/login-by-manager-token';

export const PRE_ORDERS_URL = '/pre-orders';
export const ORDERS_URL = '/orders';
export const PAGES_URL = '/pages';
export const NOTARIES_URL = '/notaries';

export const MESSENGER_GET_DATA_URL = '/messenger/get-data';
export const MESSENGER_ADD_MESSAGE_URL = '/messenger/add-message';
export const MESSENGER_ADD_FILE_URL = '/messenger/add-file';
export const MESSENGER_EDIT_MESSAGE_URL = '/messenger/edit-message';
export const MESSENGER_DELETE_MESSAGE_URL = '/messenger/delete-message';
export const MESSENGER_LOAD_FILE_URL = '/messenger/load-file';

export const ORDERS_GET_DATA_URL = '/orders/default/get-data';

export const ORDERS_ORDER_SEARCH_URL = '/orders/order/search';
export const ORDERS_ORDER_GET_FILE_URL = '/orders/order/get-file';
export const ORDERS_ORDER_GET_ZIP_URL = '/orders/order/get-zip';
export const ORDERS_ORDER_REPORT_MODIFY_URL = '/orders/order/report-modify';
export const ORDERS_ORDER_REPORT_CONFIRM_URL = '/orders/order/report-confirm';
export const ORDERS_ORDER_SHARE_FILES_URL = '/orders/order/share-files';
export const ORDERS_ORDER_SHARE_FILES_GET_ORDER_URL = '/orders/order/share-files/get-order';
export const ORDERS_ORDER_SHARE_FILES_DOWNLOAD_ZIP_URL = '/orders/order/share-files/download-zip';
export const ORDERS_ORDER_SHARE_FILES_DOWNLOAD_FILE_URL = '/orders/order/share-files/download-file';
export const ORDERS_ORDER_GET_PAYMENT_LINK_URL = '/orders/order/get-payment-link';
export const ORDERS_ORDER_GET_PAYMENT_LINK_WITH_EMAIL_URL = '/orders/order/get-payment-link-with-email';
export const ORDERS_ORDER_SET_DELIVERY_POINT_URL = '/orders/order/set-delivery-point';
export const ORDERS_ORDER_SET_DELIVERY_TYPE_URL = '/orders/order/set-delivery-type';
export const ORDERS_ORDER_SET_DELIVERY_ADDRESS_URL = '/orders/order/set-delivery-address';
export const ORDERS_ORDER_SEND_MAIL_NOTARY_URL = '/orders/order/send-mail-notary';
export const ORDERS_ORDER_RE_ARCHIVE_URL = '/orders/order/re-archive';

export const ORDERS_ARCHIVE_SEARCH_URL = '/orders/archive/search';
export const ORDERS_ARCHIVE_GET_ZIP_URL = '/orders/archive/get-zip';
export const ORDERS_ARCHIVE_RECOVERY_URL = '/orders/archive/recovery';

export const NOTIFICATIONS_URL = '/notifications';

export const SITE_INDEPENDENCE_URL = '/site/independence';
