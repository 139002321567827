import { createRouter, createWebHistory } from "vue-router";
import store from '../store';
import routes from "./routes";
import { AUTH_SHOW, BREADCRUMBS_CLEAR } from "../store/constants";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {

  if (to.meta.auth && !store.state.auth.isLoggedIn) {
    store.commit(AUTH_SHOW, { to: to });
    next(from);
  } else {
    store.commit(BREADCRUMBS_CLEAR);
    next();
  }

});

router.afterEach((to, from) => {
  window.scrollTo(0, 0);
});


export default router
