<template>
  <div class="messenger" @click="handleShow">
    <font-awesome-icon icon="comments" />
    <span v-if="count" class="count">[1]</span>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { MESSENGER_SHOW } from "../../../../../../store/constants";

export default {
    name: "MessengerComponent",
    props: {
        id: {
            type: Number
        },
        count: {
            type: Number
        }
    },
    setup(props, context) {

        const store = useStore();

        const handleShow = () => {
            store.commit(MESSENGER_SHOW, { id: props.id });
            context.emit('update:count', 0);
        };

        return {
            handleShow
        }

    }
}
</script>

<style scoped>

  .messenger {
    cursor: pointer;
    display: inline-block;
  }

  .messenger>svg {
    font-size: 24px;
    margin-right: 2px;
    color: #FFFFFF;
  }

  .messenger .count {
    color: #FFFFFF;
  }

  .messenger>svg,
  .messenger>.count {
    display: inline-block;
    vertical-align: middle;
  }

</style>
