<template>
  <div class="file-item file-item-upload">
    <a href="#">Добавить документ</a>
    <input type="file" multiple="" @change="uploadEvent">
  </div>
</template>

<script>
export default {
    name: "UploadButtonComponent",
    props: {
        sumSize: {
            type: Number,
            required: true
        },
        uploadEvent: {
            type: Function,
            required: true
        }
    }
}
</script>

<style scoped>

  .file-item-upload {
    position: relative;
    overflow: hidden;
    background-color: #3294ca;
    display: inline-block;
    vertical-align: middle;
    border: 1px solid #307399;
    padding: 2px 10px;
    border-radius: 5px;
  }

  .file-item-upload>a {
    font-size: 18px;
    color: #ffffff;
  }

  .file-item-upload input[type=file] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

</style>
