export function createBlobImageUrl(file, type = 'image/jpeg') {
  return URL.createObjectURL(new Blob([file],{type: type}));
}

export function sizeFormatBytes(bytes, ext = true, decimals = 2) {
  if (bytes === 0) {
    return 0;
  }
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['байт', 'кб', 'мб', 'гб', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  if(ext) {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  } else {
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
  }
}

export const isImage = (extension) => {
  const extensions = ['jpg','png','jpeg','heic'];
  if(extensions.indexOf(extension) > -1) {
    return true;
  }
  return false;
};
