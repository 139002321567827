<template>
  <div class="orders-cat">
    <div class="orders-cat-title">ЗАКАЗЫ: В АРХИВЕ</div>
    <div class="orders-items">
      <order-component
        v-for="order in archive.items"
        :key="order.id"
        :order="order"
      />
    </div>
    <pagination-component v-if="archive.pages.length" :items="archive.pages" @action="searchArchive" />
    <loader-component v-if="loader"/>
  </div>
</template>

<script>
import { inject } from 'vue';
import OrderComponent from "./order/OrderComponent";
import PaginationComponent from "../../../components/PaginationComponent";
import LoaderComponent from "../../../components/LoaderComponent";

export default {
    name: "Archive",
    components: {
        OrderComponent,
        PaginationComponent,
        LoaderComponent
    },
    setup() {

        const archive = inject('archive');
        const searchArchive = inject('searchArchive');
        const loader = inject('archiveLoader');

        return {
            archive,
            searchArchive,
            loader
        }
    }
}
</script>

<style scoped>

  .orders-cat {
    padding: 15px;
    border: 1px solid #cccccc;
    border-radius: 15px;
    margin-bottom: 20px;
    position: relative;
  }

  .orders .orders-cat:last-child {
    margin-bottom: 0;
  }

  .orders-cat-title {
    position: absolute;
    top: -10px;
    left: 15px;
    background-color: #3294ca;
    padding: 3px 5px;
    text-transform: uppercase;
    font-size: 12px;
    color: #FFFFFF;
  }

</style>
