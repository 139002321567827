<template>
  <div v-if="filePanel" class="file-panel">
    <div class="drop-panel">
      <p>Перетащите файлы в эту область, скопируйте и вставьте<br/>или выберите файлы</p>
      <button>
        Выбрать файлы
        <input type="file" multiple="multiple" ref="inputRef" @change="handleOnChangeFiles" />
      </button>
      <div v-if="formFields.files.errors.length" class="errors">
        <p class="error" v-for="error in formFields.files.errors" :key="error">{{ error }}</p>
      </div>

    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { getExtensionByName } from '../../../../helpers/file';
import { v4 as uuidv4 } from "uuid";
export default {
    name: "FilePanelComponent",
    setup() {

        const inputRef = ref();
        const formFields = inject('formFields');
        const filePanel = inject('filePanel');
        const fileRules = inject('fileRules');

        const handleOnChangeFiles = () => {
            const inputFiles = inputRef.value.files;
            if (!inputFiles.length) return;
            addFiles(inputFiles);
        };

        const addFiles = (inputFiles) => {

            const rules = fileRules;
            formFields.value.files.errors = [];

            for(let i=0; i<inputFiles.length; i++) {

                const file = inputFiles[i];
                const extension = getExtensionByName(file.name);

                if(extension) {

                    let errorFile = false;

                    if(rules.allowedExtensions.indexOf(extension) === -1) {
                        errorFile = true;
                        if (!formFields.value.files.errors.find(err => err === 'Недопустимый формат файла')) {
                            formFields.value.files.errors.push('Недопустимый формат файла');
                        }
                    }

                    if(file.size > rules.maxSize) {
                        errorFile = true;
                        if (!formFields.value.files.errors.find(err => err === 'Размер файла превышает допустимый размер')) {
                            formFields.value.files.errors.push('Размер файла превышает допустимый размер');
                        }
                    }

                    if(!errorFile) {
                        file.id = uuidv4();
                        formFields.value.files.value.push(file);
                    }
                }
            }

            if(!formFields.value.files.errors.length) {
                filePanel.value = false;
            }

        };

        return {
            filePanel,
            formFields,
            inputRef,
            handleOnChangeFiles
        };
    }
}
</script>

<style scoped>

  .file-panel {
    border: 1px dashed #b9b9b9;
    padding: 2px;
    margin-bottom: 20px;
  }

  .drop-panel {
    background-color: #efefef;
    padding: 20px;
  }

  .drop-panel>p {
    margin-bottom: 20px;
  }

  .drop-panel>button {
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #cccccc;
    position: relative;
    padding: 8px 10px;
    font-weight: 500;
  }

  .drop-panel>button>input {
    visibility: visible;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .error {
    margin: 10px 0 0 0 !important;
  }

</style>

