import { createApp } from 'vue';
import { createHead } from '@unhead/vue';
import App from './components/App.vue';
import store from './store';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toaster from "@meforma/vue-toaster";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCog, faBars, faTrash, faIdBadge, faEdit, faBell, faHandPaper, faClipboardList, faPlusSquare, faMinusSquare, faFileAlt, faCopy, faArrowLeft, faArrowRight, faFile, faCamera, faCloudUploadAlt, faFileArchive, faSpinner, faUpload, faFilePdf, faFileWord, faRedo, faAngleDown, faPlusCircle, faCaretLeft, faCaretRight, faWindowClose, faUndo, faSun, faAdjust, faEye, faArrowsAltH, faCrop, faHandScissors, faSignOutAlt, faBackward, faForward, faPencilAlt, faSignInAlt, faMap, faThLarge, faFileDownload, faArrowDown, faDownload, faHourglassStart, faCheckSquare, faUserCircle, faUserAlt, faCartArrowDown, faCartPlus, faComment, faBook, faQuestionCircle, faTimesCircle, faComments, faCaretSquareDown, faCaretSquareUp, faFileImage, faExpand, faRss, faLongArrowAltRight, faTh, faThList, faReply, faPen, faTimes, faEllipsisH, faPaperclip, faInfoCircle, faPenSquare } from '@fortawesome/free-solid-svg-icons';
import { faTelegram, faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import SlideUpDown from 'vue3-slide-up-down'
import DadataSuggestions from './components/dadataSuggestions';
import LoadScript from 'vue-plugin-load-script';
import Vue3TouchEvents from "vue3-touch-events";
import breadcrumbs from 'vue-3-breadcrumbs'
import { RouterLink,  RouterView } from 'vue-router';
import { vMask } from "@bardoui/vmask";
import VueDatePicker from '@vuepic/vue-datepicker';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './assets/css/reset.css';
import './assets/css/style.css';
import './assets/css/mobile.css';
import '@vuepic/vue-datepicker/dist/main.css';

library.add(faCog, faBars, faTrash, faIdBadge, faEdit, faBell, faHandPaper, faClipboardList, faPlusSquare, faMinusSquare, faTelegram, faFileAlt, faCopy, faArrowLeft, faArrowRight, faFile, faCamera, faCloudUploadAlt, faFileArchive, faSpinner, faUpload, faFilePdf, faFileWord, faRedo, faAngleDown, faPlusCircle, faCaretLeft, faCaretRight, faWindowClose, faUndo, faSun, faAdjust, faEye, faArrowsAltH, faCrop, faHandScissors, faSignOutAlt, faBackward, faForward, faPencilAlt, faSignInAlt, faMap, faThLarge, faFileDownload, faGoogleDrive, faArrowDown, faDownload, faHourglassStart, faCheckSquare, faUserCircle, faUserAlt, faCartArrowDown, faCartPlus, faComment, faBook, faQuestionCircle, faTimesCircle, faComments, faCaretSquareDown, faCaretSquareUp, faFileImage, faExpand, faRss, faLongArrowAltRight, faTh, faThList, faReply, faPen, faTimes, faEllipsisH, faPaperclip, faInfoCircle, faPenSquare);

RouterLink.compatConfig = {
  MODE: 3,
};
RouterView.compatConfig = {
  MODE: 3,
};

const root = createApp(App);
const head = createHead();

root
  .use(router)
  .use(store)
  .use(VueAxios, axios)
  .use(Toaster, {
    position: 'top'
  })
  .use(LoadScript)
  .use(DadataSuggestions,{ token: '15fec7ecadeb9f1bb22299252244e19cc1cc0a07'})
  .use(Vue3TouchEvents)
  .use(breadcrumbs, {
    includeComponent: true // {boolean} [includeComponent=false] - Include global breadcrumbs component or not
  })
  .use(head)

  .component('font-awesome-icon', FontAwesomeIcon)
  .component('slide-up-down', SlideUpDown)
  .component('VueDatePicker', VueDatePicker)

  .directive("mask", vMask)

  .mount('#app');
