<template>
  <span>Заказ от {{date}}</span>
</template>

<script>
export default {
    name: "NumberComponent",
    props: {
        date: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

  span {
    margin-right: 10px;
    font-size: 24px;
    color: #FFFFFF;
    margin-left: 15px;
    font-family: 'Roboto';
    font-weight: 500;
  }

</style>
