<template>
  <layout-legacy>
    <breadcrumb-component/>
    <h1>Страница не найдена</h1>
    <div>
      <p>Страница которую вы запрашиваете не существует</p>
    </div>
  </layout-legacy>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useHead } from '@unhead/vue';
import { BREADCRUMBS_SET_ITEM } from "../store/constants";
import LayoutLegacy from "../layouts/LayoutLegacy";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
export default {
    name: "NotFoundView",
    components: {
        LayoutLegacy,
        BreadcrumbComponent
    },
    setup() {

        const store = useStore();
        const route = useRoute();

        onMounted(() => {

            useHead({
                title: '(404) Страница не найдена',
                meta: [
                    { name: 'keywords', content: '' },
                    { name: 'description', content: 'Страница не найдена'  },
                ],
            });

            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Страница не найдена', path: route.path }
            });

        });

    }
}
</script>

<style scoped>

</style>
