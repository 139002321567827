const state = () => ({
  items: []
});

const getters = {};

const actions = {};

const mutations = {
  clear (state, payload) {
    state.items.splice(0, state.items.length);
  },
  setItem (state, payload) {
    state.items.push(payload.item);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
