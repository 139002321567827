import axios from 'axios';
import { BASE_URL, AUTH_REFRESH_TOKEN_URL } from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();
axios.defaults.withCredentials = true;

const httpClient = axios.create({
  baseURL: BASE_URL
});

httpClient.interceptors.response.use(response => response,error => {

  const response = error.response;
  const config = error.config;

  if(config.url === AUTH_REFRESH_TOKEN_URL) {
    cookies.remove('token');
    window.location.href = '/';
    return Promise.reject(error);
  }

  if(response.status === 401) {
    return getRequest(AUTH_REFRESH_TOKEN_URL).then(res => {
      const data = res.data;
      if(data && data.token) {
        cookies.set('token', data.token);
        cookies.set('role', data.role);
        return new Promise(resolve => {
          config.headers.Authorization = "Bearer " + data.token;
          if(config.method === 'get') {
            resolve(getRequest(config.url, config));
          } else if (config.method === 'post') {
            resolve(postRequest(config.url, config.data, config));
          }
        });
      }
    });
  }

  return Promise.reject(error);
});

export const getRequest = (endpoint, config) => {
  return httpClient.get(endpoint,config);
};

export const postRequest = (endpoint, data, config) => {
  return httpClient.post(endpoint, data, config);
};

export const deleteRequest = (endpoint, config) => {
  return httpClient.delete(endpoint, config);
};

export const updateRequest = (endpoint, data, config) => {
  return httpClient.put(endpoint, data, config);
};

export const putRequest = (endpoint, data, config) => {
  return httpClient.put(endpoint, data, config);
};

export const patchRequest = (endpoint, data, config) => {
  return httpClient.patch(endpoint, data, config);
};
