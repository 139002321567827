const state = () => ({
  defaultCart: 0,
  carts: [
    {
      name: 'cartNotary',
      db: null,
      items: [],
      type: 1
    },
    {
      name: 'cartRealty',
      db: null,
      items: [],
      type: 1
    }
  ]
});

const getters = {
  getCartDefault (state) {
    return state.carts[state.defaultCart];
  },
  getCarts (state) {
    return state.carts;
  },
  getData(state) {
    return state.data;
  }
};

const actions = {
  initCart({ getters, dispatch }) {
    const cart = getters.getCartDefault;
    return new Promise((resolve, reject) => {
      const openRequest = indexedDB.open(cart.name, 1);
      openRequest.onupgradeneeded = function (event) {
        // срабатывает, если на клиенте нет базы данных
        const db = cart.db = event.target.result;
        if (!db.objectStoreNames.contains('cart')) {
          db.createObjectStore('cart',{
            keyPath: 'index',
            autoIncrement: true
          });
        }
        if (!db.objectStoreNames.contains('fields')) {
          db.createObjectStore('fields',{
            keyPath: 'index'
          });
          const transaction = event.target.transaction;
          transaction.oncomplete = function () {
            const objectStoreFields = db.transaction('fields', "readwrite").objectStore('fields');
            objectStoreFields.add({
              index: 'type',
              value: 1
            });
            resolve(true);
          };
        }
      };
      openRequest.onerror = function () {
        console.error('Error', openRequest.error)
      };
      openRequest.onsuccess = function () {
        cart.db = openRequest.result;
        dispatch('initCartItems');
        resolve(true);
      };
    });
  },
  initCartItems({ getters, commit }) {
    const cart = getters.getCartDefault;
    const transactionCart = cart.db.transaction('cart', "readwrite");
    const objectStoreCart = transactionCart.objectStore('cart');
    const requestCart = objectStoreCart.getAll();
    requestCart.onsuccess = function() {
      if(requestCart.result) {
        commit('setItems', { items: requestCart.result });
      }
    };
    const transactionFields = cart.db.transaction('fields', "readwrite");
    const objectStoreFields = transactionFields.objectStore('fields');
    const requestFields = objectStoreFields.get('type');
    requestFields.onsuccess = function() {
      if(requestFields.result) {
        commit('setType', { type: requestFields.result.value });
      }
    };
  },
  addItem({ getters, dispatch }, id) {
    const cart = getters.getCartDefault;
    const objectStore = cart.db.transaction('cart', "readwrite").objectStore('cart');
    objectStore.put({
      id: parseInt(id)
    });
    dispatch('initCartItems');
  },
  editFields({ getters, dispatch }, value) {
    const cart = getters.getCartDefault;
    const objectStore = cart.db.transaction('fields', "readwrite").objectStore('fields');
    objectStore.put(value);
    dispatch('initCartItems');
  },
  dropItem({ getters, dispatch }, id) {
    const cart = getters.getCartDefault;
    const objectStore = cart.db.transaction('cart', "readwrite").objectStore('cart');
    const request = objectStore.getAll();
    let deleted = false;
    request.onsuccess = async function() {
      await request.result.forEach(item => {
        if(!deleted && item.id === id) {
          objectStore.delete(item.index);
          deleted = true;
          dispatch('initCartItems');
        }
      });
    };
  },
  dropItemByIndex({ getters, dispatch }, itemIndex) {
    const cart = getters.getCartDefault;
    const objectStore = cart.db.transaction('cart', "readwrite").objectStore('cart');
    const request = objectStore.get(itemIndex);
    request.onsuccess = function() {
      if(request.result) {
        objectStore.delete(itemIndex);
        dispatch('initCartItems');
      }
    };
  },
  clear({ getters }) {
    const cart = getters.getCartDefault;
    const cartStore = cart.db.transaction('cart', "readwrite").objectStore('cart');
    cartStore.clear();
    cart.items.splice(0,cart.items.length);
    const fieldsStore = cart.db.transaction('fields', "readwrite").objectStore('fields');
    fieldsStore.put({index:'type', value: 1});
    cart.type = 1;
  }
};

const mutations = {
  setItems (state, { items }) {
    state.carts[state.defaultCart].items = items;
  },
  setType (state, { type }) {
    state.carts[state.defaultCart].type = type;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
