import {
  SITE_INDEPENDENCE_URL
} from "./constants";
import { getRequest } from "./axios";
import { useCookies } from "vue3-cookies";

const { cookies }  = useCookies();

export function getIndependenceRequest() {
  return getRequest(SITE_INDEPENDENCE_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}
