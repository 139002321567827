<template>
  <div>
    <p class="alert-error">{{ text }}</p>
  </div>
</template>

<script>
export default {
    name: "ErrorComponent",
    props: {
        text: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>

  .alert-error {
    color: #ff4646;
    background-color: #ffdada;
    border: 1px solid #ff9898;
    font-size: 14px;
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
    margin: 10px 0 0 0;
  }

</style>
