<template>
  <div class="order">
    <top-component
      :date="preOrder.date"
      :messenger="preOrder.messenger"
    />
    <body-component
      :services="preOrder.services"
      :report="preOrder.report"
      :status="preOrder.status"
      :price="preOrder.price"
      :exist-new-file="preOrder.existNewFile"
    />
    <loader-component v-if="loader"/>
  </div>
</template>

<script>
import { provide, ref } from 'vue';
import TopComponent from "./top/TopComponent";
import BodyComponent from "./body/BodyComponent";
import LoaderComponent from "../../../../components/LoaderComponent";

export default {
    components: {
        TopComponent,
        BodyComponent,
        LoaderComponent
    },
    props: {
        preOrder: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const loader = ref(false);

        provide('orderId', props.preOrder.id);
        provide('loader', loader);

        return {
            loader
        }
    }
}
</script>

<style scoped>

  .order {
    position: relative;
    margin-bottom: 10px;
  }

  .order:last-child {
    margin-bottom: 0;
  }

</style>
