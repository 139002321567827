<template>
  <table v-if="message.item" class="selected">
    <tr>
      <td>
        <font-awesome-icon icon="reply" v-if="message.action === 'reply'" />
        <font-awesome-icon icon="pen" v-else-if="message.action === 'edit'" />
      </td>
      <td>
        {{message.item.text}}
      </td>
      <td>
         <span @click="handleClearCurrentMessage">
            <font-awesome-icon icon="times" />
        </span>
      </td>
    </tr>
  </table>
</template>

<script>
import { inject } from 'vue';
export default {
    name: 'CurrentMessageComponent',
    setup() {

        const message = inject('currentMessage');
        const handleClearCurrentMessage = inject('handleClearCurrentMessage');

        return {
            message,
            handleClearCurrentMessage
        }
    }
}
</script>

<style scoped>

  .selected {
    margin-bottom: 20px;
    width: 100%;
  }

  .selected td:first-child,
  .selected td:last-child {
    width: 50px;
    text-align: center;
  }

  .selected td:first-child>span,
  .selected td:last-child>span {
    cursor: pointer;
  }

  .selected td {
    font-size: 13px;
    vertical-align: middle;
  }

</style>
