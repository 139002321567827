<template>
  <div class="header">
    <div class="container">
      <div class="table">
        <div class="tr">
          <div class="td left">
            <logo-component/>
          </div>
          <div class="td right">
            <menu-component/>
            <action-bar-component/>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>

  </div>
</template>

<script>
import LogoComponent from "./LogoComponent";
import MenuComponent from "./MenuComponent";
import ActionBarComponent from "./ActionBarComponent";

export default {
    name: 'HeaderComponent',
    components: {
        LogoComponent,
        MenuComponent,
        ActionBarComponent
    }
}
</script>

<style scoped>

  .header {
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .header .td {
    vertical-align: middle;
  }

  .header .left {
    width: 30%;
  }

  .header .right {
    text-align: right;
    width: 70%;
  }

</style>
