<template>
  <section class="cart">
    <div class="row">
      <div class="col-sm-12">
        <h1>Нотариат</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="content">
          <div class="services">
            <div class="table">
              <div class="tr">
                <div class="th num">№</div>
                <div class="th">Услуга</div>
                <div class="th price">Цена</div>
                <div class="th action"></div>
              </div>
              <div v-for="(object,objectKey) in cartObjects" :key="object.id" class="tr">
                <div class="td num">{{objectKey+1}}</div>
                <div class="td info-col">
                  <div>
                    <img class="image" :src="object.image">
                    <div class="info">
                      <div class="name">
                        <router-link :to="'/oc/' + object.page.alias">
                          {{ object.page.name }}
                        </router-link>
                      </div>
                      <span class="prompt">
                        <span>Потребуются документы:</span>
                        <span v-html="object.prompt"></span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="td price">
                  <template v-if="!object.priceSale">
                    <p>{{object.price}} руб.</p>
                  </template>
                  <template v-else>
                    <p class="price_sale">{{object.price}} руб.</p>
                    <p>{{object.priceSale}} руб.</p>
                  </template>
                  <p v-if="reportType===2">+{{ paperReportPrice }} руб.</p>
                </div>
                <div class="td action">
                  <button class="btn btn-custom" @click="deleteEvent(object.dbIndex)">
                    <font-awesome-icon icon="trash" />
                  </button>
                </div>
              </div>
            </div>
            <div class="price">
              <b>
                Итого: {{price}} руб.
                <span v-if="price>2000" class="cart-info">
                    При сумме заказа более 2000 руб. потребуется предоплата 50%.
                  </span>
                <span v-if="price>5000 && price<=10000" class="cart-info">
                    При сумме заказа более 5000 руб. по данному заказу будет дополнительная скидка 5%.
                  </span>
                <span v-else-if="price>10000 && price<=15000" class="cart-info">
                    При сумме заказа более 10000 руб. по данному заказу будет дополнительная скидка 10%.
                  </span>
                <span v-else-if="price>15000 && price<=20000" class="cart-info">
                    При сумме заказа более 15000 руб. по данному заказу будет дополнительная скидка 15%.
                  </span>
                <span v-else-if="price>20000" class="cart-info">
                    При сумме заказа более 20000 руб. по данному заказу будет дополнительная скидка 20%.
                  </span>
              </b>
            </div>
          </div>
          <div class="report-type">
            <input type="checkbox" :checked="reportType===2" @click="changeReportTypeEvent()"/> Отчет требуется также на бумажном носителе (+400 руб./отчет)
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="btn-cart">
          <router-link to="/cart/order">Перейти к загрузке документов</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'DesktopComponent',
    props: {
        cartObjects: {
            type: Array,
            required: true
        },
        reportType: {
            type: Number,
            required: true
        },
        paperReportPrice: {
            type: Number,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        deleteEvent: {
            type: Function,
            required: true
        },
        changeReportTypeEvent: {
            type: Function,
            required: true
        }
    },
    setup() {

    }
};
</script>

<style scoped>

  .carts .cart {
    margin-bottom: 40px;
  }

  .cart .services .table {
    width: 100%;
  }

  .cart .services .table .td,
  .cart .services .table .th {
    padding: 5px;
    vertical-align: middle;
  }

  .cart .services .table .tr:nth-child(2n) {
    background-color: #f3f3f3;
  }

  .cart .services .table .price {
    width: 100px;
    text-align: center;
  }

  .cart .services .table .price p {
    margin-bottom: 0;
  }

  .cart .services .table .price_sale {
    color: red;
    text-decoration: line-through;
  }

  .cart .services .table .action {
    width: 60px;
    text-align: center;
  }

  .cart .services .table .num {
    width: 40px;
    text-align: center;
  }

  .cart .report-type {
    text-align: left;
    margin-top: 10px;
    margin-left: 40px;
  }

  .cart .report-type label {
    font-size: 14px;
    margin-bottom: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .report-type .double-switch {
    display: inline-block;
  }

  .cart .btn-cart {
    max-width: 300px;
    margin: 10px 0 0 auto;
  }

  .cart .btn-cart>a {
    display: block;
    text-align: center;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    font-weight: bold;
    background-color: #3194ca;
    color: #ffffff;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    border: none;
    text-decoration: none;
  }

  .services .image {
    max-width: 154px;
    vertical-align: middle;
    margin-right: 10px;
  }

  .services .info {
    vertical-align: middle;
    display: inline-block;
  }

  .services .name a {
    border-bottom: 1px dashed #000000;
    text-decoration: none;
    display: inline-block;
    margin-right: 5px;
    color: #000;
    margin-bottom: 10px;
  }

  .services .name a:hover {
    border-bottom: 1px dashed transparent;
  }

  .services .name svg {
    color: #b1b1b1;
  }

  .services .prompt {
    color: #6c6c6c;
  }

  .price {
    margin-top: 10px;
    margin-left: 40px;
  }

  .info-col>div {
    display: flex;
    align-items: center;
  }

</style>
