<template>
  <div class="sorting">
    <div class="row">
      <div class="col-sm-6">
        <div class="category-list">
          <div class="form-group">
            <select class="form-control" v-model="currentCategory" @change="handleSelectCategory">
              <option value="0">Выберите категорию</option>
              <option v-for="(category, key) in categories" :key="key" :value="category.id">{{ category.name }}</option>
            </select>
          </div>
        </div>
        <router-link class="discount-link" to="/information/skidki">Скидки до 50%!</router-link>
      </div>
      <div class="col-sm-6">
        <div class="switch-sorting-view">
          <div class="btn-group">
            <button :class="['btn','grid',view==='grid'?'active':'']" @click="handleViewEvent('grid')">
              <font-awesome-icon icon="th" />
            </button>
            <button :class="['btn','grid',view==='list'?'active':'']" @click="handleViewEvent('list')">
              <font-awesome-icon icon="th-list" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
export default {
    name: 'SortComponent',
    props: {
        categories: {
            type: Array,
            required: true
        }
    },
    setup() {

        const view = inject('view');
        const currentCategory = inject('currentCategory');

        const handleSelectCategory = () => {
            let top = 0;
            if(currentCategory.value > 0) {
                top = document.querySelector('.category-' + currentCategory.value).offsetTop
            }
            window.scrollTo({
                top: top,
                left: 0,
                behavior: "smooth",
            });
        };

        const handleViewEvent = (value) => {
            view.value = value;
        };

        return {
            view,
            handleSelectCategory,
            handleViewEvent,
            currentCategory
        }
    }
}
</script>

<style scoped>

  .sorting {
    background-color: #e9e9e9;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    padding: 10px;
  }

  .sorting .form-group {
    margin-bottom: 0;
  }

  .sorting .category-list {
    max-width: 300px;
  }

  .sorting .category-list {
    display: inline-block;
    margin-right: 5px;
  }

  .discount-link {
    font-size: 14px;
    color: #3294ca;
    text-decoration: underline;
  }

  .discount-link:hover {
    text-decoration: none;
  }


  .sorting .switch-sorting-view {
    text-align: right;
    margin: 2px 0;
  }

  .switch-sorting-view .btn {
    background-color: #e6e6e6;
    border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.25);
  }

  .switch-sorting-view .active {
    background-color: #3294ca;
  }

  .switch-sorting-view .active {
    color: #ffffff;
  }

  @media (max-width: 480px) {

    .sorting .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

  }

</style>
