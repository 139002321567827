<template>
  <div class="orders-cat">
    <div class="orders-cat-title">ЗАКАЗЫ: В РАБОТЕ</div>
    <template v-if="orders.items.length">
      <div class="orders-items">
        <order-component
          v-for="order in orders.items"
          :key="order.id"
          :order="order"
        />
      </div>
      <pagination-component v-if="orders.pages.length" :items="orders.pages" @action="searchOrders" />
      <loader-component v-if="loader"/>
    </template>
    <div v-else>
      <p>Заказов не найдено</p>
    </div>
  </div>
</template>

<script>
import { inject, onMounted, ref, provide } from "vue";
import { loadScript } from 'vue-plugin-load-script';
import { setDeliveryPointRequest } from "../../../api/orders";
import OrderComponent from "./order/OrderComponent";
import PaginationComponent from "../../../components/PaginationComponent";
import LoaderComponent from "../../../components/LoaderComponent";

export default {
  components: {
      OrderComponent,
      PaginationComponent,
      LoaderComponent
  },
  setup() {

      const orders = inject('orders');
      const searchOrders = inject('searchOrders');
      const loader = inject('ordersLoader');
      const deliveryWidget = ref(null);
      const deliveryOrderId = ref(null);

      const handleSelectDeliveryPoint = (wat) => {
          if(!deliveryOrderId.value) {
              alert('Ошибка выбора пункта доставки');
              return;
          }
          loader.value = true;
          setDeliveryPointRequest(deliveryOrderId.value, {
              value: wat.cityName + ', ' + wat.PVZ.Address
          })
              .then(() => {
                  loader.value = false;
                  deliveryOrderId.value = null;
                  searchOrders();
              })
              .catch(err => console.log(err));
      };

      const initDeliveryWidget = () => {
          loadScript(process.env.VUE_APP_API_URL + "/widget-cdek/widjet.min.js").then(() => {
              deliveryWidget.value = new ISDEKWidjet({
                  path: process.env.VUE_APP_API_URL + '/widget-cdek/scripts/',
                  servicepath: process.env.VUE_APP_API_URL + '/widget-cdek/scripts/service.php',
                  templatepath: process.env.VUE_APP_API_URL + '/widget-cdek/scripts/template.php',
                  country: 'Россия',
                  defaultCity: 'Санкт-Петербург',
                  cityFrom: 'Санкт-Петербург',
                  popup: true,
                  choose: true,
                  hidedress: true,
                  hidecash: true,
                  hidedelt: true,
                  detailAddress: true,
                  onChoose: function (wat) {
                      handleSelectDeliveryPoint(wat);
                  }
              });
          }).catch(() => {});
      };

      onMounted(() => {
          initDeliveryWidget();
      });

      provide('deliveryWidget', deliveryWidget);
      provide('deliveryOrderId', deliveryOrderId);

    return {
        orders,
        searchOrders,
        loader
    }
  }
}
</script>

<style scoped>

.orders-cat {
  padding: 15px;
  border: 1px solid #cccccc;
  border-radius: 15px;
  margin-bottom: 20px;
  position: relative;
}

.orders .orders-cat:last-child {
  margin-bottom: 0;
}

.orders-cat-title {
  position: absolute;
  top: -10px;
  left: 15px;
  background-color: #3294ca;
  padding: 3px 5px;
  text-transform: uppercase;
  font-size: 12px;
  color: #FFFFFF;
}

</style>
