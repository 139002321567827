<template>
  <ul class="nav">
    <li class="active">
      <font-awesome-icon icon="user-alt" />
      <router-link to="/settings/profile">Профиль</router-link>
    </li>
  </ul>
</template>

<script>
export default {
    name: "MenuComponent"
}
</script>

<style scoped>

  .nav>li,
  .nav>li>a {
    font-size: 14px;
    color: #495057;
  }

  .nav>li>a {
    margin-left: 10px;
  }

  .nav>li {
    width: 100%;
  }

  .nav>li.active,
  .nav>li.active a {
    color: #AD2420;
  }

</style>
