<template>
  <div class="order">
    <top-component
      :number="order.number"
      :date="order.date"
      :paid="order.paid"
      :price="order.price"
      :object-names="order.objectNames"
      @click="handleShowOrder"
    />
    <slide-up-down v-model="showOrder" :duration="100">
      <div class="order-objects">
        <object-component
          v-for="(service, index) in order.services"
          :key="index"
          :index="index"
          :service="service"
          :digital-report-type="order.digitalReportType"
          :paper-report-type="order.paperReportType"
        />
      </div>
      <bottom-component/>
    </slide-up-down>
  </div>
</template>

<script>
import { ref, provide } from 'vue';
import TopComponent from "./top/TopComponent";
import ObjectComponent from "./object/ObjectComponent";
import BottomComponent from "./bottom/BottomComponent";
export default {
    name: "OrderComponent",
    components: {
        TopComponent,
        ObjectComponent,
        BottomComponent
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const showOrder = ref(false);

        const handleShowOrder = () => {
            showOrder.value = !showOrder.value;
        };

        provide('order', props.order);

        return {
            showOrder,
            handleShowOrder
        }
    }
}
</script>

<style scoped>

  .order {
    position: relative;
    margin-bottom: 10px;
    background-color: #f5f5f5;
  }

</style>
                                                                                                                                                                                
