<template>
  <div class="text">
    <b v-if="reportNumber">{{ reportNumber }}:</b> {{ text }}
  </div>
</template>

<script>
export default {
    name: "TextComponent",
    props: {
        text: {
            type: String,
            required: true
        },
        reportNumber: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

  .text {
    color: #14232E;
    display: block;
    text-align: left;
    width: auto;
    word-wrap: break-word;
  }

</style>
