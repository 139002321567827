<template>
  <div class="col-sm-8 service_descr">
    <div class="row">
      <div class="col-sm-4 detail_name">
        Наименование услуги:
      </div>
      <div class="col-sm-8 detail_value">
        {{ object.name_alt }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Результат оказания услуги:
      </div>
      <div class="col-sm-8 detail_value grayline">
        <strong>отчет об оценке рыночной стоимости</strong> в электронной форме
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Дополнительные опции:
      </div>
      <div class="col-sm-8 detail_value">
        отчет на бумажном носителе: +{{ pricePaperReport }} руб. к цене
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Срок выполнения:
      </div>
      <div class="col-sm-8 detail_value grayline">
        24 часа
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Осмотр/фото объекта:
      </div>
      <div class="col-sm-8 detail_value">
        <template v-if="!ride">
          не требуется
        </template>
        <template v-else>
          требуется
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Cтоимость услуги
      </div>
      <div class="col-sm-8 detail_value grayline">
        <template v-if="object.priceSale">
          <span class="old_price">{{ object.price }}</span>
        </template>
        <strong>
          <template v-if="object.priceSale">
            {{ object.priceSale }}
          </template>
          <template v-else>{{ object.price }}</template>
          руб.
        </strong>
        <template v-if="unitName">
          за {{ unitName }}
        </template>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Способ оплаты:
      </div>
      <div class="col-sm-8 detail_value">
        безопасная оплата по QR-коду или картой
      </div>
    </div>

    <div class="row">
      <div class="col-sm-4 detail_name">
        Предоплата:
      </div>
      <div class="col-sm-8 detail_value grayline">
        не требуется при сумме заказа до 2000 руб.
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Отправка нотариусу:
      </div>
      <div class="col-sm-8 detail_value">
        есть, в т.ч. с привязкой к наследственному делу
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Доставка:
      </div>
      <div class="col-sm-8 detail_value grayline">
        есть (почта России или CDEK)
      </div>
    </div>
    <div class="row">
      <div class="col-sm-4 detail_name">
        Регионы:
      </div>
      <div class="col-sm-8 detail_value">
        для любых регионов РФ
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: "PromoComponent",
    props: {
        object: {
            type: Object,
            required: true
        },
        unitName: {
            type: String
        },
        pricePaperReport: {
            type: Number,
            required: true
        },
        ride: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
