<template>
  <div class="actions">
    <span v-if="!existNewFile" class="btn btn-custom-gray send-mod-btn" title="Подгрузите документы по отмеченным объектам оценки" >Отправить на проверку</span>
    <a v-else class="btn btn-primary" @click="handleToModerate">Отправить на проверку</a>
  </div>
</template>

<script>
import { inject } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import { toModerateRequest } from "../../../../../../../api/preOrders";
export default {
    name: "ActionsComponent",
    props: {
        existNewFile: {
            type: Boolean,
            required: true
        }
    },
    setup() {

        const loader = inject('loader');
        const orderId = inject('orderId');
        const searchPreOrders = inject('searchPreOrders');
        const toaster = createToaster({ position: 'top' });

        const handleToModerate = (e) => {
            e.preventDefault();
            loader.value = true;
            toModerateRequest(orderId)
                .then(() => {
                    searchPreOrders().then(() => {
                        loader.value = false;
                        toaster.success('Заказ повторно направлен на проверку документов');
                    });
                }).catch(err => console.log(err));
        };

        return {
            handleToModerate
        }
    }
}
</script>

<style scoped>

</style>
