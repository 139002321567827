<template>
  <div class="status">
    <span v-if="archive">Отчет в архиве</span>
    <template v-else>
      <template v-if="isWithPrePay">
        <span v-if="paid<prePaySum && !isCompleteAllReportField">Ожидает предоплаты (50%)</span>
        <span v-else-if="checkReportStatus < 2">В работе</span>
        <span v-else-if="finalReportStatusValue === 1 || finalReportStatusValue === 5">Отчет готов, ожидает Вашего подтверждения</span>
        <span v-else-if="finalReportStatusValue === 2">Отчет на доработке или пояснении</span>
        <span v-else-if="!allReportConfirmed">Ожидает подтверждения других отчетов</span>
        <span v-else-if="paid<price">Ожидает оплаты</span>
        <span v-else-if="!isSigned">Отчет Вами подтвержден, ожидает подписания ЭЦП</span>
        <span v-else>Отчет готов</span>
      </template>
      <template v-else>
        <span v-if="checkReportStatus < 2">В работе</span>
        <span v-else-if="finalReportStatusValue === 1 || finalReportStatusValue === 5">Отчет готов, ожидает Вашего подтверждения</span>
        <span v-else-if="finalReportStatusValue === 2">Отчет на доработке или пояснении</span>
        <span v-else-if="!allReportConfirmed">Ожидает подтверждения других отчетов</span>
        <span v-else-if="paid<price">Ожидает оплаты</span>
        <span v-else-if="!isSigned">Отчет Вами подтвержден, ожидает подписания ЭЦП</span>
        <span v-else>Отчет готов</span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
    name: "StatusComponent",
    props: {
        price: {
            type: Number,
            required: true
        },
        paid: {
            type: Number,
            required: true
        },
        isWithPrePay: {
            type: Boolean,
            required: true
        },
        archive: {
            type: Number,
            required: true
        },
        checkReportStatus: {
            type: Number,
            required: true
        },
        finalReportStatusValue: {
            type: Number,
            required: true
        },
        prePaySum: {
            type: Number,
            required: true
        },
        delivery: {
            type: Object,
            required: true
        },
        isSigned: {
            type: Boolean,
            required: true
        },
        allReportConfirmed: {
            type: Boolean,
            required: true
        },
        isCompleteAllReportField: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

  .status {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
  }

  .status>span {
    font-family: 'Roboto';
    color: #fff;
    background: #aaa;
    padding: 3px 5px;
    border-radius: 5px;
    display: inline-block;
    font-size: 14px;
    line-height: 15px;
  }

</style>
