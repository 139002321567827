<template>
  <div class="order">
    <top-component
      :number="order.number"
      :messenger="order.messenger"
    />
    <body-component
      :services="order.services"
      :status="order.status"
      :price="order.price"
      :paid="order.paid"
      :discount="order.discount"
      :is-with-pre-pay="order.isWithPrePay"
      :pre-pay-sum="order.prePaySum"
      :delivery="order.delivery"
      :notary="order.notary"
      :all-report-signed="order.allReportSigned"
      :all-report-complete="order.allReportComplete"
      :all-report-confirmed="order.allReportConfirmed"
      :exist-report-on-print="order.existReportOnPrint"
      :is-all-report-printed="order.isAllReportPrinted"
      :is-all-report-sent-mail-russia="order.isAllReportSentMailRussia"
      :is-all-report-sent-delivery="order.isAllReportSentDelivery"
      :is-complete-all-report-field="order.isCompleteAllReportField"
    />
    <loader-component v-if="loader"/>
  </div>
</template>

<script>
import { provide, ref } from 'vue';
import LoaderComponent from "../../../../components/LoaderComponent";
import TopComponent from "./top/TopComponent";
import BodyComponent from "./body/BodyComponent";
export default {
    name: 'OrderComponent',
    components: {
        LoaderComponent,
        TopComponent,
        BodyComponent
    },
    props: {
        order: {
          type: Object,
          required: true
        }
    },
    setup(props) {

        const orderId = props.order.id;
        const loader = ref(false);

        provide('loader', loader);
        provide('orderId', orderId);
        provide('reportDigitalType', props.order.report_digital_type);
        provide('reportPaperType', props.order.report_paper_type);

        return {
            loader
        }
    }
}
</script>

<style scoped>

  .order {
    position: relative;
    margin-bottom: 10px;
  }

</style>
