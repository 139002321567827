<template>
  <div class="step-3">
    <div class="cart-form">
      <form @submit="handleSubmit">
        <h1>Данные заказчика оценки</h1>
        <div class="group-block">
          <span class="group-block-title">Паспортные данные</span>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>ФИО заказчика</label>
                <dadata-suggestions
                  v-model="fields.client_name.value"
                  type="NAME"
                  @change="formValidate()"
                  @focusout="formValidate()"
                  class="form-control"
                />
                <p class="error" v-if="fields.client_name.error">{{fields.client_name.errorText}}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label>Серия и номер паспорта</label>
                <input
                  type="text"
                  class="form-control"
                  v-mask="'#### ######'"
                  placeholder="#### ######"
                  v-model="fields.passport_series.value"
                  @change="formValidate()"
                  @focusout="formValidate()"
                />
                <p class="error" v-if="fields.passport_series.error">{{fields.passport_series.errorText}}</p>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label>Дата выдачи паспорта</label>
                <VueDatePicker
                  v-model="fields.passport_date.value"
                  model-type="timestamp"
                  locale="ru"
                  :enable-time-picker="false"
                  auto-apply
                  format="dd.MM.yyyy"
                  @update:model-value="formValidate()"
                  text-input
                  @focusout="formValidate()"
                  utc="preserve"
                  @keyup="handleFormatDate"
                />
                <p class="error" v-if="fields.passport_date.error">{{fields.passport_date.errorText}}</p>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group">
                <label>Кем выдан паспорт</label>
                <dadata-suggestions
                  v-model="fields.passport_from.value"
                  @change="formValidate()"
                  type="fms_unit"
                  class="form-control"
                  @focusout="formValidate()"
                />
                <p class="error" v-if="fields.passport_from.error">{{fields.passport_from.errorText}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <i class="warning-text"><b>Важно!</b> Паспортные данные вводите аккуратно и внимательно. В целях безопасности мы не храним эти данные на сайте, а однократно передаем оценщику для указания в отчете. Вы не сможете их потом увидеть и перепроверить в личном кабинете.</i>
          </div>
        </div>
        <div class="step-nav">
          <div class="row">
            <div class="col-sm-6">
              <a href="#" @click="prevStep">Вернуться назад</a>
            </div>
            <div class="col-sm-6">
              <button v-if="formValidate()" type="submit" class="btn-send-form">Отправить заказ на обработку</button>
              <button v-else type="button" disabled="disabled" class="disabled">Отправить заказ на обработку</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { parseDateFormat } from "../../../../../../helpers/helper";

export default {
    name: 'Step3Component',
    setup() {

        const prevStep = inject('prevStep');
        const fields = inject('fields');
        const handleSubmit = inject('handleSubmit');

        const handleFormatDate = (e) => {
            e.target.value = parseDateFormat(e.target.value);
        };

        const formValidate = () => {
            let error = false;
            const date = new Date().getTime();
            for (const key of Object.keys(fields.value)) {
                const field = fields.value[key];
                if (key !== 'date_eval' && key !== 'eval_name' && key !== 'notaryFound' && key !== 'notary' && key !== 'notaryNumber' && key !== 'notaryTestator') {
                  if(field.value==='' || field.value===null) {
                      field.error = true;
                      field.errorText = 'Поле обязательно к заполнению';
                      error = true;
                  } else {
                    if(key === 'passport_date') {
                      if(field.value >= date) {
                        field.error = true;
                        field.errorText = 'Дата не может быть больше текущей';
                        error = true;
                      } else {
                        field.error = false;
                        field.errorText = '';
                      }
                    } else {
                      field.error = false;
                      field.errorText = '';
                    }
                  }
                }
            }
            if(error) {
                return false;
            }
            return true;
        };

        return {
            fields,
            prevStep,
            formValidate,
            handleSubmit,
            handleFormatDate
        }
    }
}
</script>

<style scoped>

  .step-3 {
    max-width: 600px;
    margin: 0 auto;
  }

  .step-3 .cart-form .mx-datepicker {
    width: 100%;
  }

  .step-3 .cart-form label {
    display: block;
  }

  .step-3 .cart-form .warning-text {
    font-size: 12px;
    border-radius: 10px;
    display: block;
  }

  .step-3 .cart-form .form-group {
    margin-bottom: 0;
    position: relative;
  }

  .step-3 .cart-form .row {
    margin-bottom: 15px;
  }

  .step-3 .cart-form .row:last-child {
    margin-bottom: 0;
  }

  .step-3 .cart-form .group-block {
    border: 1px solid #c7c7c7;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
  }

  .step-3 .cart-form .group-block-title {
    position: absolute;
    top: -8px;
    left: 10px;
    background-color: #efefef;
    font-size: 10px;
    text-transform: uppercase;
    display: inline-block;
    padding: 2px;
    color: #000000;
  }

  .step-3 label {
    position: relative;
  }

  .step-3 .label-prompt {
    position: relative;
  }

  .step-3 .label-prompt>span {
    position: absolute;
    top: 0;
    left: 15px;
    border: 1px solid gray;
    padding: 5px;
    border-radius: 5px;
    background-color: #FFFFFF;
    font-weight: normal;
    font-size: 11px;
    max-width: 300px;
    width: max-content;
    width: -moz-max-content;
    z-index: 2;
    display: none;
  }

  .step-3 .label-prompt:hover>span {
    display: block;
  }

  .step-3 label .prompt-text {
    font-size: 12px;
    font-weight: normal;
    color: #606060;
  }

  .suggestions-wrapper {
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  /*step-nav*/

  .step-nav {
    margin-top: 20px;
  }

  .step-nav .row>.col-sm-6:first-child {
    text-align: left;
  }

  .step-nav .row>.col-sm-6:last-child {
    text-align: right;
  }

  .step-nav a,
  .step-nav button {
    background-color: #ffffff;
    color: #1b1e21;
    border-radius: 5px;
    padding: 7px;
    border: 1px solid #c7c7c7;
    display: inline-block;
    text-decoration: none;
  }

  .step-nav .btn-send-form {
    background-color: #3194ca;
    color: #ffffff;
    border: none;
  }

  .step-nav .disabled {
    background-color: #d3d3d3;
  }

  @media (max-width: 768px) {
    .step-nav .col-sm-6 {
      width: 50%;
    }
  }

</style>
