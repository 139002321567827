<template>
  <section class="info-column">
    <div class="row">
      <div class="col-sm-6">
        <router-link to="/information/step-by-step">
          <div class="item">
            <h2>Как вступить в наследство: пошаговая инструкция. Как вступить в наследство: пошаговая инструкция.</h2>
            <p>Простой и понятный порядок действий при оформлении наследства.</p>
          </div>
        </router-link>
      </div>
      <div class="col-sm-6">
        <a href="https://notariat.ru/probate/" target="_blank">
          <div class="item">
            <h2>Официальный реестр наследственных дел.</h2>
            <p>Сервис для проверки и поиска открытых наследственных дел.</p>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: "infoColumnComponent"
}
</script>

<style scoped>

  .info-column {
    margin: 20px 0;
  }

  .col-sm-6>a {
    text-decoration: none;
    color: #212529;
  }

  .col-sm-6 .item {
    background-color: #dddddd;
    margin-bottom: 20px;
    min-height: 113px;
    padding: 20px;
  }

  .col-sm-6:last-child .item {
    margin-bottom: 0;
  }

</style>
