<template>
  <layout-legacy>
    <breadcrumb-component/>
    <div class="page-settings">
      <div class="row">
        <div class="col-sm-4">
          <section class="content">
            <side-bar-component/>
          </section>
        </div>
        <div class="col-sm-8">
          <section class="content">
            <router-view/>
          </section>
        </div>
      </div>
    </div>
  </layout-legacy>
</template>

<script>
import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { BREADCRUMBS_SET_ITEM } from "../../store/constants";
import LayoutLegacy from "../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";
import SideBarComponent from "./sideBar/SideBarComponent";
export default {
    name: "SettingsView",
    components: {
        LayoutLegacy,
        BreadcrumbComponent,
        SideBarComponent
    },
    setup() {

        const store = useStore();

        onBeforeMount(() => {
            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Настройки', path: '/settings' }
            });
        });

    }
}
</script>

<style scoped>

</style>
