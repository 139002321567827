<template>
  <services-component :services="services" :report="report"/>
  <status-component :status="status" :exist-new-file="existNewFile"/>
  <payment-component :status="status" :price="price"/>
</template>

<script>
import ServicesComponent from "./services/ServicesComponent";
import StatusComponent from "./status/StatusComponent";
import PaymentComponent from "./payment/PaymentComponent";
export default {
    name: "BodyComponent",
    components: {
        ServicesComponent,
        StatusComponent,
        PaymentComponent
    },
    props: {
        services: {
            type: Array,
            required: true
        },
        report: {
            type: Object,
            required: true
        },
        status: {
            type: Object,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        existNewFile: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
