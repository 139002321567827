<template>
  <div class="actions">
    <button v-if="!notary.lastSent" type="button" @click="handleSendEmailNotary">
      Отправить нотариусу
    </button>
    <template v-else>
      <button v-if="notary.lastSent.isTimeExpired" type="button" @click="handleSendEmailNotary">
        Повторно отправить
      </button>
      <button v-else class="disabled" @click="handleSendEmailNotary">
        Повторно отправить через {{ notary.lastSent.lastMinutes }} мин..
      </button>
    </template>
    <button
      v-if="notary.options && notary.options.shareFiles.status"
      type="button"
      class="share-files-link"
      @click="handleCopyShareFilesLink"
    >
      <span class="desktop">Ссылка "Поделиться файлами" (активна еще {{notary.options.shareFiles.leftDay}} дней)</span>
      <span class="mobile">Ссылка "Поделиться"</span>
    </button>
    <button v-else type="button" class="share-files" @click="handleShareFiles">
      Поделиться файлами заказа
    </button>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import { createToaster } from '@meforma/vue-toaster';
import { shareFilesRequest, sendMailNotary } from "../../../../../../../api/orders";
import { copy } from "../../../../../../../helpers/helper";
export default {
    name: "ActionsComponent",
    props: {
        notary: {
            type: Object
        }
    },
    setup(props) {

        const toaster = createToaster({ position: 'top' });
        const orderId = inject('orderId');
        const searchOrders = inject('searchOrders');
        const loader = inject('loader');

        const copyLink = (token) => {
            let link = window.location.origin;
            link += '/orders/order/share-files?token=' + token;
            copy(link);
        };

        const handleCopyShareFilesLink = () => {
            copyLink(props.notary.options.shareFiles.token);
            toaster.success('Ссылка успешно скопирована');
        };

        const handleShareFiles = () => {
            loader.value = true;
            shareFilesRequest(orderId)
                .then(response => {
                    if(response.data.token) {
                        copyLink(response.data.token);
                        toaster.success('Создана и скопирована в буфер обмена ссылка на файлы заказа');
                        searchOrders().then(() => {
                            loader.value = false;
                        });
                    }
                })
                .catch(err => console.log(err));
        };

        const handleSendEmailNotary = () => {
            loader.value = true;
            sendMailNotary(orderId)
                .then(response => {
                    const data = response.data;
                    if(data) {
                        toaster.success('Письмо было успешно отправлено нотуриусу');
                        searchOrders().then(() => {
                            loader.value = false;
                        });
                    }
                })
                .catch(err => console.log(err));
        };

        return {
            handleSendEmailNotary,
            handleCopyShareFilesLink,
            handleShareFiles
        }
    }
}
</script>

<style scoped>

  .actions {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }

  .actions button {
    display: inline-block;
    border: 1px solid #3294ca;
    font-size: 19px;
    color: #3294ca;
    padding: 5px 8px;
    border-radius: 5px;
    margin-right: 5px;
  }

  .actions button:active {
    background-color: #60a0c3;
  }

  .actions .disabled {
    border: 1px solid #b1b1b1;
    color: #b1b1b1;
  }

  .mobile {
    display: none;
  }

  @media (max-width: 992px) {

    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

  }

</style>
