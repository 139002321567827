<template>
  <service-component
    v-for="(service,index) in services"
    :key="service.id"
    :index="index"
    :service="service"
    :report="report"
  />
</template>

<script>
import ServiceComponent from "./service/ServiceComponent";
export default {
    name: "ServicesComponent",
    components: {
        ServiceComponent
    },
    props: {
        services: {
            type: Array,
            required: true
        },
        report: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
