<template>
  <div class="order-top">
    <div class="row">
      <div class="col-sm-8 left-col">
        <span class="number">Заказ №{{number}} от {{date}}</span>
        <span class="objectNames">
            <template v-if="objectNames.length>1">
              ({{objectNames[0] + ' и еще ' + (objectNames.length-1) + ' объект(а,ов)'}})
            </template>
            <template v-else>
              ({{objectNames[0]}})
            </template>
          </span>
      </div>
      <div class="col-sm-4 right-col">
        <div class="price-paid">
          Оплачено <b>{{paid}}</b> из <b>{{price}}</b> руб.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      required: true
    },
    date: {
      type: String,
      required: true
    },
    objectNames: {
        type: Array,
        required: true
    },
    paid: {
      type: Number,
      required: true
    },
    price: {
      type: Number,
      required: true
    }
  }
}
</script>

<style scoped>

.order-top {
  background-color: #f5f5f5;
  margin: 0;
  padding: 5px 0;
  position: relative;
  cursor: pointer;
}

.order-top:first-child {
  background-color: #efefef;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.order-top .price-paid {
  font-size: 17px;
  color: #3294ca;
  margin-right: 15px;
}

.order-top .right-col {
  text-align: right;
}

.order-top .number {
  margin-right: 10px;
  font-size: 17px;
  font-weight: bold;
  color: #3294ca;
  margin-left: 15px;
}

.order-top .objectNames {
  font-size: 14px;
  font-weight: bold;
  color: #3294ca;
  display: inline-block;
  line-height: 14px;
  vertical-align: middle;
}

</style>
