<template>
    <div class="item" :title="item.name">
      <template v-if="extension === 'pdf'">
        <font-awesome-icon icon="file-pdf" />
      </template>
      <template v-else-if="extension === 'zip'">
        <font-awesome-icon icon="file-archive" />
      </template>
      <template v-else-if="extension === 'png' || extension === 'jpg' || extension === 'jpeg' || extension === 'heic'">
        <img :src="src"/>
      </template>
      <template v-else>
        <font-awesome-icon icon="file" />
      </template>
      <a href="#" @click="(e) => deleteEvent(e, item.id)">x</a>
    </div>
</template>

<script>
import { getExtensionByName, getSrc } from "../../../../helpers/file";

export default {
    name: "FileItemComponent",
    props: {
        item: {
            type: File,
            required: true
        },
        deleteEvent: {
            type: Function,
            required: true
        }
    },
    setup(props) {

        const extension = getExtensionByName(props.item.name);
        const src = getSrc(props.item);

        return {
            src,
            extension
        }
    },
}
</script>

<style scoped>

  .item {
    width: 50px;
    height: 50px;
    display: inline-flex;
    border-radius: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #ebebeb;
    position: relative;
    align-items: center;
    vertical-align: top;
  }

  .item>img {
    border-radius: 7px;
    display: inline-block;
    margin: 0 auto;
    max-height: 100%;
    max-width: 100%;
  }

  .item>a {
    position: absolute;
    top: -9px;
    right: -8px;
    background-color: #8d8d8d;
    color: #ffffff !important;
    font-size: 14px;
    line-height: 14px;
    display: block;
    padding: 1px 5px 3px 5px;
    border-radius: 20px;
    border: 1px solid #ffffff;
    cursor: pointer;
    text-decoration: none;
  }

  .item>svg {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 40px;
    color: #cfcfcf;
  }

</style>
