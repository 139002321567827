<template>
  <div v-if="formFields.files.value.length" class="file-items">
    <file-item-component
      v-for="item in formFields.files.value"
      :item="item"
      :key="item.id"
      :delete-event="handleDelete"
    />
  </div>
</template>

<script>
import { inject } from 'vue';
import FileItemComponent from "./FileItemComponent";
export default {
    name: 'FileItemsComponent',
    components: {
        FileItemComponent
    },
    setup() {

        const formFields = inject('formFields');

        const handleDelete = (e, id) => {
            e.preventDefault();
            formFields.value.files.value = formFields.value.files.value.filter(item => item.id !== id);
        };

        return {
            formFields,
            handleDelete
        }
    }
}
</script>

<style scoped>

  .file-items {
    display: inline-block;
    margin: 10px 10px 0 10px;
  }

</style>
