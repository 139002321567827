<template>
  <div class="data">
    <h2>ФИО пльзователя</h2>
    <form @submit="handleSubmit">
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Фамилия" v-model="form.firstName" />
      </div>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Имя" v-model="form.secondName" />
      </div>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Отчество" v-model="form.lastName" />
      </div>
      <div class="form-group">
        <button class="btn btn-custom" type="submit">Сохранить</button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from 'vue';
import { updateNameRequest } from "../../../../api/user";
import { createToaster } from '@meforma/vue-toaster';
import { AUTH_SET_USER } from "../../../../store/constants";
import { useStore } from 'vuex';

export default {
    name: 'DataFormComponent',
    props: {
        firstName: {
            type: String,
            required: true
        },
        secondName: {
            type: String,
            required: true
        },
        lastName: {
            type: String,
            required: true
        }
    },
    setup({ firstName, secondName, lastName }) {

        const store = useStore();
        const user = store.state.auth.user;
        const toaster = createToaster({position: 'top'});
        const form = ref({
            firstName: firstName,
            secondName: secondName,
            lastName: lastName
        });

        const handleSubmit = (e) => {
            e.preventDefault();
            updateNameRequest({
                firstName: form.value.firstName,
                secondName: form.value.secondName,
                lastName: form.value.lastName
            }).then(response => {
                store.commit(AUTH_SET_USER, {
                    ...user,
                    firstName: form.value.firstName,
                    secondName: form.value.secondName,
                    lastName: form.value.lastName
                });
                toaster.success('Успешно сохранено');
            }).catch(err => console.log(err));
        };

        return {
            form,
            handleSubmit
        }
    }
}
</script>

<style scoped>

</style>
