<template>
  <author-component :name="message.author.name"/>
  <div class="file-item">
    <div v-if="isImage()" @click="getFile" class="file-item-image">
      <img v-if="srcImage" :src="srcImage"/>
      <div v-else style="padding: 15px 0;"></div>
    </div>
    <div v-else class="file-item-file" @click="getFile">
      <div class="table">
        <div class="tr">
          <div class="td icon">
            <template v-if="message.file.extension === 'pdf'">
              <font-awesome-icon icon="file-pdf" />
            </template>
            <template v-else-if="message.file.extension === 'zip'">
              <font-awesome-icon icon="file-archive" />
            </template>
            <template v-else>
              <font-awesome-icon icon="file" />
            </template>
          </div>
          <div class="td">
            <div class="name">{{message.file.name}}</div>
            <div class="size">{{itemSize}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loader" class="loader">
      <font-awesome-icon :icon="['fas','spinner']" />
    </div>
  </div>
  <date-component :datetime="message.datetime"/>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { loadFileRequest } from "../../../../../api/messenger";
import { createBlobImageUrl, sizeFormatBytes } from "../../../../../services/fileHelper";
import AuthorComponent from "../author/AuthorComponent";
import DateComponent from "../date/DateComponent";

export default {
    name: 'FileContentComponent',
    components: {
        AuthorComponent,
        DateComponent
    },
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const loader = ref(false);
        const srcImage = ref(null);
        const itemSize = ref(null);
        const store = useStore();
        const message = computed(() => props.message);

        const isImage = () => {
            const extensions = ['jpg','png','jpeg'];
            if(extensions.indexOf(message.value.file.extension) > -1) {
                return true;
            }
            return false;
        };

        const loadFile = () => {
            return loadFileRequest(store.state.messenger.id, { id: message.value.id })
                .then(response => response.data)
                .catch(err => console.log(err));
        };

        const getFile = async () => {
            if(isImage()) {
                window.open(srcImage.value, '_blank');
            } else {
                loader.value = true;
                loadFileRequest(
                    store.state.messenger.id,
                    { id: message.value.id }
                ).then(response => {
                    const url = window.URL.createObjectURL(response.data),
                        link = window.document.createElement("a");
                    link.href = url;
                    link.download = message.value.file.name;
                    link.target = '_blank';
                    link.click();
                    window.URL.revokeObjectURL(url);
                    loader.value = false;
                }).catch(err => console.log(err));
            }
        };

        onMounted(() => {
            if(isImage()) {
                loader.value = true;
                if(message.value.file.id) {
                    const getImage = async () => {
                        const file = await loadFile();
                        srcImage.value = createBlobImageUrl(file);
                        loader.value = false;
                    };
                    getImage();
                }
            }
            if(message.value.file.id) {
                itemSize.value = sizeFormatBytes(message.value.file.size);
            }
        });

        return {
            loader,
            isImage,
            srcImage,
            itemSize,
            getFile
        }
    }
}
</script>

<style scoped>

  .file-item {
    cursor: pointer;
    max-width: 300px;
  }

  .loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(243,243,243,.7);
  }

  .loader>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 17px;
    margin-top: -11px;
    margin-left: -11px;
    color: black;
  }

  .file-item-file .table .td {
    vertical-align: top;
  }

  .file-item-file .icon {
    padding-right: 10px;
  }

  .file-item-file .icon svg {
    font-size: 27px;
    color: #a0b3bf;
  }

  .file-item-file .name {
    font-size: 14px;
    color: #646464;
    margin-bottom: 5px;
  }

  .file-item-file .size {
    font-size: 14px;
    color: #a0b3bf;
  }

</style>
