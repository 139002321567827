import { postRequest, getRequest } from "./axios";
import {
  MESSENGER_GET_DATA_URL,
  MESSENGER_ADD_MESSAGE_URL,
  MESSENGER_ADD_FILE_URL,
  MESSENGER_EDIT_MESSAGE_URL,
  MESSENGER_DELETE_MESSAGE_URL,
  MESSENGER_LOAD_FILE_URL
} from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies }  = useCookies();

export function getDataRequest(id) {
  return getRequest(MESSENGER_GET_DATA_URL + '?id=' + id, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function addMessageRequest(id, formData) {
  return postRequest(MESSENGER_ADD_MESSAGE_URL + '?id=' + id, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function addFileRequest(id, formData) {
  return postRequest(MESSENGER_ADD_FILE_URL + '?id=' + id, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function editMessageRequest(id, formData) {
  return postRequest(MESSENGER_EDIT_MESSAGE_URL + '?id=' + id, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function deleteMessageRequest(id, formData) {
  return postRequest(MESSENGER_DELETE_MESSAGE_URL + '?id=' + id, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function loadFileRequest(id, formData) {
  return postRequest(MESSENGER_LOAD_FILE_URL + '?id=' + id, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    },
    responseType: 'blob'
  });
}
