import {
  ORDERS_ARCHIVE_GET_ZIP_URL,
  ORDERS_ARCHIVE_RECOVERY_URL,
  ORDERS_ARCHIVE_SEARCH_URL,
  ORDERS_URL
} from "./constants";
import { getRequest, postRequest, putRequest } from "./axios";
import { useCookies } from "vue3-cookies";

const { cookies }  = useCookies();

export function getObjectsRequest() {
  return getRequest(`${ORDERS_URL}/objects`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function getParamsRequest() {
  return getRequest(`${ORDERS_URL}/params`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}

export function searchRequest(params = {}) {
  const getParams = new URLSearchParams(params).toString();
  return getRequest(`${ORDERS_URL}/search?${getParams}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function getFileRequest(orderId, reportId, fileId) {
  return getRequest(`${ORDERS_URL}/${orderId}/reports/${reportId}/files/${fileId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function reportModifyRequest(orderId, reportId, formData) {
  return putRequest(`${ORDERS_URL}/${orderId}/reports/${reportId}/modify`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function reportConfirmRequest(orderId, reportId) {
  return putRequest(`${ORDERS_URL}/${orderId}/reports/${reportId}/confirm`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function getPaymentLinkRequest(orderId) {
  return getRequest(`${ORDERS_URL}/${orderId}/get-payment-link`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function getZipRequest(orderId, reportId) {
  return getRequest(`${ORDERS_URL}/${orderId}/reports/${reportId}/zip`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function shareFilesRequest(orderId) {
  return putRequest(`${ORDERS_URL}/${orderId}/share-files`, {},{
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function shareFilesGetOrderRequest(token) {
  return getRequest(`${ORDERS_URL}/share-files?token=${token}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function shareFilesGetZipRequest(token) {
  return getRequest(`${ORDERS_URL}/share-files/get-zip?token=${token}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function shareFilesGetFileRequest(token, fileId) {
  return getRequest(`${ORDERS_URL}/share-files/get-file?token=${token}&fileId=${fileId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function sendMailNotary(orderId) {
  return putRequest(`${ORDERS_URL}/${orderId}/send-notary`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function setDeliveryTypeRequest(orderId) {
  return putRequest(`${ORDERS_URL}/${orderId}/select-delivery-office`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function setDeliveryAddressRequest(orderId, formData) {
  return putRequest(`${ORDERS_URL}/${orderId}/select-delivery-russian-mail`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function setDeliveryPointRequest(orderId, formData) {
  return putRequest(`${ORDERS_URL}/${orderId}/select-delivery-point`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function reArchiveRequest(orderId, reportId) {
  return putRequest(`${ORDERS_URL}/${orderId}/reports/${reportId}/recovery`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function searchArchiveRequest(params = {}) {
  const getParams = new URLSearchParams(params).toString();
  return getRequest(`${ORDERS_URL}/search-archive?${getParams}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}
