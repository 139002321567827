<template>
  <div :class="getClass()">
    <div class="author">{{message.author.name}}</div>
    <div class="text">
      Сообщение удалено
    </div>
    <div class="date">{{message.datetime}}</div>
  </div>
</template>

<script>
export default {
    name: "MessageDeletedComponent",
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    setup(props) {

        const message = props.message;

        const getClass = () => {
            let className = ['message','deleted'];
            if(message.isAuthor) {
                className.push('from');
            } else {
                className.push('to');
            }
            return className;
        };

        return {
            getClass
        }
    }
}
</script>

<style scoped>

  .message {
    color: #FFFFFF;
    font-size: 14px;
    width: max-content;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    max-width: 100%;
    position: relative;
    padding: 8px;
    margin-bottom: 5px;
  }

  .message.from {
    background-color: #cbecff;
  }

  .message.to {
    background-color: #ecf0f3;
  }

  .text {
    color: #999999;
    display: block;
    text-align: left;
    width: auto;
    word-wrap: break-word;
  }

  .date {
    display: block;
    text-align: right;
    font-size: 10px;
    color: #ababab;
    margin-top: 4px;
  }

  .author {
    color: #ababab;
    font-size: 10px;
    margin-bottom: 5px;
  }

</style>
