<template>
  <div class="user-menu">
    <ul>
      <li>
        <font-awesome-icon icon="cog" />
        <router-link to="/settings">Профиль</router-link>
      </li>
      <li>
        <font-awesome-icon icon="cart-arrow-down" />
        <router-link to="/orders"><b>Заказы</b></router-link>
      </li>
      <li>
        <font-awesome-icon icon="sign-out-alt" />
        <a href="#" @click="handleLogout">Выход</a>
      </li>
    </ul>
    <div @click="handleHideMenu" class="user-menu-overlay"></div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { logoutRequest } from "../../api/auth";
import { useCookies } from 'vue3-cookies';
import { useStore } from 'vuex';
import { AUTH_CLEAR_USER, DEFAULT_SHOW_LOADER, DEFAULT_HIDE_LOADER } from "../../store/constants";
import { useRouter } from 'vue-router';

export default {
    setup() {

        const showUserMenu = inject('showUserMenu');
        const { cookies } = useCookies();
        const store = useStore();
        const router = useRouter();

        const handleHideMenu = () => {
            showUserMenu.value = false;
        };

        const handleLogout = (e) => {
            e.preventDefault();
            store.commit(DEFAULT_SHOW_LOADER);
            logoutRequest().then(() => {
                store.commit(DEFAULT_HIDE_LOADER);
                cookies.remove('token');
                store.commit(AUTH_CLEAR_USER);
                router.push('/');
            });
        };

        return {
            handleHideMenu,
            handleLogout
        }
    }
}
</script>

<style scoped>

  .user-menu>ul {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #ffffff;
    padding: 10px 15px;
    border-radius: 10px;
    border: 1px solid #dfdfdf;
    box-shadow: 0 0 5px rgba(0,0,0,0.1);
    text-align: left;
    z-index: 2;
    width: 200px;
  }

  .user-menu-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  ul li {
    padding: 10px 15px;
    border-bottom: 1px solid #ccc;
  }

  ul li:last-child {
    border-bottom: none;
  }

  ul li a {
    margin-left: 10px;
    color: #3294ca;
  }

  ul li svg {
    color: #3294ca;
  }

</style>
