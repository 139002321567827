<template>
  <form @submit="handleSendSubmit">
    <div class="left">
      <a href="#" @click="handleShowFilePanel">
        <font-awesome-icon icon="paperclip" />
      </a>
    </div>
    <div class="center">
      <input type="text" placeholder="Введите сообщение" v-model="fields.text" />
    </div>
    <div class="right">
      <button type="submit" title="Отправить сообщение">
        <font-awesome-icon :icon="['fab', 'telegram']" />
      </button>
    </div>
  </form>
</template>

<script>
import { inject } from 'vue';
export default {
    name: "FormComponent",
    setup() {

        const fields = inject('formFields');
        const handleSendSubmit = inject('handleSendSubmit');
        const handleShowFilePanel = inject('handleShowFilePanel');

        return {
            fields,
            handleSendSubmit,
            handleShowFilePanel
        }
    }
}
</script>

<style scoped>

  form {
    display: flex;
    align-items: center;
  }

  form .left {
    width: 27px;
    text-align: left;
  }

  form .right {
    width: 40px;
    text-align: right;
  }

  form .center {
    width: 100%;
  }

  form .center input {
    background-color: #eff4f0;
    border: none;
    border-radius: 15px;
    padding: 5px 10px;
    font-size: 14px;
    color: #5e5e5e;
    line-height: 14px;
    width: 100%;
    margin-right: 10px;
  }

  form .center input::placeholder {
    color: #ced0cf;
  }

  form .left a {
    font-size: 20px;
    color: #d7dcd8;
  }

  form .right button {
    font-size: 29px;
    color: #d7dcd8;
    border: none;
    background-color: transparent;
  }

  form .right button:hover {
    color: #a7ced5;
  }

</style>
