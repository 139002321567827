<template>
  <div class="instruction">
    <p><b>Добавьте в корзину наследуемое имущество для оценки. Перейдите в корзину и далее следуйте подсказкам.</b></p>
  </div>
</template>

<script>
export default {
    name: "InstructionComponent"
}
</script>

<style scoped>

  .instruction {
    margin-bottom: 20px;
  }

  .instruction p {
    color: #333;
    text-indent: 1.3em;
    text-align: justify;
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 0.5em;
    font-family: 'Roboto', Tahoma;
  }

</style>
