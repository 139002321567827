import { PAGES_URL } from "./constants";
import { getRequest } from "./axios";

export function getPagesRequest() {
  return getRequest(PAGES_URL, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  });
}
