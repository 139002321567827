<template>
  <section class="bottom">
    <current-message-component/>
    <file-panel-component/>
    <file-items-component/>
    <form-component/>
  </section>
</template>

<script>
import FormComponent from "./form/FormComponent";
import FilePanelComponent from "./filePanel/FilePanelComponent";
import FileItemsComponent from "./fileItems/FileItemsComponent";
import CurrentMessageComponent from "./currentMessage/CurrentMessageComponent";
export default {
    name: "BottomComponent",
    components: {
        FormComponent,
        FilePanelComponent,
        FileItemsComponent,
        CurrentMessageComponent
    }
}
</script>

<style scoped>

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    border-top: 1px solid #f1f1f1;
    background-color: #f7fcf8;
  }

</style>
