<template>
    <footer>
      <section class="top">
        <div class="container">
          <div class="row">
            <div class="col-sm-4 left">
              <h4>Наследство<span>.Онлайн</span></h4>
              <p>Оценка любых видов имущества для целей нотариального оформления на всей территории РФ</p>
              <router-link class="policy-link" to="/policy">Политика обработки персональных данных</router-link>
            </div>
            <div class="col-sm-4 center">
              <h5>Информация</h5>
              <p>
                ООО "ФИНЭК-КОНСАЛТ-АУДИТ",<br/>ОГРН 1027809191409, ИНН 7825050767, Юр.адрес: 191023, г Санкт-Петербург, ул Караванная, д 1, лит А, пом 61-Н, комн №39
              </p>
              <div class="footer-contacts">
                <p><span>Тел:</span> +7 (812) 425-30-30 (9:30-21:00 МСК)</p>
              </div>
            </div>
            <div class="col-sm-4 right">
              <h5><router-link to="/how-to-order">Процедура заказа и оплаты</router-link></h5>
              <img src="@/assets/card-logo.png"/>
            </div>
          </div>
        </div>
      </section>
      <section class="bottom">
        <div class="copyright">
          <p>© <a href="https://finec-audit.ru" target="_blank">ООО "Финэк-Консалт-Аудит"</a>, {{ year }}</p>
        </div>
      </section>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    setup() {
        const year = (new Date()).getFullYear();
        return {
            year
        }
    }
}
</script>

<style scoped>

  footer {
    position: relative;
    z-index: 2;
  }

  footer .top {
    background-color: #e9e9e9;
    padding-top: 25px;
    margin-top: -225.8px;
  }

  footer .top h4 {
    font-size: 21px;
    margin-bottom: 20px;
  }

  footer .top h4>span {
    color: #49aec5;
  }

  footer .top p {
    font-size: 14px;
    color: #787878;
    line-height: 18px;
    margin-bottom: 15px;
  }

  footer .top h5 {
    color: #787878;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }

  footer .top .footer-contacts p>span {
    color: #49aec5;
    font-weight: bold;
  }

  footer .top .right h5 a {
    color: #49aec5;
  }

  .policy-link {
    color: #49aec5;
    font-size: 14px;
  }

  footer .bottom .copyright {
    text-align: center;
    font-weight: 700;
    padding: 15px 0;
    background-color: #e3e3e3;
    font-size: 13px;
    line-height: 14px;
  }

  footer .bottom .copyright a {
    color: #49aec5;
  }

</style>
