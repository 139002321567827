<template>
  <div class="files">
    <div
      v-for="(file, fileIndex) in files"
      :key="fileIndex"
      class="file-item"
      :title="file.name"
    >
      <a href="#" onclick="return false;">
        {{ fileIndex+1 }}. {{ getShortName(file.name) }}
      </a>
      <span class="delete" @click="deleteEvent(objectIndex, fileIndex)">x</span>
    </div>
  </div>
</template>

<script>
import { isImage, getShortName } from "../../../../../../../helpers/file";

export default {
    name: "MobileFilesComponent",
    props: {
        files: {
            type: Array,
            required: true
        },
        objectIndex: {
            type: Number,
            required: true
        },
        deleteEvent: {
            type: Function,
            required: true
        }
    },
    setup() {
        return {
            isImage,
            getShortName
        }
    }
}
</script>

<style scoped>

  .files {
    margin-bottom: 10px;
  }

  .files .file-item {
    position: relative;
    display: block;
    margin-bottom: 3px;
  }

  .files .file-item>a {
    color: #777;
    font-size: 15px;
    line-height: 1.5em;
  }

  .files .file-item>.delete {
    cursor: pointer;
    color: #3294ca;
    font-size: 19px;
    margin-left: 5px;
    border: 1px solid #3294ca;
    line-height: 14px;
    display: inline-block;
    padding: 0 5px 5px 5px;
  }

  .files .file-item>.delete {
    cursor: pointer;
    color: #3294ca;
    font-size: 19px;
    margin-left: 5px;
    border: 1px solid #3294ca;
    line-height: 14px;
    display: inline-block;
    padding: 0 5px 5px 5px;
  }

  @media (max-width: 768px) {

    .files {
      margin-bottom: 10px;
    }

    .files .file-item>a {
      color: #3294ca;
    }

  }

</style>
