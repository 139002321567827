import { getRequest } from './axios';
import { NOTARIES_URL } from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function findRequest(params = {}) {
  const getParams = new URLSearchParams(params).toString();
  return getRequest(`${NOTARIES_URL}/find?${getParams}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    timeout: 60000
  });
}

export function findByNameRequest(params = {}) {
  const getParams = new URLSearchParams(params).toString();
  return getRequest(`${NOTARIES_URL}/find-by-name?${getParams}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}
