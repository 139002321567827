<template>
  <layout-legacy>
    <breadcrumb-component/>
    <h1>Заказы</h1>
    <section class="content">
      <section v-if="preOrders.items.length || orders.items.length || archive.items.length">
        <pre-orders-component v-if="preOrders.items.length"/>
        <orders-component v-if="orders.items.length"/>
        <archive-component v-if="archive.items.length"/>
      </section>
      <div v-else>
        <p>Заказы пока отсутствуют</p>
      </div>
    </section>
  </layout-legacy>
</template>

<script>
import { ref, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useSeoMeta } from '@unhead/vue';
import { getAllRequest as searchPreOrdersRequest } from "../../api/preOrders";
import { searchRequest as searchOrdersRequest, searchArchiveRequest } from "../../api/orders";
import { BREADCRUMBS_SET_ITEM } from "../../store/constants";
import LayoutLegacy from "../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../components/BreadcrumbComponent";
import PreOrdersComponent from "./preOrders/PreOrders";
import OrdersComponent from "./orders/Orders";
import ArchiveComponent from "./archive/Archive";

export default {
    name: 'OrdersView',
    components: {
        LayoutLegacy,
        BreadcrumbComponent,
        PreOrdersComponent,
        OrdersComponent,
        ArchiveComponent
    },
    setup() {

        const store = useStore();
        const legalId = store.state.legalId;
        const preOrders = ref({ items: [], pages: [] });
        const orders = ref({items: [], pages: []});
        const ordersLoader = ref(false);
        const archive = ref({items: [], pages: []});
        const archiveLoader = ref(false);

        const searchPreOrders = (page = 1) => {
            return searchPreOrdersRequest({
                legalId: legalId,
                page: page
            })
                .then(response => {
                    const data = response.data;
                    if(data) {
                        preOrders.value.items = data.items;
                        preOrders.value.pages = data.pages;
                    }
                })
                .catch(err => console.log(err));
        };

        const searchOrders = (page = 1) => {
            ordersLoader.value = true;
            return searchOrdersRequest({
                legalId: legalId,
                page: page
            })
                .then(response => {
                    ordersLoader.value = false;
                    const data = response.data;
                    if(data) {
                        orders.value.items = data.items;
                        orders.value.pages = data.pages;
                    }
                })
                .catch(err => console.log(err));
        };

        const searchArchive = (page = 1) => {
            archiveLoader.value = true;
            return searchArchiveRequest({
                legalId: legalId,
                page: page
            })
                .then(response => {
                    archiveLoader.value = false;
                    const data = response.data;
                    if(data) {
                        archive.value.items = data.items;
                        archive.value.pages = data.pages;
                    }
                })
                .catch(err => console.log(err));
        };

        onMounted(() => {

            useSeoMeta({
                title: 'Заказы'
            });

            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Заказы', path: '/orders' }
            });

            searchPreOrders();
            searchOrders();
            searchArchive();

        });

        provide('preOrders', preOrders);
        provide('orders', orders);
        provide('archive', archive);
        provide('searchPreOrders', searchPreOrders);
        provide('searchOrders', searchOrders);
        provide('searchArchive', searchArchive);
        provide('ordersLoader', ordersLoader);
        provide('archiveLoader', archiveLoader);

        return {
            preOrders,
            orders,
            archive
        }
    }
}
</script>

<style scoped>

  .beta-text {
    font-size: 14px;
    color: red;
    border: 1px solid black;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 20px
  }

</style>
