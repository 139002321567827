<template>
  <div class="file">
    <a href="#" :title="file.title" @click="(e) => handleGetFile(e, file)">
      <font-awesome-icon v-if="file.type === 'pdf'" icon="file-pdf" />
      <font-awesome-icon v-if="file.type === 'jpg' || file.type === 'png' || file.type === 'jpeg'" icon="file-image" />
      {{ file.name }}.{{ file.type }}
      <loader-component v-if="loader"/>
    </a>
    <template v-if="!isLast">;</template>
  </div>
</template>

<script>
import { ref, inject } from 'vue';
import { getFileRequest } from "../../../../../../../../../api/preOrders";
import { openFile } from "../../../../../../../../../helpers/file";
import LoaderComponent from "../../../../../../../../../components/LoaderComponent";

export default {
    name: "FileComponent",
    components: {
        LoaderComponent
    },
    props: {
        file: {
            type: Object,
            required: true
        },
        objectId: {
            type: Number,
            required: true
        },
        isLast: {
            type: Boolean,
            required: true
        }
    },
    setup(props) {

        const loader = ref(false);
        const orderId = inject('orderId');

        const handleGetFile = (e, file) => {
            e.preventDefault();
            loader.value = true;
            getFileRequest(orderId, props.objectId, file.id)
                .then(response => {
                    loader.value = false;
                    openFile(response.data);
                }).catch(err => console.log(err));
        };

        return {
            loader,
            handleGetFile
        }
    }
}
</script>

<style scoped>

  .file {
    display: inline-block;
    position: relative;
    margin-right: 5px;
  }

  .file>a {
    color: #555555 !important;
    border-bottom: 1px solid transparent;
    padding-bottom: 3px;
    text-decoration: none !important;
  }

  .file>a:hover {
    border-bottom: 1px dashed #555555;
  }

  .file:last-child {
    margin-right: 0;
  }

  @media (max-width: 992px) {

    .file {
      display: block;
      margin-top: 5px;
    }

  }

</style>
