<template>
  <section>
    <div class="table">
      <div class="tr">
        <div class="td number">
          <number-component :date="date"/>
        </div>
        <div class="td messenger">
          <messenger-component v-if="messenger" :id="messenger.id" v-model:count="messenger.count"/>
        </div>
        <div class="td help">
          <help-component/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import NumberComponent from "./number/NumberComponent";
import MessengerComponent from "./messenger/MessengerComponent";
import HelpComponent from "./help/HelpComponent";
export default {
    name: 'TopComponent',
    components: {
        NumberComponent,
        MessengerComponent,
        HelpComponent
    },
    props: {
        date: {
            type: String,
            required: true
        },
        messenger: {
            type: Object,
            required: true
        }
    },
    setup() {

    }
}
</script>

<style scoped>

  section {
    margin: 0;
    padding: 5px 0;
    position: relative;
    border-bottom: 1px dotted;
    color: gray;
    background-color: #3294ca;
  }

  section .table .td {
    vertical-align: top;
  }

  section .number {
    width: 260px;
  }

  section .messenger {
    width: 50px;
  }

  section .help {
    text-align: left;
  }

</style>
