<template>
  <div class="pagination">
    <ul>
      <li v-for="(item, key) in items" :key="key" :class="item.class">
        <a @click="action(item.value)" onclick="return false;" href="#">{{ item.num }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
    export default {
        name: "PaginationComponent",
        props: {
            items: {
                type: Array,
                required: true,
                default: null
            }
        },
        methods: {
          action(v) {
            this.$emit('action', v);
          }
        }
    }
</script>

<style scoped>

  .pagination {
    margin: 10px 0;
  }

  .pagination ul li {
    float: left;
    margin-right: -1px;
  }

  .pagination ul li a {
    font-size: 14px;
    display: block;
    padding: 8px 13px;
    border: 1px solid #CCC;
    text-decoration: none;
    background-color: #FFF;
    color: #3a95c8;
  }

  .pagination ul li.active a {
    background-color: #3a95c8;
    color: #fff;
  }

  .pagination ul li a:hover {
    background-color: #3a95c8;
    color: #fff;
  }

</style>
