<template>
  <layout-legacy>
    <breadcrumb-component/>
    <order-component/>
  </layout-legacy>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useSeoMeta } from '@unhead/vue';
import { BREADCRUMBS_SET_ITEM } from "../../../../store/constants";
import OrderComponent from "./order/OrderComponent";
import LayoutLegacy from "../../../../layouts/LayoutLegacy";
import BreadcrumbComponent from "../../../../components/BreadcrumbComponent";
export default {
    name: "CartNotaryView",
    components: {
        LayoutLegacy,
        OrderComponent,
        BreadcrumbComponent
    },
    setup() {

        const store = useStore();

        onMounted(() => {
            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Корзина товаров', path: '/cart' }
            });
            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Оформление', path: '/cart/notary' }
            });
            useSeoMeta({
                title: 'Оформление',
                keywords: '',
                description: '',
            });
        });

    }
}
</script>

<style scoped>

</style>
