<template>
  <div class="object-item">
    <div class="row">
      <div class="col-sm-8 name">
        <h3>
          <router-link :to="`/oc/${item.alias}`">
            {{ item.name }}
          </router-link>
        </h3>
      </div>
      <div class="col-sm-2 price">
        <template v-if="!object.priceSale">
          <p>{{ object.price }} руб.</p>
        </template>
        <template v-else>
          <p class="price_sale">{{ object.price }} руб.</p>
          <p>{{ object.priceSale }} руб.</p>
        </template>
      </div>
      <div class="col-sm-2 action">
        <cart-buttons-component :id="object.id"/>
      </div>
    </div>
  </div>
</template>

<script>
import { watchEffect, ref } from 'vue';
import CartButtonsComponent from "../../../../../../components/cart/CartButtonsComponent";
export default {
    components: {
        CartButtonsComponent
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        objects: {
            type: Array,
            required: true
        }
    },
    setup(props) {

        const object = ref(null);

        watchEffect(() => {
            object.value = props.objects.find(item => item.id === props.item.objectId);
        });

        return {
            object
        }
    }
}
</script>

<style scoped>

  .object-item {
    padding: 10px;
  }

  .object-item .price {
    font-size: 15px;
    font-weight: bold;
  }

  .object-item .price_sale {
    font-size: 15px;
    color: red;
    text-decoration: line-through;
  }

  .object-item h3 {
    font-size: 16px;
    margin: 10px 0;
  }

  .object-item h3>a {
    color: #212529;
    border-bottom: 1px dashed;
    text-decoration: none;
  }

  .object-item h3>a:hover {
    border-bottom: 0;
  }

</style>
