<template>
  <div class="info">
    <template v-if="!notary.emails.length">
      <p>Нотариус: {{ notary.name }}<span v-if="notary.number">, № насл. дела: {{ notary.number }}</span></p>
      <p>Отсутствуют сведения о e-mail адресе данного нотариуса. Если у вас есть е-мейл нотариуса, то напишите нам его в чат пожайлуста.</p>
    </template>
    <template v-else>
      <template v-if="!allReportComplete || !allReportConfirmed || !isFullPayed || !allReportSigned">
        <p>Нотариус: {{ notary.name }}<span v-if="notary.number">, № насл. дела: {{ notary.number }}</span></p>
        <p>Вы сможете направить оценку нотариусу на e-mail: {{ getEmails() }} после готовности и оплаты заказа.</p>
        <p v-if="notary.digitalReport === 2">
          По нашей информации, данный нотариус принимает отчеты на бумажном носителе. Поэтому рекомендуем уточнить у него возможность приема отчета в электронной форме дополнительно.
        </p>
      </template>
      <template v-else>
        <template v-if="!notary.lastSent">
          <p>Отправьте результаты оценки нотариусу: {{ notary.name }}, <span class="email-list">{{ getEmails() }}</span></p>
        </template>
        <template v-else>
          <p>
            Оценка была направлена нотариусу ({{ notary.name }}) на адреса: <span class="email-list">{{ getEmails() }}</span> {{ notary.lastSent.date }}
            <span class="bold">Обязательно подтвердите в нот. конторе получение!</span>
          </p>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
export default {
    name: "InfoComponent",
    props: {
        notary: {
            type: Object
        },
        isFullPayed: {
            type: Boolean,
            required: true
        },
        allReportConfirmed: {
            type: Boolean,
            required: true
        },
        allReportComplete: {
            type: Boolean,
            required: true
        },
        allReportSigned: {
            type: Boolean,
            required: true
        }
    },
    setup(props) {

        const getEmails = () => {
            return props.notary.emails.join(', ');
        };

        return {
            getEmails
        }
    }
}
</script>

<style scoped>

  .info {
    font-size: 18px;
    line-height: 1.3em;
  }

  .info p {
    line-height: 1.3em;
  }

  .info .bold {
    font-weight: 500;
  }

  .info p .email-list {
    color: #2a8edd;
  }

</style>
