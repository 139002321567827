import { getRequest, postRequest, deleteRequest, putRequest } from './axios';
import { PRE_ORDERS_URL } from "./constants";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

export function createRequest(formData) {
  return postRequest(`${PRE_ORDERS_URL}`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function getAllRequest(params={}) {
  const getParams = new URLSearchParams(params).toString();
  return getRequest(`${PRE_ORDERS_URL}?${getParams}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function dropRequest(id) {
  return deleteRequest(`${PRE_ORDERS_URL}/${id}`,{
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}

export function getFileRequest(orderId, objectId, fileId) {
  return getRequest(`${PRE_ORDERS_URL}/${orderId}/objects/${objectId}/files/${fileId}`,{
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    },
    responseType: 'blob'
  });
}

export function uploadFilesRequest(id, objectId, formData) {
  return postRequest(`${PRE_ORDERS_URL}/${id}/objects/${objectId}/upload`, formData, {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`,
    }
  });
}

export function toModerateRequest(id) {
  return putRequest(`${PRE_ORDERS_URL}/${id}/send-to-moderate`, {},{
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      Authorization: `Bearer ${cookies.get('token')}`
    }
  });
}
