<template>
  <section>
    <service-component
      v-for="(service, index) in services"
      :key="service.id"
      :index="index"
      :service="service"
      :price="price"
      :paid="paid"
      :is-with-pre-pay="isWithPrePay"
      :pre-pay-sum="prePaySum"
      :delivery="delivery"
      :all-report-confirmed="allReportConfirmed"
      :is-complete-all-report-field="isCompleteAllReportField"
    />
  </section>
</template>

<script>
import ServiceComponent from "./service/ServiceComponent";
export default {
    name: "ServicesComponent",
    components: {
        ServiceComponent
    },
    props: {
        services: {
            type: Array,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        paid: {
            type: Number,
            required: true
        },
        isWithPrePay: {
            type: Boolean,
            required: true
        },
        prePaySum: {
            type: Number,
            required: true
        },
        delivery: {
            type: Object,
            required: true
        },
        allReportConfirmed: {
            type: Boolean,
            required: true
        },
        isCompleteAllReportField: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
