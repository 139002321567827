<template>
  <layout-legacy>
    <breadcrumb-component/>
    <div v-if="result !== null">
      <h1>Оплата</h1>
      <div v-if="result === 'success'" class="notify success">
        <p style="font-size: 32px; font-weight: bold; text-align: center; padding-bottom: 15px">Ваш платеж обрабатывается.</p>
        <p style="font-size: 28px; text-align: justify; text-indent: 1em; padding-bottom: 15px">Оплата в заказе появится после получения подтверждения от платежной системы (возможно оно уже поступило). Подтверждение поступает в течение 30 минут. Обновляйте страницу с заказом, чтобы увидеть изменение статуса оплаты.</p>
        <p style="font-size: 28px; text-align: justify; text-indent: 1em; padding-bottom: 10px">Если в течение 30 минут платеж не отметился в заказе, то напишите нам об этом в чат, мы решим проблему.</p>
        <p style="text-align: center; font-size: 28px;"><router-link to="/orders">[Вернуться к заказам]</router-link></p>
      </div>
      <div v-else class="notify fail">
        <p style="font-size: 32px; font-weight: bold; text-align: center; padding-bottom: 15px">Оплата не прошла.</p>
        <p style="text-align: center; font-size: 28px;"><router-link to="/orders">[Вернуться к заказам]</router-link></p>
      </div>
    </div>
  </layout-legacy>
</template>

<script>
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { onMounted } from 'vue';
import { BREADCRUMBS_SET_ITEM } from "../store/constants";
import LayoutLegacy from "../layouts/LayoutLegacy";
import BreadcrumbComponent from "../components/BreadcrumbComponent";
export default {
    name: "PayResult",
    components: {
        LayoutLegacy,
        BreadcrumbComponent
    },
    setup() {

        const route = useRoute();
        const store = useStore();

        onMounted(() => {
            store.commit(BREADCRUMBS_SET_ITEM, {
                item: { name: 'Оплата', path: route.fullPath }
            })
        });

        return {
            result: route.query.result
        }
    }
}
</script>

<style scoped>

  .notify {
    padding: 15px;
    display: inline-block;
    border-radius: 8px;
  }

  .notify>p {
    color: #000000;
    margin-bottom: 10px;
  }

  .notify>p:last-child {
    margin-bottom: 0;
  }

  .notify>p a {
    color: #068ad5;
  }

  .notify.success {
    background-color: #a2eba5;
    border: 1px solid #92cb92;
  }

  .notify.fail {
    background-color: #ffbfbf;
    border: 1px solid #d16f6f;
  }

</style>
