<template>
  <div class="grid">
    <category-item-component
      v-for="category in categories"
      :key="category.id"
      :category="category"
      :objects="objects"
    />
  </div>
</template>

<script>
import CategoryItemComponent from "./categoryItem/CategoryItemComponent";
export default {
    name: 'GridComponent',
    components: {
        CategoryItemComponent
    },
    props: {
        categories: {
            type: Object,
            required: true
        },
        objects: {
            type: Array,
            required: true
        }
    }
}
</script>

<style scoped>

</style>
