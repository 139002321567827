<template>
  <div class="service">
    <div class="col-item">
      <number-component :number="index+1"/>
    </div>
    <div class="col-item col-item-image">
      <image-component :base64="service.object.image" :name="service.object.name"/>
    </div>
    <div class="col-item">
      <div class="row-item">
        <name-component :name="service.object.name"/>
        <status-component
          :price="price"
          :paid="paid"
          :archive="service.archive"
          :check-report-status="service.checkReportStatus"
          :final-report-status-value="service.finalReportStatusValue"
          :is-with-pre-pay="isWithPrePay"
          :pre-pay-sum="prePaySum"
          :delivery="delivery"
          :is-signed="service.isSigned"
          :all-report-confirmed="allReportConfirmed"
          :is-complete-all-report-field="isCompleteAllReportField"
        />
      </div>
      <price-component
        :price="service.price"
        :report-digital-type="reportDigitalType"
        :report-paper-type="reportPaperType"
      />
      <files-component
        v-if="service.files.length"
        :files="service.files"
        :final-report-status-value="service.finalReportStatusValue"
        :folder-name="service.folderName"
        :all-report-confirmed="allReportConfirmed"
        :is-full-payed="paid>=price"
        :is-signed="service.isSigned"
        :is-archive="service.isArchive"
      />
    </div>
  </div>
</template>

<script>
import { inject, provide } from 'vue';
import NumberComponent from "./number/NumberComponent";
import ImageComponent from "./image/ImageComponent";
import NameComponent from "./name/NameComponent";
import PriceComponent from "./price/PriceComponent";
import StatusComponent from "./status/StatusComponent";
import FilesComponent from "./files/FilesComponent";
export default {
    name: "ServiceComponent",
    components: {
        NumberComponent,
        ImageComponent,
        NameComponent,
        PriceComponent,
        StatusComponent,
        FilesComponent
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        service: {
            type: Object,
            required: true
        },
        price: {
            type: Number,
            required: true
        },
        paid: {
            type: Number,
            required: true
        },
        isWithPrePay: {
            type: Boolean,
            required: true
        },
        prePaySum: {
            type: Number,
            required: true
        },
        delivery: {
            type: Object,
            required: true
        },
        allReportConfirmed: {
            type: Boolean,
            required: true
        },
        isCompleteAllReportField: {
            type: Boolean,
            required: true
        }
    },
    setup(props) {

        const reportId = props.service.id;
        const reportDigitalType = inject('reportDigitalType');
        const reportPaperType = inject('reportPaperType');

        provide('reportId', reportId);
        provide('reportId', reportId);
        provide('reportId', reportId);

        return {
            reportDigitalType,
            reportPaperType
        }
    }
}
</script>

<style scoped>

  .service {
    margin-top: 10px;
  }

  .service .col-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
  }

  @media (max-width: 992px) {

    .col-item-image {
      display: none !important;
    }

  }

</style>
