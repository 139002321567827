<template>
  <div class="order-end">
    <div>
      <div class="col-left">
        <div v-if="order.messenger" class="icon-message" @click="handleOpenMessenger">
          <span><font-awesome-icon icon="comments" /></span>
          <span v-if="order.messenger.count" class="message-count">[1]</span>
          <a href="#" class="icon-message" onclick="return false;">Чат по заказу</a>
        </div>
      </div>
      <div class="col-center">

      </div>
      <div class="col-right">
        <p v-if="order.delivery && order.delivery.type === 3 && parseInt(order.delivery.price)>0">
          <b>+ Доставка:</b>
          {{order.delivery.price}} руб
        </p>
        <p><b>Итого:</b> {{order.price}} руб.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import { useStore } from 'vuex';
import { MESSENGER_SHOW} from "../../../../../../store/constants";

export default {
    name: "End",
    setup() {

        const store = useStore();
        const order = inject('order');

        const handleOpenMessenger = () => {
            store.commit(MESSENGER_SHOW, {
                themeId: order.messenger.theme
            });
        };

        return {
            order,
            handleOpenMessenger
        }
    }
}
</script>

<style scoped>

  .order-end {
    border-top: 1px solid #eee;
    display: table;
    width: 100%;
  }

  .order-end>div {
    display: table-row;
  }

  .order-end .col-left,
  .order-end .col-center,
  .order-end .col-right {
    padding: 5px 15px;
    vertical-align: middle;
    display: table-cell;
  }

  .order-end .col-left {
    text-align: left;
    width: 15.5%;
  }

  .order-end .col-center {
    text-align: left;
    width: 60%;
  }

  .order-end .col-right {
    text-align: right;
    width: 20%;
  }

  .order-end .col-left .icon-message>span {
    font-size: 16px;
    margin-right: 2px;
    color: #3294ca;
  }

  .order-end .col-left .icon-message>a {
    color: #3294ca;
    font-size: 15px;
  }

  .order-end .col-left .icon-message .message-count {
    color: red;
  }

</style>
