export const MESSENGER_SHOW = 'messenger/show';
export const MESSENGER_HIDE = 'messenger/hide';

export const CART_INIT = 'cart/initCart';
export const CART_GET_CART_DEFAULT = 'cart/getCartDefault';
export const CART_ADD_ITEM = 'cart/addItem';
export const CART_DROP_ITEM = 'cart/dropItem';
export const CART_DROP_ITEM_BY_INDEX = 'cart/dropItemByIndex';
export const CART_EDIT_FIELDS = 'cart/editFields';
export const CART_CLEAR = 'cart/clear';

export const AUTH_SHOW = 'auth/showAuth';
export const AUTH_HIDE = 'auth/hideAuth';
export const AUTH_SET_USER = 'auth/setUser';
export const AUTH_GET_USER = 'auth/getUser';
export const AUTH_CLEAR_USER = 'auth/clearUser';

export const DEFAULT_SHOW_LOADER = 'showLoader';
export const DEFAULT_HIDE_LOADER = 'hideLoader';

export const LOAD_OBJECTS = 'loadObjects';
export const LOAD_PAGES = 'loadPages';

export const BREADCRUMBS_CLEAR = 'breadcrumbs/clear';
export const BREADCRUMBS_SET_ITEM = 'breadcrumbs/setItem';

export const LOAD_ORDER_PARAMS = 'loadOrderParams';
