<template>
  <div class="price">
    <span v-if="reportDigitalType === 2 && reportPaperType === 1">
      Отчет электронный с ЭЦП: {{ price }} ₽
    </span>
    <span v-else-if="reportDigitalType === 1 && (reportPaperType === 2 || reportPaperType === 3)">
      Отчет на бумаге: {{ price }} ₽
    </span>
    <span v-else-if="reportDigitalType === 2 && (reportPaperType === 2 || reportPaperType === 3)">
      Отчет электронный с ЭЦП и на бумаге: {{ price }} ₽
    </span>
  </div>
</template>

<script>
export default {
    name: "PriceComponent",
    props: {
        reportDigitalType: {
            type: Number,
            required: true
        },
        reportPaperType: {
            type: Number,
            required: true
        },
        price: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>

  .price {
    margin-top: 5px;
    font-family: Roboto;
    color: #626262;
  }

</style>
