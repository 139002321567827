<template>
  <div class="help">
    <a href="#" @click="handleShowPopup">
      <font-awesome-icon icon="info-circle" />
      <span class="text">Что проверять?</span>
    </a>
    <div v-if="isShowPopup" class="popup">
      <p><b>Что следует проверить в отчете:</b></p>
      <p>1) Дату оценки (указана на первом листе отчета). При оценке для наследства эта дата должна совпадать с датой смерти наследодателя по свидетельству о смерти (требование законодательства РФ).;</p>
      <p>2) Объект оценки на первом листе отчета: его наименование и различные номера/серии/количество и т.п.;</p>
      <p>3) Результат оценки (сумма прописью должна совпадать с суммой цифрами);</p>
    </div>
    <div v-if="isShowPopup" @click="handleHidePopup" class="popup-overlay"></div>
  </div>
</template>

<script>
import { ref } from 'vue';
export default {
    name: "HelpComponent",
    setup() {

        const isShowPopup = ref(false);

        const handleShowPopup = (e) => {
            e.preventDefault();
            isShowPopup.value = true;
        };

        const handleHidePopup = () => {
            isShowPopup.value = false;
        };

        return {
            isShowPopup,
            handleShowPopup,
            handleHidePopup
        }
    }
}
</script>

<style scoped>

  .help {
    margin-left: 15px;
    position: relative;
    display: inline-block;
  }

  .help a {
    color: #555555;
    text-decoration: none;
  }

  .help>a>span.text {
    margin-left: 10px;
  }

  .help .popup {
    position: absolute;
    top: 25px;
    left: 0;
    background-color: #ffffff;
    width: 560px;
    padding: 15px;
    z-index: 2;
    border: 1px solid #000000;
  }

  .help .popup p {
    color: #767171;
    text-indent: 20px;
    line-height: 25px;
  }

  .help .popup p>a {
    color: #767171;
    border-bottom: 1px solid #767171;
    text-decoration: none;
  }

  .help .popup p>a:hover {
    border-bottom: none;
  }

  .help .popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }

  @media (max-width: 992px) {

    .help {
      margin-left: 0;
    }

    .help>a>span.text {
      display: none;
    }

    .help .popup {
      width: 350px;
      left: auto;
      right: 0;
      padding: 20px;
    }

  }

</style>
