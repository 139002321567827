<template>
  <div class="reply">
    {{message.text}}
  </div>
</template>

<script>
export default {
    name: "ReplyContentComponent",
    props: {
        message: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

  .reply {
    color: #626262;
    font-size: 14px;
    border-left: 2px solid #51af74;
    padding: 5px 10px;
    margin-bottom: 5px;
  }

</style>
