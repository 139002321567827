<template>
  <div class="actions">
    <h3>Как вы планируете получать отчет на бумаге?</h3>
    <div>
      <button @click="handleSelectDelivery(parseInt(1))">
        Заберу в офисе
      </button>
      <button @click="handleSelectDelivery(parseInt(2))">
        Отправить почтой России
      </button>
      <button @click="handleSelectDelivery(parseInt(3))">
        Доставить до ПВЗ CDEK
      </button>
    </div>
    <form-component v-if="isShowForm" :field="formField" :hide-event="handleHideForm"/>
  </div>
</template>

<script>
import { inject, ref } from 'vue';
import FormComponent from "./form/FormComponent";
import { setDeliveryTypeRequest } from "../../../../../../../api/orders";

export default {
    name: "ActionsComponent",
    components: {
        FormComponent
    },
    setup() {

        const orderId = inject('orderId');
        const loader = inject('loader');
        const searchOrders = inject('searchOrders');
        const deliveryWidget = inject('deliveryWidget');
        const deliveryOrderId = inject('deliveryOrderId');
        const formField = ref({
            address: '',
            error: ''
        });
        const isShowForm = ref(false);

        const handleSelectDelivery = (deliveryId) => {
            if(deliveryId === 1) {
                loader.value = true;
                setDeliveryTypeRequest(orderId).then(() => {
                    searchOrders().then(() => {
                        loader.value = false;
                    });
                }).catch(err => console.log(err));
            } else if(deliveryId === 2) {
                isShowForm.value = true;
            } else if(deliveryId === 3) {
                deliveryOrderId.value = orderId;
                deliveryWidget.value.open();
            }
        };

        const handleHideForm = () => {
            isShowForm.value = false;
        };

        return {
            formField,
            isShowForm,
            handleSelectDelivery,
            handleHideForm
        }
    }
}
</script>

<style scoped>

  .actions {
    text-align: left;
  }

  .actions h3 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: normal;
  }

  .actions>div>button {
    border: 1px solid #3294ca;
    font-size: 20px;
    color: #3294ca;
    padding: 5px 8px;
    border-radius: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
  }

</style>
