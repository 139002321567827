<template>
  <div class="service">
    <div class="col-item">
      <number-component :number="index+1"/>
    </div>
    <div class="col-item col-item-image">
      <image-component :base64="service.image" :name="service.name"/>
    </div>
    <div class="col-item">
      <div class="row-item">
        <name-component :name="service.name"/>
        <status-component :status="service.status"/>
      </div>
      <price-component :report="report" :price="service.price"/>
      <files-component
        :files="service.files.items"
        :status="service.status"
        :sum-size="service.files.sumSize"
        :max-size="service.files.maxSize"
        :object-id="service.id"
      />
      <info-component :exist-new-files="service.existNewFile"/>
    </div>
  </div>
</template>

<script>
import NumberComponent from "./number/NumberComponent";
import ImageComponent from "./image/ImageComponent";
import NameComponent from "./name/NameComponent";
import PriceComponent from "./price/PriceComponent";
import FilesComponent from "./files/FilesComponent";
import StatusComponent from "./status/StatusComponent";
import InfoComponent from "./info/InfoComponent";
export default {
    name: "ServiceComponent",
    components: {
        NumberComponent,
        ImageComponent,
        NameComponent,
        PriceComponent,
        FilesComponent,
        StatusComponent,
        InfoComponent
    },
    props: {
        index: {
            type: Number,
            required: true
        },
        service: {
            type: Object,
            required: true
        },
        report: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped>

  .service {
    margin-top: 10px;
  }

  .service .col-item {
    display: inline-block;
    vertical-align: top;
    margin-right: 10px;
  }

  @media (max-width: 992px) {

    .col-item-image {
      display: none !important;
    }

  }

</style>
