<template>
  <ul class="breadcrumb" v-if="items.length">
    <li class="breadcrumb-item">
      <router-link :to="mainUrl">Прейскурант</router-link>
    </li>
    <li v-for="(item, key) in items" :key="key" class="breadcrumb-item">
      <router-link :to="item.path">{{ item.name }}</router-link>
    </li>
  </ul>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
    name: 'BreadcrumbComponent',
    props: {
        mainUrl: {
            type: String,
            required: false,
            default: '/'
        }
    },
    setup() {

        const store = useStore();
        const items = computed(() => store.state.breadcrumbs.items);

        return {
            items
        }

    }
}
</script>

<style scoped>

  .breadcrumb {
    padding: 0;
    background-color: transparent;
    margin-bottom: 20px;
  }

  .breadcrumb a {
    color: #495057;
  }

  .breadcrumb li,
  .breadcrumb li a {
    font-size: 13px;
  }

</style>
