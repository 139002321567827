<template>
  <div class="name">{{name}}</div>
</template>

<script>
export default {
    name: "AuthorComponent",
    props: {
        name: {
            type: String,
            required: true
        }
    },
    setup() {

    }
}
</script>

<style scoped>

  .name {
    color: #ababab;
    font-size: 10px;
    margin-bottom: 5px;
  }

</style>
